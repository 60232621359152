<template>
  <div class="request-page">
    <component v-for="(item, i) in items"
      :is="item.type === 'exam' ? 'CardExam' : 'CardHospitalization'"
      :medicalRecordId="medicalRecordId"
      :patient="patient"
      :data="item"
      :key="item.key"
      :lastRequests="lastRequests"
      :pending="pending"
      @remove="remove(item.type, i)"
    />
    <div class="request-card">
      <button class="btn btn-lg btn-gray btn-icon btn-icon-left" @click="add('exam')">
        <fa-icon :icon="['fal', 'file-medical-alt']"></fa-icon>Solicitar exames
      </button>
      <button class="btn btn-lg btn-gray btn-icon btn-icon-left ml-2"
              @click="add('hospitalization')">
        <fa-icon :icon="['fal', 'procedures']"></fa-icon>Solicitar internamento
      </button>
    </div>
    <div class="request-previous" v-if="previous.length > 0">
        <h6 class="text-center">Solicitações anteriores</h6>
        <div class="previous-item" v-for="(request, p) in previous" :key="p">
      <span class="text-bold">
        {{ request.date | date }} - {{ request.professionalName}}
      </span>
          <div class="mt-2" v-for="(expense, i) in request.expenses" :key="i">
            <div>{{ expense.code | tuss }} - {{ expense.name }} - x{{ expense.quantity }}</div>
          </div>
          <button class="btn btn-sm btn-info mt-2"
                  :class="{loading: loadingPrevious}"
                  :disabled="loadingPrevious"
                  @click="requestAgain(request)">
            Solicitar novamente
          </button>
        </div>
      </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import CardExam from './card/CardExam.vue';
import CardHospitalization from './card/CardHospitalization.vue';
import { randomHash } from '../../../../helpers';

export default {
  components: {
    CardExam,
    CardHospitalization,
  },
  props: {
    medicalRecordId: {
      type: String,
    },
    appointment: {
      insurancePlanId: {
        type: String,
      },
      insuranceName: {
        type: String,
      },
    },
    patient: {
      id: {
        type: String,
      },
      name: {
        type: String,
      },
      gender: {
        type: String,
      },
      birthDate: {
        type: Date,
      },
      phone: {
        type: String,
      },
      cellphone: {
        type: String,
      },
      email: {
        type: String,
      },
      insuranceId: {
        type: String,
      },
      record: {
        type: String,
      },
      validity: {
        type: String,
      },
    },
    pending: {
      type: Boolean,
    },
  },
  data() {
    return {
      items: [],
      previous: [],
      lastRequests: [],
      loadingPrevious: false,
    };
  },
  mounted() {
    this.load(this.medicalRecordId);
    this.loadPrevious();
  },
  computed: {
    ...mapState({
      user: state => state.auth.user,
    }),
  },
  methods: {
    load(id) {
      this.$httpX.get(`/medical-records/${id}/requests`)
        .then(({ data }) => {
          this.items = data.items;
        })
        .catch(this.$toast.error);
    },
    loadPrevious() {
      this.$httpX.get(`/patients/${this.patient.id}/exam-requests`)
        .then(({ data }) => {
          this.previous = data;
        })
        .catch(this.$toast.error);
    },
    requestAgain(item) {
      this.loadingPrevious = true;
      const data = {
        key: randomHash(),
        id: '',
        type: item.type,
        date: '',
        insuranceId: this.patient.insuranceId,
        professional: {
          id: this.user.id,
          name: this.user.name,
        },
        referral: item.referral,
        character: item.character,
        isNewborn: item.isNewborn,
        clinicalIndication: item.clinicalIndication,
        justification: item.justification,
        expenses: item.expenses.map(expense => ({
          key: randomHash(),
          id: expense.id,
          code: expense.code,
          name: expense.name,
          quantity: expense.quantity,
        })),
      };

      this.$httpX
        .post(`/medical-records/${this.medicalRecordId}/exam-requests`, data)
        .then(({ data: request }) => {
          data.id = request.id;
          this.items.push(data);
        })
        .catch(this.$toast.error)
        .then(() => {
          this.loadingPrevious = false;
          this.$emit('scroll-top');
        });
    },
    add(type) {
      const item = type === 'exam' ? this.formExamBlank() : this.formHospitalizationBlank();
      item.type = type;
      this.items.push(item);
    },
    remove(type, idx) {
      const found = this.items[idx];
      if (!found.id) {
        this.items.splice(idx, 1);
        return;
      }

      this.$httpX.delete(`/medical-records/${this.medicalRecordId}/${type}-requests/${found.id}`)
        .then(() => {
          this.items.splice(idx, 1);
        })
        .catch(this.$toast.error);
    },
    formExamBlank() {
      return {
        id: '',
        key: randomHash(),
        type: '',
        date: '',
        insuranceId: this.patient.insuranceId,
        professional: {
          id: this.user.id,
          name: this.user.name,
        },
        referral: '',
        character: 'elective',
        isNewborn: false,
        clinicalIndication: '',
        justification: '',
        expenses: [],
      };
    },
    formHospitalizationBlank() {
      return {
        id: '',
        key: randomHash(),
        type: '',
        date: '',
        admissionDate: '',
        insuranceId: this.patient.insuranceId,
        requester: {
          identity: '',
          name: '',
          cnes: '',
        },
        character: 'elective',
        hospitalization: {
          type: 'clinical',
          regime: 'hospital',
          quantity: 1,
        },
        local: {
          code: '',
          name: '',
        },
        suggestedDate: '',
        clinicalIndication: '',
        justification: '',
        diseaseType: 'acute',
        accidentIndication: 'not_accident',
        cids: [],
        expenses: [],
        opmes: [],
      };
    },
  },
};
</script>

<style lang="scss">
  @import "../../../../assets/scss/variables";
  .request-page {
    .request-card {
      background-color: lighten($gray-color, 24%);
      border: $border-width dotted $gray-color;
      border-radius: $border-radius;
      padding: 1.5rem 0;
      text-align: center;
      button {
        width: 12rem;
      }
    }
    .request-previous {
      margin-top: $layout-spacing-lg;
      .previous-item {
        border: $border-color solid $border-width;
        border-radius: $border-radius;
        margin: $layout-spacing 0;
        padding: $layout-spacing-lg;
      }
    }
  }
</style>
