<template>
  <div class="card-recipe-detail">
    <div class="card-detail" v-for="(recipe, p) in items"
         :key="`${recipe.id}${p}`">
      <div class="card-detail-title">{{ recipe.date | date }}</div>
      <div class="card-detail-data">
        <div class="card-drug" v-for="(drug, g) in recipe.drugs"
             :key="`${drug.id}${g}`">
          {{ drug.name }}
          <small>
            - {{ drug.quantity }} {{ unit.getName(drug.unit) }} {{ access.getName(drug.access) }}
          </small>
          <br>
          <small>{{ drug.dosage }}</small>
        </div>

        <div
          v-if="recipe.notes"
          v-html="recipe.notes"
          class="card-drug recipe-detail-notes"
        ></div>
        <button class="btn btn-primary btn-icon btn-icon-left"
                @click="openPrintModal(recipe)">
          <fa-icon :icon="['fal', 'print']"/>Gerar receita
        </button>
      </div>
    </div>

    <dx-modal title="Emitir Receita" size="md"
              v-model="printModal.show"
              v-if="printModal.show"
              id="modal-print-recipe">
      <div class="print-group">
        <h6 class="print-title">
          Assinatura digital <small class="text-warning">(Em breve)</small>
        </h6>
        <div class="print-body">
          <div class="print-image">
            <fa-icon :icon="['fal', 'signature']" />
          </div>
          <div>
            <div class="text-bold">Minha assinatura digital</div>
            <div class="text-small">
              Utilize suas credenciais para assinar digitalmente
            </div>
          </div>
          <div>
            <div class="float-right">
              <label class="form-switch">
                <input type="checkbox" disabled
                       v-model="printModal.data.signature.show">
                <i class="form-icon"></i>
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="print-group">
        <h6 class="print-title">
          Enviar para o paciente <small class="text-warning">(Em breve)</small>
        </h6>
        <div class="print-body">
          <div class="print-image">
            <fa-icon :icon="['fal', 'comment-lines']" />
          </div>
          <div>
            <div class="text-bold">Enviar por WhatsApp</div>
            <div class="text-small">
              Informe o número do telefone
            </div>
            <div style="max-width: 200px">
              <input type="text" class="form-input"
                     v-model="printModal.data.sendWhatsApp.cellphone"
                     :disabled="!printModal.data.sendWhatsApp.show"
                     placeholder="(00) 0 0000-0000"
                     v-mask-phone.br>
            </div>
          </div>
          <div>
            <div class="float-right">
              <label class="form-switch">
                <input type="checkbox" disabled
                       v-model="printModal.data.sendWhatsApp.show">
                <i class="form-icon"></i>
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="print-group">
        <h6 class="print-title">Receituário(s)</h6>
        <div class="print-body mb-1">
          <div class="print-image">
            <fa-icon :icon="['fal', 'file']" />
          </div>
          <div>
            <div class="text-bold">
              Receituário simples
            </div>
            <div class="columns">
              <div class="column col-auto">
                <label class="form-label text-small">Mostrar data</label>
                <select class="form-select"
                        v-model="printModal.data.simpleRecipe.showDate"
                        :disabled="!printModal.data.simpleRecipe.show">
                  <option value="true">Sim</option>
                  <option value="false">Não</option>
                </select>
              </div>
              <div class="column col-auto">
                <label class="form-label text-small">Vias</label>
                <select class="form-select"
                        v-model="printModal.data.simpleRecipe.quantity"
                        :disabled="!printModal.data.simpleRecipe.show">
                  <option value="1">1 via</option>
                  <option value="2">2 vias</option>
                  <option value="3">3 vias</option>
                  <option value="4">4 vias</option>
                </select>
              </div>
            </div>
          </div>
          <div>
            <div class="float-right">
              <label class="form-switch">
                <input type="checkbox"
                       v-model="printModal.data.simpleRecipe.show">
                <i class="form-icon"></i>
              </label>
            </div>
          </div>
        </div>
        <div class="print-divider" />
        <div class="print-body">
          <div class="print-image">
            <fa-icon :icon="['fal', 'file-invoice']" />
          </div>
          <div>
            <div class="text-bold">Receituário de controle especial</div>
            <div class="columns">
              <div class="column col-auto">
                <label class="form-label text-small">Mostrar data</label>
                <select class="form-select"
                        v-model="printModal.data.specialRecipe.showDate"
                        :disabled="!printModal.data.specialRecipe.show">
                  <option value="true">Sim</option>
                  <option value="false">Não</option>
                </select>
              </div>
              <div class="column col-auto">
                <label class="form-label text-small">Vias</label>
                <select class="form-select"
                        v-model="printModal.data.specialRecipe.quantity"
                        :disabled="!printModal.data.specialRecipe.show">
                  <option value="1">1 via</option>
                  <option value="2">2 vias</option>
                  <option value="3">3 vias</option>
                  <option value="4">4 vias</option>
                </select>
              </div>
            </div>
            <div v-for="medicine in specialControlMedicines" :key="medicine.key">
              <small>{{ medicine.name }}</small>
            </div>
          </div>
          <div>
            <div class="float-right">
              <label class="form-switch">
                <input type="checkbox"
                       v-model="printModal.data.specialRecipe.show"
                       :disabled="specialControlMedicines.length === 0">
                <i class="form-icon"></i>
              </label>
            </div>
          </div>
        </div>
      </div>
      <template slot="footer">
        <div class="d-flex float-right">
          <st-button-report
            type="pdf"
            button-class="btn btn-primary"
            :params="printParams"
            :route="printLink()"
            :disabled="!printModal.data.specialRecipe.show
             && !printModal.data.simpleRecipe.show"
          ></st-button-report>
          <button class="btn ml-1" @click="printModal.show = false">Sair</button>
        </div>
      </template>
    </dx-modal>
  </div>
</template>

<script>
import * as access from 'src/data/drug-accesses';
import * as unit from 'src/data/drug-units';

export default {
  props: {
    id: {
      type: String,
    },
    items: {
      type: Array,
    },
  },
  data() {
    return {
      access,
      unit,
      loadingPrint: false,
      specialControlMedicines: [],
      printModal: {
        show: false,
        printing: false,
        data: this.blankPrintData(),
      },
    };
  },
  computed: {
    printParams() {
      const params = {};
      if (this.printModal.data.simpleRecipe.show) {
        params.simpleRecipe = true;
        params.simpleDate = this.printModal.data.simpleRecipe.showDate;
        params.simpleQuantity = this.printModal.data.simpleRecipe.quantity;
      }
      if (this.printModal.data.specialRecipe.show) {
        params.specialRecipe = true;
        params.specialDate = this.printModal.data.specialRecipe.showDate;
        params.specialQuantity = this.printModal.data.specialRecipe.quantity;
      }
      return params;
    },
  },
  methods: {
    printLink() {
      return `/medical-records/${this.id}/recipes/${this.printModal.data.recipeId}/print`;
    },
    openPrintModal(recipe) {
      this.specialControlMedicines = recipe.drugs.filter(({ specialControl }) => specialControl);
      const hasSimpleControl = recipe.drugs.some(({ specialControl }) => !specialControl);
      const hasSpecialControl = recipe.drugs.some(({ specialControl }) => specialControl);
      this.printModal.data = this.blankPrintData();
      this.printModal.data.recipeId = recipe.id;
      this.printModal.data.simpleRecipe.showDate = true;
      this.printModal.data.specialRecipe.showDate = true;
      this.printModal.data.simpleRecipe.show = hasSimpleControl;
      this.printModal.data.specialRecipe.show = hasSpecialControl;
      this.printModal.show = true;
    },
    blankPrintData() {
      return {
        recipeId: '',
        signature: {
          show: false,
        },
        sendWhatsApp: {
          show: false,
          cellphone: '',
        },
        simpleRecipe: {
          show: true,
          showDate: true,
          quantity: 1,
        },
        specialRecipe: {
          show: false,
          showDate: false,
          quantity: 2,
        },
      };
    },
  },
};
</script>

<style lang="scss">
@import "./src/assets/scss/_variables.scss";

.card-recipe-detail {
  .recipe-detail-notes {
    white-space: pre-wrap;
  }
}
</style>
