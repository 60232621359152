<template>
  <div class="ophthalmology-default-item">
    <div class="columns mb-2">
      <div class="column col-12 form-group">
        <label class="form-label text-bold">Queixa principal</label>
        <span v-if="input_60ede9a29f3f7f7c8c95987c">{{ input_60ede9a29f3f7f7c8c95987c }}</span>
        <span v-else>Não informado</span>
      </div>
      <div class="column col-12 form-group">
        <label class="form-label text-bold">História pregressa</label>
        <span v-if="input_60ede9a29f3f7f7c8c95987d">{{ input_60ede9a29f3f7f7c8c95987d }}</span>
        <span v-else>Não informado</span>
      </div>
      <div class="column col-12 col-md-12 col-sm-12 form-group">
        <div class="group-items auto-ref">
          <div class="columns">
            <div class="column col-4 col-md-12 col-sm-12 form-group">
              <label class="form-label text-bold text-center">Auto Refração</label>
              <div class="items text-gray">
                <span class="side"></span>
                <div>Esférica</div>
                <div>Cilíndrica</div>
                <div>Eixo</div>
              </div>
              <div class="items">
                <span class="side">D</span>
                <div :class="input_60ede9a29f3f7f7c8c95987e
                && input_60ede9a29f3f7f7c8c95987e.toString().substr(0, 1) === '-'
                ? 'negative-value' : 'positive-value'">
                  {{ input_60ede9a29f3f7f7c8c95987e | number }}
                </div>
                <div :class="input_60ede9a29f3f7f7c8c959880
                && input_60ede9a29f3f7f7c8c959880.toString().substr(0, 1) === '-'
                ? 'negative-value' : 'positive-value'">
                  {{ input_60ede9a29f3f7f7c8c959880 | number }}
                </div>
                <div>{{ input_60ede9a29f3f7f7c8c959882 || '-' }}</div>
              </div>
              <div class="items">
                <span class="side">E</span>
                <div :class="input_60ede9a29f3f7f7c8c95987f
                && input_60ede9a29f3f7f7c8c95987f.toString().substr(0, 1) === '-'
                ? 'negative-value' : 'positive-value'">
                  {{ input_60ede9a29f3f7f7c8c95987f | number }}
                </div>
                <div :class="input_60ede9a29f3f7f7c8c959881
                && input_60ede9a29f3f7f7c8c959881.toString().substr(0, 1) === '-'
                ? 'negative-value' : 'positive-value'">
                  {{ input_60ede9a29f3f7f7c8c959881 | number }}
                </div>
                <div>{{ input_60ede9a29f3f7f7c8c959883 || '-' }}</div>
              </div>
            </div>
            <div class="column col-4 col-md-12 col-sm-12 form-group">
              <label class="form-label text-bold text-center">Ceratometria</label>
              <div class="items text-gray">
                <div>K1</div>
                <div>K2</div>
                <div>Eixo</div>
              </div>
              <div class="items">
                <div>
                  {{ input_60ede9a29f3f7f7c8c959884 | number }}
                </div>
                <div>
                  {{ input_60ede9a29f3f7f7c8c959886 | number }}
                </div>
                <div>{{ input_60ede9a29f3f7f7c8c959888 || '-' }}</div>
              </div>
              <div class="items">
                <div>
                  {{ input_60ede9a29f3f7f7c8c959885 | number }}
                </div>
                <div>
                  {{ input_60ede9a29f3f7f7c8c959887 | number }}
                </div>
                <div>{{ input_60ede9a29f3f7f7c8c959889 || '-' }}</div>
              </div>
            </div>
            <div class="column col-4 col-md-12 col-sm-12 form-group">
              <label class="form-label text-bold text-center">Lensometria</label>
              <div class="items text-gray">
                <div>Esférica</div>
                <div>Cilíndrica</div>
                <div>Eixo</div>
                <div>Adição</div>
              </div>
              <div class="items">
                <div :class="input_60ede9a29f3f7f7c8c95988a
                && input_60ede9a29f3f7f7c8c95988a.toString().substr(0, 1) === '-'
                ? 'negative-value' : 'positive-value'">
                  {{ input_60ede9a29f3f7f7c8c95988a | number }}
                </div>
                <div :class="input_60ede9a29f3f7f7c8c95988c
                && input_60ede9a29f3f7f7c8c95988c.toString().substr(0, 1) === '-'
                ? 'negative-value' : 'positive-value'">
                  {{ input_60ede9a29f3f7f7c8c95988c | number }}
                </div>
                <div>{{ input_60ede9a29f3f7f7c8c95988e || '-' }}</div>
                <div>
                  {{ input_60ede9a29f3f7f7c8d95988a | number }}
                </div>
              </div>
              <div class="items">
                <div :class="input_60ede9a29f3f7f7c8c95988b
                && input_60ede9a29f3f7f7c8c95988b.toString().substr(0, 1) === '-'
                ? 'negative-value' : 'positive-value'">
                  {{ input_60ede9a29f3f7f7c8c95988b | number }}
                </div>
                <div :class="input_60ede9a29f3f7f7c8c95988d
                && input_60ede9a29f3f7f7c8c95988d.toString().substr(0, 1) === '-'
                ? 'negative-value' : 'positive-value'">
                  {{ input_60ede9a29f3f7f7c8c95988d | number }}
                </div>
                <div>{{ input_60ede9a29f3f7f7c8c95988f || '-' }}</div>
                <div></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="column col-4 col-md-12 col-sm-12 form-group"
           v-if="input_60ede9a29f3f7f7c8c959890
             || input_60ede9a29f3f7f7c8c959891
             || input_60ede9a29f3f7f7c8c959893
             || input_60ede9a29f3f7f7c8c959894
             || input_60ede9a29f3f7f7c8c959895
             || input_60ede9a29f3f7f7c8c959897">
        <div class="group-items auto-pio">
          <div class="columns">
            <div class="column col-6 form-group">
              <label class="form-label text-bold text-center">Auto PIO</label>
              <div class="items text-gray">
                <div>D</div>
                <div>E</div>
              </div>
              <div class="items">
                <div>{{ input_60ede9a29f3f7f7c8c959890 || '-' }}</div>
                <div>{{ input_60ede9a29f3f7f7c8c959891 || '-' }}</div>
              </div>
              <div class="items">
                <div>{{ input_60ede9a29f3f7f7c8c959893 || '-' }}</div>
              </div>
            </div>
            <div class="column col-6 form-group">
              <label class="form-label text-bold text-center">PIO</label>
              <div class="items text-gray">
                <div>D</div>
                <div>E</div>
              </div>
              <div class="items">
                <div>{{ input_60ede9a29f3f7f7c8c959894 || '-' }}</div>
                <div>{{ input_60ede9a29f3f7f7c8c959895 || '-' }}</div>
              </div>
              <div class="items">
                <div>{{ input_60ede9a29f3f7f7c8c959897 || '-' }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="column col-4 col-md-12 col-sm-12 form-group"
           v-if="input_60ede9a29f3f7f7c8c959898
           || input_60ede9a29f3f7f7c8c959899
           || input_60ede9a29f3f7f7c8c95989a
           || input_60ede9a29f3f7f7c8c95989b">
        <div class="group-items avsc">
          <div class="columns">
            <div class="column col-6 form-group">
              <label class="form-label text-bold text-center">AVsc</label>
              <div class="items text-gray">
                <span class="side"></span>
                <div>Valor</div>
              </div>
              <div class="items">
                <span class="side">D</span>
                <div>{{ input_60ede9a29f3f7f7c8c959898 }}</div>
              </div>
              <div class="items">
                <span class="side">E</span>
                <div>{{ input_60ede9a29f3f7f7c8c959899 }}</div>
              </div>
            </div>
            <div class="column col-6 form-group">
              <label class="form-label text-bold text-center">AVcc</label>
              <div class="items text-gray">
                <span class="side"></span>
                <div>Valor</div>
              </div>
              <div class="items">
                <span class="side">D</span>
                <div>{{ input_60ede9a29f3f7f7c8c95989a }}</div>
              </div>
              <div class="items">
                <span class="side">E</span>
                <div>{{ input_60ede9a29f3f7f7c8c95989b }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="column col-12 form-group">
        <div class="group-items refraction">
          <div class="columns">
            <div class="column col-6 col-md-12 col-sm-12 form-group">
              <label class="form-label text-bold text-center">Refração Subjetiva</label>
              <div class="items text-gray">
                <span class="side"></span>
                <div>Esférica</div>
                <div>Cilíndrica</div>
                <div>Eixo</div>
                <div>AVcc</div>
                <div>Adição</div>
              </div>
              <div class="items">
                <span class="side">D</span>
                <div :class="input_60ede9a29f3f7f7c8c95989c
                && input_60ede9a29f3f7f7c8c95989c.toString().substr(0, 1) === '-'
                ? 'negative-value' : 'positive-value'">
                  {{ input_60ede9a29f3f7f7c8c95989c | number }}
                </div>
                <div :class="input_60ede9a29f3f7f7c8c95989e
                && input_60ede9a29f3f7f7c8c95989e.toString().substr(0, 1) === '-'
                ? 'negative-value' : 'positive-value'">
                  {{ input_60ede9a29f3f7f7c8c95989e | number }}
                </div>
                <div>{{ input_60ede9a29f3f7f7c8c9598a0 || '-' }}</div>
                <div>{{ input_60ede9a29f3f7f7c8c9598a8 || '-' }}</div>
                <div>{{ input_60ede9a29f3f7f7c8c9598aa | number }}</div>
              </div>
              <div class="items">
                <span class="side">E</span>
                <div :class="input_60ede9a29f3f7f7c8c95989d
                && input_60ede9a29f3f7f7c8c95989d.toString().substr(0, 1) === '-'
                ? 'negative-value' : 'positive-value'">
                  {{ input_60ede9a29f3f7f7c8c95989d | number }}
                </div>
                <div :class="input_60ede9a29f3f7f7c8c95989f
                && input_60ede9a29f3f7f7c8c95989f.toString().substr(0, 1) === '-'
                ? 'negative-value' : 'positive-value'">
                  {{ input_60ede9a29f3f7f7c8c95989f | number }}
                </div>
                <div>{{ input_60ede9a29f3f7f7c8c9598a1 || '-' }}</div>
                <div>{{ input_60ede9a29f3f7f7c8c9598a9 || '-' }}</div>
                <div>{{ input_60ede9a29f3f7f7c8a9598aa || '-' }}</div>
              </div>
            </div>
            <div class="column col-6 col-md-12 col-sm-12 form-group">
              <label class="form-label text-bold text-center">Refração Objetiva</label>
              <div class="items text-gray">
                <div>Esférica</div>
                <div>Cilíndrica</div>
                <div>Eixo</div>
                <div>AVcc</div>
                <div>Adição</div>
              </div>
              <div class="items">
                <div :class="input_60ede9a29f3f7f7c8c9598a2
                && input_60ede9a29f3f7f7c8c9598a2.toString().substr(0, 1) === '-'
                ? 'negative-value' : 'positive-value'">
                  {{ input_60ede9a29f3f7f7c8c9598a2 | number }}
                </div>
                <div :class="input_60ede9a29f3f7f7c8c9598a4
                && input_60ede9a29f3f7f7c8c9598a4.toString().substr(0, 1) === '-'
                ? 'negative-value' : 'positive-value'">
                  {{ input_60ede9a29f3f7f7c8c9598a4 | number }}
                </div>
                <div>{{ input_60ede9a29f3f7f7c8c9598a6 || '-' }}</div>
                <div>{{ input_60ede9a29f3f7f7c8d9598a8 || '-' }}</div>
                <div>{{ input_60ede9a29f3f7f7c8c9598ab | number }}</div>
              </div>
              <div class="items">
                <div :class="input_60ede9a29f3f7f7c8c9598a3
                && input_60ede9a29f3f7f7c8c9598a3.toString().substr(0, 1) === '-'
                ? 'negative-value' : 'positive-value'">
                  {{ input_60ede9a29f3f7f7c8c9598a3 | number }}
                </div>
                <div :class="input_60ede9a29f3f7f7c8c9598a5
                && input_60ede9a29f3f7f7c8c9598a5.toString().substr(0, 1) === '-'
                ? 'negative-value' : 'positive-value'">
                  {{ input_60ede9a29f3f7f7c8c9598a5 | number }}
                </div>
                <div>{{ input_60ede9a29f3f7f7c8c9598a7 || '-' }}</div>
                <div>{{ input_60ede9a29f3f7f7c8d9598a9 || '-' }}</div>
                <div>{{ input_60ede9a29f3f7f7c8d9598ab || '-' }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <label class="form-label text-bold">Biomicroscopia (OD)</label>
      <span v-if="input_60ede9a29f3f7f7c8c9598ac">{{ input_60ede9a29f3f7f7c8c9598ac }}</span>
      <span v-else>Não informado</span>
    </div>
    <div class="divider" />
    <div>
      <label class="form-label text-bold">Biomicroscopia (OE)</label>
      <span v-if="input_60ede9a29f3f7f7c8c9598ad">{{ input_60ede9a29f3f7f7c8c9598ad }}</span>
      <span v-else>Não informado</span>
    </div>
    <div class="divider" />
    <div>
      <label class="form-label text-bold">Fundo de Olho (OD)</label>
      <span v-if="input_60ede9a29f3f7f7c8c9598ae">{{ input_60ede9a29f3f7f7c8c9598ae }}</span>
      <span v-else>Não informado</span>
    </div>
    <div class="divider" />
    <div>
      <label class="form-label text-bold">Fundo de Olho (OE)</label>
      <span v-if="input_60ede9a29f3f7f7c8c9598af">{{ input_60ede9a29f3f7f7c8c9598af }}</span>
      <span v-else>Não informado</span>
    </div>
    <div class="divider" />
    <div>
      <label class="form-label text-bold">Exames complementares</label>
      <span v-if="input_60ede9a29f3f7f7c8c9598b0">{{ input_60ede9a29f3f7f7c8c9598b0 }}</span>
      <span v-else>Não informado</span>
    </div>
    <div class="divider" />
    <div>
      <label class="form-label text-bold">Diagnóstico</label>
      <span v-if="input_60ede9a29f3f7f7c8c9598b1">{{ input_60ede9a29f3f7f7c8c9598b1 }}</span>
      <span v-else>Não informado</span>
    </div>
    <div class="divider" />
    <div>
      <label class="form-label text-bold">Conduta</label>
      <span v-if="input_60ede9a29f3f7f7c8c9598b2">{{ input_60ede9a29f3f7f7c8c9598b2 }}</span>
      <span v-else>Não informado</span>
    </div>
  </div>
</template>

<script>
const inputIds = [
  '60ede9a29f3f7f7c8c95987c', // Queixa principal
  '60ede9a29f3f7f7c8c95987d', // História pregressa
  '60ede9a29f3f7f7c8c95987e', // Auto Refração - Esférica Direito
  '60ede9a29f3f7f7c8c95987f', // Auto Refração - Esférica Esquerdo
  '60ede9a29f3f7f7c8c959880', // Auto Refração - Cilíndrica Direito
  '60ede9a29f3f7f7c8c959881', // Auto Refração - Cilíndrica Esquerdo
  '60ede9a29f3f7f7c8c959882', // Auto Refração - Eixo Direito
  '60ede9a29f3f7f7c8c959883', // Auto Refração - Eixo Esquerdo
  '60ede9a29f3f7f7c8c959884', // Ceratometria - K1 Direito
  '60ede9a29f3f7f7c8c959885', // Ceratometria - K1 Esquerdo
  '60ede9a29f3f7f7c8c959886', // Ceratometria - K2 Direito
  '60ede9a29f3f7f7c8c959887', // Ceratometria - K2 Esquerdo
  '60ede9a29f3f7f7c8c959888', // Ceratometria - Eixo Direito
  '60ede9a29f3f7f7c8c959889', // Ceratometria - Eixo Esquerdo
  '60ede9a29f3f7f7c8c95988a', // Lensometria - Esférica Direito
  '60ede9a29f3f7f7c8c95988b', // Lensometria - Esférica Esquerdo
  '60ede9a29f3f7f7c8c95988c', // Lensometria - Cilíndrica Direito
  '60ede9a29f3f7f7c8c95988d', // Lensometria - Cilíndrica Esquerdo
  '60ede9a29f3f7f7c8c95988e', // Lensometria - Eixo Direito
  '60ede9a29f3f7f7c8c95988f', // Lensometria - Eixo Esquerdo
  '60ede9a29f3f7f7c8d95988a', // Lensometria - Adição
  '60ede9a29f3f7f7d8c95988b', // Lensometria - Quantidade
  '60ede9a29f3f7f7c8c959890', // Auto PIO (Pressão Intraocular) - Valor Direito
  '60ede9a29f3f7f7c8c959891', // Auto PIO (Pressão Intraocular) - Valor Esquerdo
  '60ede9a29f3f7f7c8c959892', // Auto PIO (Pressão Intraocular) - Horário Direito
  '60ede9a29f3f7f7c8c959893', // Auto PIO (Pressão Intraocular) - Horário Esquerdo
  '60ede9a29f3f7f7c8c959894', // PIO (Pressão Intraocular) - Valor Direito
  '60ede9a29f3f7f7c8c959895', // PIO (Pressão Intraocular) - Valor Esquerdo
  '60ede9a29f3f7f7c8c959896', // PIO (Pressão Intraocular) - Horário Direito
  '60ede9a29f3f7f7c8c959897', // PIO (Pressão Intraocular) - Horário Esquerdo
  '60ede9a29f3f7f7c8c959898', // AVsc (Acuidade Visual Sem Correção) - Direito
  '60ede9a29f3f7f7c8c959899', // AVsc (Acuidade Visual Sem Correção) - Esquerdo
  '60ede9a29f3f7f7c8c95989a', // AVcc (Acuidade Visual Com Correção) - Direito
  '60ede9a29f3f7f7c8c95989b', // AVcc (Acuidade Visual Com Correção) - Esquerdo
  '60ede9a29f3f7f7c8c95989c', // Refração Subjetiva - Esférica Direito
  '60ede9a29f3f7f7c8c95989d', // Refração Subjetiva - Esférica Esquerdo
  '60ede9a29f3f7f7c8c95989e', // Refração Subjetiva - Cilíndrica Direito
  '60ede9a29f3f7f7c8c95989f', // Refração Subjetiva - Cilíndrica Esquerdo
  '60ede9a29f3f7f7c8c9598a0', // Refração Subjetiva - Eixo Direito
  '60ede9a29f3f7f7c8c9598a1', // Refração Subjetiva - Eixo Esquerdo
  '60ede9a29f3f7f7c8c9598a8', // Refração Subjetiva - AVcc (Acuidade Visual Com Correção) - Direito
  '60ede9a29f3f7f7c8c9598a9', // Refração Subjetiva - AVcc (Acuidade Visual Com Correção) - Esquerdo
  '60ede9a29f3f7f7c8c9598aa', // Refração Subjetiva - Adição
  '60ede9a29f3f7f7c8a9598aa', // Refração Subjetiva - Quantidade
  '60ede9a29f3f7f7c8c9598a2', // Refração Objetiva - Esférica Direito
  '60ede9a29f3f7f7c8c9598a3', // Refração Objetiva - Esférica Esquerdo
  '60ede9a29f3f7f7c8c9598a4', // Refração Objetiva - Cilíndrica Direito
  '60ede9a29f3f7f7c8c9598a5', // Refração Objetiva - Cilíndrica Esquerdo
  '60ede9a29f3f7f7c8c9598a6', // Refração Objetiva - Eixo Direito
  '60ede9a29f3f7f7c8c9598a7', // Refração Objetiva - Eixo Esquerdo
  '60ede9a29f3f7f7c8c9598ab', // Refração Objetiva - Adição
  '60ede9a29f3f7f7c8d9598ab', // Refração Objetiva - Quantidade
  '60ede9a29f3f7f7c8d9598a8', // Refração Objetiva - AVcc (Acuidade Visual Com Correção) - Direito
  '60ede9a29f3f7f7c8d9598a9', // Refração Objetiva - AVcc (Acuidade Visual Com Correção) - Esquerdo
  '60ede9a29f3f7f7c8c9598ac', // Biomicroscopia - Direito
  '60ede9a29f3f7f7c8c9598ad', // Biomicroscopia - Esquerdo
  '60ede9a29f3f7f7c8c9598ae', // Fundo de Olho (Mapeamento de retina) - Direito
  '60ede9a29f3f7f7c8c9598af', // Fundo de Olho (Mapeamento de retina) - Esquerdo
  '60ede9a29f3f7f7c8c9598b0', // Exames complementares
  '60ede9a29f3f7f7c8c9598b1', // Diagnóstico
  '60ede9a29f3f7f7c8c9598b2', // Conduta
];

function mapPropsModels(ids = []) {
  return ids.reduce((obj, id) => {
    obj[`input_${id}`] = {
      get() {
        return this.form.anamnesis.inputs.find(item => item.id === id).model.value;
      },
      set(value) {
        this.$emit('change', { id, value });
      },
    };
    return obj;
  }, {});
}

export default {
  props: {
    data: {
      type: Object,
    },
  },
  data() {
    return {
      form: this.data,
    };
  },
  computed: {
    ...mapPropsModels(inputIds),
  },
};
</script>

<style lang="scss">
@import "./src/assets/scss/_variables.scss";
.ophthalmology-default-item {
  .group-items {
    border-radius: $border-radius;
    margin-top: $layout-spacing-sm;
    padding: $layout-spacing-sm $layout-spacing;
    .items {
      align-items: center;
      border-right: $border-width solid $border-color;
      display: flex;
      text-align: center;
      .negative-value {
        color: red;
      }
      .positive-value {
        color: green;
      }
      .side {
        color: $gray-color;
        min-width: $font-size;
      }
      & > div {
        flex: 1;
        margin-right: $layout-spacing-sm;

        &:last-child {
          margin-right: 0;
        }
      }
    }
    .column:last-of-type {
      .items {
        border-right: 0;
      }
    }

    &.auto-ref {
      border: $border-width solid $gray-color;
    }

    &.auto-pio {
      border: $border-width solid #efcc92;
    }

    &.avsc {
      border: $border-width solid #9292c2;
    }

    &.refraction {
      border: $border-width solid #a8cba8;
    }
  }
}
</style>
