<template>
  <div>
    <dx-modal id="modal-patient-growth-curves"
              size="lg" title="Curvas de crescimento"
              :value="show" @input="close">
      <div class="loading loading-lg" v-if="loading" />
      <div v-else>
        <label for="period" class="form-label">Período</label>
        <select id="period" v-model="period" class="form-select" @change="load">
          <option value="1">0 à 5 anos</option>
          <option value="2">5 à 19 anos</option>
        </select>
        <div class="card">
          <div class="card-body">
            <div class="card-title">
              <span v-if="period === '1'">Estatura para idade (0 a 5 anos) - Percentis</span>
              <span v-else>Estatura para idade (5 a 19 anos) - Percentis</span>
            </div>
            <st-line-chart
              :chart-data="charts.growthCurvesHeight.data"
              :options="charts.growthCurvesHeight.options"
            />
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <div class="card-title">
              <span v-if="period === '1'">Peso para idade (0 a 5 anos) - Percentis</span>
              <span v-else>Peso para idade (5 a 10 anos) - Percentis</span>
            </div>
            <st-line-chart
              :chart-data="charts.growthCurvesWeight.data"
              :options="charts.growthCurvesWeight.options"
            />
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <div class="card-title">
            <span v-if="period === '1'">
              Índice de massa corpórea por idade (0 a 5 anos) - Percentis
            </span>
              <span v-else>Índice de massa corpórea por idade  (5 a 19 anos) - Percentis</span>
            </div>
            <st-line-chart
              :chart-data="charts.bmi.data"
              :options="charts.bmi.options"
            />
          </div>
        </div>
        <div class="divider" />
        <div class="empty mt-2" v-if="patient.medicalInfos.length === 0">
          <div class="empty-icon">
            <fa-icon :icon="['fal', 'info-circle']" size="3x"/>
          </div>
          <p class="empty-title h5">Dados do paciente</p>
          <p class="empty-subtitle">
            Paciente sem dados de antropometria
          </p>
        </div>
        <template v-else>
          <span class="h6">Dados do paciente</span>
          <button class="btn btn-primary btn-icon btn-icon-left float-right"
                  @click="openCreateModal">
            <fa-icon :icon="['fal', 'plus']" />Adicionar
          </button>
          <table class="table table-striped table-hover">
            <thead>
            <tr>
              <th>Data do atendimento</th>
              <th class="text-center">Altura</th>
              <th class="text-center">Peso</th>
              <th class="text-center">IMC</th>
              <th></th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(item, i) in patient.medicalInfos" :key="i">
              <td>{{ item.date | date }}</td>
              <td class="text-center" width="120px">
                <div class="input-group" v-if="editing && idx === i">
                  <dx-input-number class="form-input"
                                   v-model="item.height"
                                   :precision="1" maxlength="5" />
                  <span class="input-group-addon text-gray">cm</span>
                </div>
                <span v-else>{{ item.height }} cm</span>
              </td>
              <td class="text-center" width="120px">
                <div class="input-group" v-if="editing && idx === i">
                  <dx-input-number class="form-input"
                                   v-model="item.weight"
                                   :precision="3" maxlength="7" />
                  <span class="input-group-addon text-gray">kg</span>
                </div>
                <span v-else>{{ item.weight }} kg</span>
              </td>
              <td class="text-center">{{ item.bmi | number }}</td>
              <td class="text-right">
                <button class="btn btn-primary btn-action btn-icon btn-sm"
                        :class="{loading: updating}"
                        @click="update(item)" :disabled="updating"
                        v-if="editing && idx === i">
                  <fa-icon :icon="['fal', 'save']" />
                </button>
                <button class="btn btn-gray btn-action btn-icon btn-sm"
                        @click="edit(i)" v-else>
                  <fa-icon :icon="['fal', 'pencil']" />
                </button>
              </td>
            </tr>
            </tbody>
          </table>
        </template>
      </div>
      <template slot="footer">
        <button class="btn" @click="close">Sair</button>
      </template>
    </dx-modal>
    <dx-modal id="modal-growth-curves-add"
              size="sm" title="Adicionar atendimento"
              v-model="createModal.show">
      <div class="columns">
        <div class="column col-4"
             :class="{'has-error': $v.createModal.form.date.$error}">
          <dx-input-date class="text-center"
                         v-model="createModal.form.date"
                         @blur="$v.createModal.form.date.$touch()"
          />
        </div>
        <div class="column col-4"
             :class="{'has-error': $v.createModal.form.height.$error}">
          <div class="input-group">
            <dx-input-number class="form-input"
                             v-model="createModal.form.height"
                             :precision="1" maxlength="5"
                             @blur="$v.createModal.form.height.$touch()"
            />
            <span class="input-group-addon text-gray">cm</span>
          </div>
        </div>
        <div class="column col-4"
             :class="{'has-error': $v.createModal.form.weight.$error}">
          <div class="input-group">
            <dx-input-number class="form-input"
                             v-model="createModal.form.weight"
                             :precision="3" maxlength="7"
                             @blur="$v.createModal.form.weight.$touch()"
            />
            <span class="input-group-addon text-gray">kg</span>
          </div>
        </div>
      </div>
      <template slot="footer">
        <button class="btn btn-primary mr-1" @click="save"
                :disabled="saving" :class="{loading: saving}">
          Salvar
        </button>
        <button class="btn" @click="createModal.show = false">Sair</button>
      </template>
    </dx-modal>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { minValue, required } from 'vuelidate/lib/validators';
import { date, maxDate } from '../../../data/validators';

export default {
  props: {
    show: {
      type: Boolean,
    },
    patientId: {
      type: String,
    },
  },
  data() {
    return {
      loading: false,
      editing: false,
      saving: false,
      updating: false,
      period: '1',
      idx: null,
      patient: {
        id: '',
        name: '',
        birthDate: '',
        gender: '',
        medicalInfos: [],
      },
      charts: {
        growthCurvesWeight: {
          data: {
            labels: [],
            datasets: [],
          },
          options: {
            tooltips: {
              callbacks: {
                title: () => 'Informações',
                label: tooltipItem => `${
                  tooltipItem.label} ${
                  Number(tooltipItem.label) < 1 ? 'meses' : 'anos'} - ${
                  Math.round(tooltipItem.value)} kg`,
              },
            },
            maintainAspectRatio: false,
            legend: {
              display: true,
            },
            scales: {
              yAxes: [{
                ticks: {
                  beginAtZero: false,
                },
              }],
            },
          },
        },
        growthCurvesHeight: {
          data: {
            labels: [],
            datasets: [],
          },
          options: {
            tooltips: {
              callbacks: {
                title: () => 'Informações',
                label: tooltipItem => `${
                  tooltipItem.label} ${
                  Number(tooltipItem.label) < 1 ? 'meses' : 'anos'} - ${
                  Math.round(tooltipItem.value)} cm`,
              },
            },
            maintainAspectRatio: false,
            legend: {
              display: true,
            },
            scales: {
              yAxes: [{
                ticks: {
                  beginAtZero: false,
                },
              }],
            },
          },
        },
        bmi: {
          data: {
            labels: [],
            datasets: [],
          },
          options: {
            tooltips: {
              callbacks: {
                title: () => 'Informações',
                label: tooltipItem => `${
                  tooltipItem.label} ${
                  Number(tooltipItem.label) < 1 ? 'meses' : 'anos'} - ${
                  Math.round(tooltipItem.value)} cm`,
              },
            },
            maintainAspectRatio: false,
            legend: {
              display: true,
            },
            scales: {
              yAxes: [{
                ticks: {
                  beginAtZero: false,
                },
              }],
            },
          },
        },
      },
      createModal: {
        show: false,
        form: {
          date: '',
          height: 0,
          weight: 0,
        },
      },
    };
  },
  async mounted() {
    this.loading = true;
    this.clearCharts();
    await this.loadMedicalInfos();
    await this.load();
    this.loading = false;
  },
  computed: {
    ...mapState({
      user: ({ auth }) => auth.user,
    }),
  },
  validations() {
    return {
      createModal: {
        form: {
          date: { required, date, maxDate: maxDate() },
          height: { required, minValue: minValue(1) },
          weight: { required, minValue: minValue(1) },
        },
      },
    };
  },
  methods: {
    loadMedicalInfos() {
      return this.$httpX
        .get(`/patients/${this.patientId}/medical-infos`)
        .then(({ data }) => {
          if (data) {
            this.patient = data;
          }
        })
        .catch(() => {});
    },
    load() {
      const params = {
        period: this.period,
        gender: this.patient.gender || 'male',
      };

      return this.$httpX
        .get('/growth-curves', { params })
        .then(({ data }) => {
          const bmi = data.items.filter(({ type }) => type === 'bmi');
          const height = data.items.filter(({ type }) => type === 'height');
          const weight = data.items.filter(({ type }) => type === 'weight');
          this.loadGrowthCurvesBmi(bmi);
          this.loadGrowthCurvesLength(height);
          this.loadGrowthCurvesWeight(weight);
        });
    },
    edit(i) {
      this.idx = i;
      this.editing = true;
    },
    save() {
      this.$v.createModal.form.$touch();
      if (this.$v.createModal.form.$error) {
        return;
      }

      this.saving = true;

      const data = {
        startDate: this.createModal.form.date,
        endDate: this.createModal.form.date,
        status: 'finished',
        patientId: this.patientId,
        professionalId: this.user.id,
        anthropometry: {
          height: this.createModal.form.height,
          weight: this.createModal.form.weight,
        },
      };

      this.$http.post('/medical-records', data)
        .then(() => {
          this.loadMedicalInfos();
          this.load();
          this.createModal.show = false;
        })
        .catch(() => {
          this.$toast.show('Ocorreu um erro ao salvar. Tente novamente!', { type: 'error' });
        })
        .finally(() => {
          this.saving = false;
        });
    },
    update(item) {
      this.updating = true;

      if (item.height && item.weight) {
        const resultBmi = (item.weight / (item.height * item.height)) * 10000;
        item.bmi = resultBmi < 14 || resultBmi > 55 ? 0 : resultBmi;
      }

      const data = {
        anthropometry: {
          height: item.height,
          weight: item.weight,
        },
      };

      this.$http.put(`/medical-records/${item.id}`, data)
        .then(() => {
          this.loadMedicalInfos();
          this.load();
        })
        .catch(() => {
          this.$toast.show('Ocorreu um erro ao salvar. Tente novamente!', { type: 'error' });
        })
        .finally(() => {
          this.updating = false;
          this.editing = false;
        });
    },
    clearCharts() {
      this.charts.growthCurvesHeight.data.labels = [];
      this.charts.growthCurvesHeight.data.datasets = [];
      this.charts.growthCurvesWeight.data.labels = [];
      this.charts.growthCurvesWeight.data.datasets = [];
      this.charts.bmi.data.labels = [];
      this.charts.bmi.data.datasets = [];
    },
    loadGrowthCurvesLength(base) {
      this.patient.medicalInfos.forEach(((patient) => {
        const { month } = patient;
        const found = base.find(item => item.month === month);
        if (found) {
          found.patientData = patient.height === 0 ? null : patient.height;
        }
      }));

      this.charts.growthCurvesHeight.data = {
        labels: base.map(item => (item.month / 12).toFixed(1)),
        datasets: [
          {
            label: 'Paciente',
            data: base.map(item => item.patientData),
            backgroundColor: 'rgba(0, 0, 0, 0)',
            borderColor: 'rgba(0, 0, 0, 0)',
            pointBackgroundColor: 'rgba(150, 14, 80,1)',
            pointBorderColor: 'rgba(150, 14, 80,1)',
            pointRadius: 4,
            showLine: false,
          },
          {
            label: '3',
            data: base.map(item => item.p3),
            backgroundColor: 'rgba(0, 0, 0, 0)',
            borderColor: 'rgba(255,99,132,1)',
            borderWidth: 3,
            pointRadius: 0,
          },
          {
            label: '15',
            data: base.map(item => item.p15),
            backgroundColor: 'rgba(0, 0, 0, 0)',
            borderColor: 'rgba(255, 206, 86, 1)',
            borderWidth: 3,
            pointRadius: 0,
          },
          {
            label: '50',
            data: base.map(item => item.p50),
            backgroundColor: 'rgba(0, 0, 0, 0)',
            borderColor: 'rgba(75, 192, 192, 1)',
            borderWidth: 3,
            pointRadius: 0,
          },
          {
            label: '85',
            data: base.map(item => item.p85),
            backgroundColor: 'rgba(0, 0, 0, 0)',
            borderColor: 'rgba(255, 206, 86, 1)',
            borderWidth: 3,
            pointRadius: 0,
          },
          {
            label: '97',
            data: base.map(item => item.p97),
            backgroundColor: 'rgba(0, 0, 0, 0)',
            borderColor: 'rgba(255,99,132,1)',
            borderWidth: 3,
            pointRadius: 0,
          },
        ],
      };
    },
    loadGrowthCurvesWeight(base) {
      this.patient.medicalInfos.forEach(((patient) => {
        const { month } = patient;
        const found = base.find(item => item.month === month);
        if (found) {
          found.patientData = patient.weight === 0 ? null : patient.weight;
        }
      }));

      this.charts.growthCurvesWeight.data = {
        labels: base.map(item => (item.month / 12).toFixed(1)),
        datasets: [
          {
            label: 'Paciente',
            data: base.map(item => item.patientData),
            backgroundColor: 'rgba(0, 0, 0, 0)',
            borderColor: 'rgba(0, 0, 0, 0)',
            pointBackgroundColor: 'rgba(150, 14, 80,1)',
            pointBorderColor: 'rgba(150, 14, 80,1)',
            pointRadius: 4,
            showLine: false,
          },
          {
            label: '3',
            data: base.map(item => item.p3),
            backgroundColor: 'rgba(0, 0, 0, 0)',
            borderColor: 'rgba(255,99,132,1)',
            borderWidth: 3,
            pointRadius: 0,
          },
          {
            label: '15',
            data: base.map(item => item.p15),
            backgroundColor: 'rgba(0, 0, 0, 0)',
            borderColor: 'rgba(255, 206, 86, 1)',
            borderWidth: 3,
            pointRadius: 0,
          },
          {
            label: '50',
            data: base.map(item => item.p50),
            backgroundColor: 'rgba(0, 0, 0, 0)',
            borderColor: 'rgba(75, 192, 192, 1)',
            borderWidth: 3,
            pointRadius: 0,
          },
          {
            label: '85',
            data: base.map(item => item.p85),
            backgroundColor: 'rgba(0, 0, 0, 0)',
            borderColor: 'rgba(255, 206, 86, 1)',
            borderWidth: 3,
            pointRadius: 0,
          },
          {
            label: '97',
            data: base.map(item => item.p97),
            backgroundColor: 'rgba(0, 0, 0, 0)',
            borderColor: 'rgba(255,99,132,1)',
            borderWidth: 3,
            pointRadius: 0,
          },
        ],
      };
    },
    loadGrowthCurvesBmi(base) {
      this.patient.medicalInfos.forEach(((patient) => {
        const { month } = patient;
        const found = base.find(item => item.month === month);
        if (found) {
          found.patientData = patient.bmi === 0 ? null : patient.bmi;
        }
      }));

      this.charts.bmi.data = {
        labels: base.map(item => (item.month / 12).toFixed(1)),
        datasets: [
          {
            label: 'Paciente',
            data: base.map(item => item.patientData),
            backgroundColor: 'rgba(0, 0, 0, 0)',
            borderColor: 'rgba(0, 0, 0, 0)',
            pointBackgroundColor: 'rgba(150, 14, 80,1)',
            pointBorderColor: 'rgba(150, 14, 80,1)',
            pointRadius: 4,
            showLine: false,
          },
          {
            label: '3',
            data: base.map(item => item.p3),
            backgroundColor: 'rgba(0, 0, 0, 0)',
            borderColor: 'rgba(255,99,132,1)',
            borderWidth: 3,
            pointRadius: 0,
          },
          {
            label: '15',
            data: base.map(item => item.p15),
            backgroundColor: 'rgba(0, 0, 0, 0)',
            borderColor: 'rgba(255, 206, 86, 1)',
            borderWidth: 3,
            pointRadius: 0,
          },
          {
            label: '50',
            data: base.map(item => item.p50),
            backgroundColor: 'rgba(0, 0, 0, 0)',
            borderColor: 'rgba(75, 192, 192, 1)',
            borderWidth: 3,
            pointRadius: 0,
          },
          {
            label: '85',
            data: base.map(item => item.p85),
            backgroundColor: 'rgba(0, 0, 0, 0)',
            borderColor: 'rgba(255, 206, 86, 1)',
            borderWidth: 3,
            pointRadius: 0,
          },
          {
            label: '97',
            data: base.map(item => item.p97),
            backgroundColor: 'rgba(0, 0, 0, 0)',
            borderColor: 'rgba(255,99,132,1)',
            borderWidth: 3,
            pointRadius: 0,
          },
        ],
      };
    },
    close() {
      this.$emit('close');
    },
    openCreateModal() {
      this.createModal.form.date = '';
      this.createModal.form.height = 0;
      this.createModal.form.weight = 0;
      this.$v.createModal.form.$reset();
      this.createModal.show = true;
    },
  },
};
</script>

<style lang="scss">
@import "./src/assets/scss/_variables.scss";
#modal-patient-growth-curves {
  .modal .modal-body {
    min-height: 50px;
  }
  .card {
    background-color: $gray-color-ultra-light;
    border: $border-width solid $border-color;
    border-radius: $border-radius;
    margin: $layout-spacing-lg 0;
    padding: $layout-spacing-sm;
  }
}
</style>
