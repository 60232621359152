<template>
  <div>
    <div class="card-detail" v-for="(request, r) in items"
         :key="`${request.id}${r}`">
      <div class="card-detail-title">
        <span v-if="request.date"> {{ request.date | date }}</span>
      </div>
      <div class="card-detail-data">
        <div class="card-drug" v-for="(expense, e) in request.expenses"
             :key="`${expense.id}${e}`">
          {{ expense.code | tuss }} - {{ expense.name }} ( x{{ expense.quantity }} )
        </div>
        <button class="btn btn-info btn-icon btn-action"
                :class="{loading: loadingPrint}"
                :disabled="loadingPrint"
                @click="print(request.id)">
          <fa-icon :icon="['fal', 'print']"></fa-icon>
        </button>
        <button class="btn btn-gray btn-icon btn-icon-left ml-2"
                :class="{loading: loadingPrint}"
                :disabled="loadingPrint"
                @click="print(request.id, true)">
          <fa-icon :icon="['fal', 'print']"></fa-icon> SADT
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
    },
    items: {
      type: Array,
    },
  },
  data() {
    return {
      loadingPrint: false,
    };
  },
  methods: {
    print(id, spsadt = false) {
      this.loadingPrint = true;
      return this.$file
        .print(`/medical-records/${this.id}/exam-requests/${id}/print`, { spsadt })
        .catch(this.$toast.error)
        .finally(() => {
          this.loadingPrint = false;
        });
    },
  },
};
</script>

<style lang="scss">
  @import "./src/assets/scss/_variables.scss";
</style>
