<template>
  <div class="card-manipulated-recipe">
    <div class="card-recipe-header">
      <div class="columns">
        <div class="column col-2 col-md-3 col-sm-12"
             :class="{'has-error': $v.data.date.$error}">
          <dx-input-date class="text-center"
                         v-model="data.date"
                         @blur="$v.data.date.$touch()"
                         :disabled="!printDate"
                         @change="getDate"
          />
        </div>
        <div class="column hide-sm">
          <label class="form-checkbox">
            <input type="checkbox" v-model="printDate">
            <i class="form-icon"></i>Imprimir data
          </label>
        </div>
        <div class="column col-auto col-md-auto col-sm-12" v-if="false">
          <div class="input-group" :class="{loading: loadingModel}">
            <select class="form-select" v-model="modelId"
                    :disabled="loadingModel"
                    @change="getModel">
              <option value="">Meus modelos de receituários</option>
              <option v-for="(item, i) in models" :value="item.id" :key="i">
                {{ item.name }}
              </option>
            </select>
            <button class="btn btn-neutral input-group-btn"
                    @click="openEditModel">
              Gerenciar
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="card-recipe-body">
      <st-editor
        id="manipulated-recipe-content"
        v-model="data.notes"
      ></st-editor>
    </div>
    <div class="card-recipe-footer">
      <div class="d-flex centered">
        <st-button-report
          type="pdf"
          button-class="btn-gray"
          :params="printParams"
          :disabled="pending"
          :route="printLink()"
        ></st-button-report>
        <button
          class="btn btn-icon-left btn-error ml-2"
          :disabled="pending"
          @click="remove"
        ><fa-icon :icon="['fal', 'trash']"/>Excluir</button>
      </div>
    </div>
    <modal-edit
      v-if="editModels.show"
      :show="editModels.show"
      @close="closeEditModel"
    />
  </div>
</template>

<script>
import { date } from 'src/data/validators';
import { randomHash } from '@/helpers';
import { mapState } from 'vuex';
import moment from 'moment';
import ModalEdit from '../../../../template/components/recipe/modals/Main.vue';
import formMixin from '../../../../../mixins/form';

export default {
  mixins: [formMixin],
  components: {
    ModalEdit,
  },
  props: {
    medicalRecordId: {
      type: String,
    },
    dosages: {
      type: Array,
    },
    data: {
      key: {
        type: String,
      },
      id: {
        type: String,
      },
      date: {
        type: Date,
      },
      notes: {
        type: String,
      },
      drugs: {
        type: Array,
      },
    },
  },
  data() {
    return {
      drug: null,
      models: [],
      modelId: '',
      printDate: true,
      loadingModel: false,
      pending: false,
      printing: false,
      savingModel: false,
      debounceTimeout: null,
      editModels: {
        show: false,
      },
    };
  },
  computed: {
    haveDrugs() {
      return this.data.drugs.length > 0;
    },
    ...mapState({
      user: state => state.auth.user,
    }),
    printParams() {
      return {
        simpleRecipe: true,
        simpleDate: this.printDate,
        simpleQuantity: 1,
      };
    },
  },
  validations() {
    return {
      data: {
        date: { date },
      },
    };
  },
  watch: {
    data: {
      handler() {
        clearTimeout(this.debounceTimeout);
        this.debounceTimeout = setTimeout(() => {
          this.pending = true;
          this.save();
        }, 1000);
      },
      deep: true,
    },
    printDate: function printDate() {
      this.data.date = this.printDate ? moment().format('YYYY-MM-DD') : '';
    },
  },
  mounted() {
    this.loadModels();
    this.printDate = !!this.data.date;
  },
  methods: {
    getDate() {
      if (!this.data.date || !moment(this.data.date).isValid()) {
        this.data.date = moment().format('YYYY-MM-DD');
      }
    },
    loadModels() {
      const params = {
        limit: 0,
        professionalId: this.user.id,
        manipulated: true,
      };
      this.$httpX.get('/recipe-templates', { params })
        .then(({ data }) => {
          this.models = data.items;
        })
        .catch(this.$toast.error);
    },
    getModel() {
      this.loadingModel = true;
      this.$httpX.get(`/recipe-templates/${this.modelId}`)
        .then(({ data }) => {
          if (data.notes) {
            this.data.notes = data.notes;
          }
          data.drugs.forEach((drug) => {
            this.data.drugs.push({
              key: randomHash(),
              id: drug.id,
              name: drug.name,
              quantity: drug.quantity,
              unit: drug.unit,
              access: drug.access,
              dosage: drug.dosage,
            });
          });
        })
        .catch(this.$toast.error)
        .then(() => {
          this.modelId = '';
          this.loadingModel = false;
        });
    },
    openEditModel() {
      this.editModels.show = true;
    },
    closeEditModel() {
      this.loadModels();
      this.editModels.show = false;
    },
    save() {
      const data = this.clone(this.data);
      data.printDate = this.printDate;

      if (data.date && data.date.length < 10) {
        data.date = '';
      }

      const request = !this.data.id
        ? this.$httpX
          .post(`/medical-records/${this.medicalRecordId}/recipes`, data)
        : this.$httpX
          .put(`/medical-records/${this.medicalRecordId}/recipes/${this.data.id}`, data);

      return request
        .then(({ data: result }) => {
          this.data.id = result.id;
        })
        .catch(() => {
          this.$toast.error('Ocorreu um erro ao salvar este registro. Tente novamente!');
        })
        .finally(() => {
          this.pending = false;
        });
    },
    remove() {
      this.$dialog.show('', {
        html:
          '<div class="text-center">'
          + '<h5 class="text-center">Atenção!</h5>'
          + '<div>Deseja realmente excluir este receituário?</div>'
          + '</div>',
        buttons: [
          {
            label: 'Não',
            classes: '',
          }, {
            label: 'Sim',
            classes: 'btn-primary btn-error ml-2',
            click: (close) => {
              this.$emit('remove');
              close();
            },
          },
        ],
      });
    },
    saveModel() {
      const drugs = this.data.drugs
        .filter(item => (
          item.name && item.quantity
        ));

      if (!drugs) {
        return;
      }

      this.savingModel = true;

      const names = drugs
        .map(drug => drug.name.split(' ')[0]);

      const data = {
        id: this.modelId,
        name: names.join(' + '),
        professionals: [
          { id: this.user.id, name: this.user.name },
        ],
        active: true,
        drugs,
      };

      const request = !data.id
        ? this.$httpX.post('/recipe-templates', data)
        : this.$httpX.put(`/recipe-templates/${data.id}`, data);

      // eslint-disable-next-line consistent-return
      return request
        .then(({ data: result }) => {
          const found = this.models.find(model => model.id === result.id);
          if (!found) {
            this.models.push({
              id: result.id,
              name: result.name,
              drugs: result.drugs.length,
            });
          } else {
            found.drugs = result.drugs.length;
          }
          this.modelId = result.id;
          this.$toast.show('Salvo com sucesso!');
        })
        .catch(this.$toast.error)
        .then(() => {
          this.savingModel = false;
        });
    },
    printLink() {
      return `/medical-records/${this.medicalRecordId}/recipes/${this.data.id}/print`;
    },
  },
};
</script>

<style lang="scss">
@import 'src/assets/scss/variables';
.card-manipulated-recipe {
  background-color: lighten($gray-color, 24%);
  border: $border-width solid $border-color;
  border-radius: $border-radius;
  margin-bottom: $layout-spacing-lg;
  .card-recipe-header {
    background-color: lighten($gray-color, 15%);
    padding: $layout-spacing;
  }
  .card-recipe-body {
    padding: $layout-spacing-lg $layout-spacing;
  }
  .card-recipe-footer {
    padding: $layout-spacing-lg;
    text-align: center;
    button {
      min-width: 7rem;
    }
  }

  .document-editor-body {
    background-color: $light-color;
    .ck.ck-content.ck-editor__editable {
      min-height: 15rem;
    }
  }
}
</style>
