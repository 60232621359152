<template>
  <div class="document-page">
    <card
      :patient="patient"
      :medicalRecordId="medicalRecordId"
      :data="item"
      v-for="(item, i) in items" :key="item.key"
      @remove="remove(i)" />
    <div class="document-card">
      <button class="btn btn-lg btn-gray" @click="add">
        Novo impresso
      </button>
    </div>
    <div class="document-previous" v-if="previous.length > 0">
      <h6 class="text-center">Impressos anteriores</h6>
      <div class="previous-item" v-for="(document, d) in previous" :key="d">
        <div class="title">
          <span class="text-bold">
            {{ document.date | date }} - {{ document.professionalName}}
          </span>
          <button class="btn btn-sm btn-info"
                  :class="{loading: loadingPrevious}"
                  :disabled="loadingPrevious"
                  @click="addAgain(document)">
            Solicitar novamente
          </button>
        </div>
        <div class="content" v-html="document.data" />
      </div>
    </div>
  </div>
</template>

<script>
import Card from './card/Card.vue';
import { randomHash } from '../../../../helpers';

export default {
  components: {
    Card,
  },
  props: {
    medicalRecordId: {
      type: String,
    },
    patient: {
      id: {
        type: String,
      },
      name: {
        type: String,
      },
      birthDate: {
        type: Date,
      },
      gender: {
        type: String,
      },
      phone: {
        type: String,
      },
      cellphone: {
        type: String,
      },
      identity: {
        type: {
          type: String,
        },
        value: {
          type: String,
        },
      },
      addresses: {
        type: Array,
      },
    },
  },
  data() {
    return {
      items: [],
      previous: [],
      loadingPrevious: false,
      deleting: false,
    };
  },
  async mounted() {
    this.loadPrevious();
    await this.load(this.medicalRecordId);
  },
  methods: {
    load(id) {
      return this.$httpX.get(`/medical-records/${id}/documents`)
        .then(({ data }) => {
          this.items = data.items;
        })
        .catch(this.$toast.error);
    },
    add() {
      this.items.push(this.formBlank());
    },
    remove(idx) {
      const found = this.items[idx];
      if (!found.id) {
        this.items.splice(idx, 1);
        return;
      }

      this.deleting = true;

      this.$httpX.delete(`/medical-records/${this.medicalRecordId}/documents/${found.id}`)
        .then(() => {
          this.items.splice(idx, 1);
        })
        .catch(this.$toast.error)
        .then(() => {
          this.deleting = false;
        });
    },
    addAgain(item) {
      this.loadingPrevious = true;
      const data = {
        id: '',
        data: item.data,
      };

      this.$httpX
        .post(`/medical-records/${this.medicalRecordId}/documents`, data)
        .then(({ data: document }) => {
          data.id = document.id;
          this.items.push(data);
        })
        .catch(this.$toast.error)
        .then(() => {
          this.loadingPrevious = false;
          this.$emit('scroll-top');
        });
    },
    loadPrevious() {
      this.$httpX.get(`/patients/${this.patient.id}/documents`)
        .then(({ data }) => {
          this.previous = data;
        })
        .catch(this.$toast.error);
    },
    formBlank() {
      return {
        key: randomHash(),
        id: '',
      };
    },
  },
};
</script>

<style lang="scss">
  @import "../../../../assets/scss/variables";
  .document-page {
    .document-card {
      background-color: lighten($gray-color, 24%);
      border: $border-width dotted $gray-color;
      border-radius: $border-radius;
      padding: 1.5rem 0;
      text-align: center;
      button {
        width: 12rem;
      }
    }
    .document-previous {
      margin-top: $layout-spacing-lg;
      .previous-item {
        background-color: lighten($gray-color-light, 7%);
        border: $border-color solid $border-width;
        border-radius: $border-radius;
        margin: $layout-spacing-lg 0;
        .title {
          align-items: center;
          background-color: $gray-color-ultra-light;
          border-bottom: $border-width solid $border-color;
          display: flex;
          justify-content: space-between;
          padding: $layout-spacing-lg;
        }
        .content {
          padding: $layout-spacing-lg;
        }
      }
    }
  }
</style>
