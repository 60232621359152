export const severities = {
  severe: 'Grave',
  moderate: 'Moderada',
  light: 'Leve',
  unknown: 'Desconhecida',
};

export function getName(value) {
  return severities[value];
}
