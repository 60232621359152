<template>
  <div class="medical-record-header">
    <div class="loading tile tile-centered" v-if="loading" />
    <div class="tile tile-centered" v-else>
      <div class="tile-icon">
        <figure class="avatar">
          <img :src="patientImage" :alt="patient.name">
        </figure>
      </div>
      <div class="tile-content">
        <div>
          <span v-if="patient.socialName">
            <strong>{{ patient.socialName }}</strong>
            <span> ({{ patient.name }})</span>
          </span>
          <strong v-else>{{ patient.name }}</strong>
          <span class="ml-1">
            <span class="text-info" v-if="insuranceName">[{{ insuranceName }}]</span>
            <span class="text-error text-bold" v-else>[Sem convênio definido]</span>
          </span>
          <br>
          <small>
            Nascimento: {{ patient.birthDate | date }} ({{ patient.birthDate | dateOld(false) }})
          </small>
          <small v-if="isMinor && patient.motherName">
            - Nome da mãe: {{ patient.motherName }}
          </small>
        </div>
        <button class="btn btn-sm btn-neutral mt-1 mr-1"
                @click="patientModal.show = true">
          Mais informações
        </button>
        <button class="btn btn-sm mt-1"
                :class="patient.allergy ? 'btn-warning' : 'btn-neutral'"
                @click="allergyModal.show = true">
          {{ patient.allergy ? 'Paciente com alergia(s)' : 'Informar alergia(s)' }}
        </button>
      </div>
      <div class="tile-action text-right">
        <slot></slot>
      </div>
    </div>
    <patient-view
      v-if="patientModal.show"
      :patient-id="patient.id"
      :show="patientModal.show"
      @close="patientModal.show = false"
    />
    <allergy
      v-if="allergyModal.show"
      :show="allergyModal.show"
      :id="patient.id"
      @close="allergyModal.show = false"
    />
  </div>
</template>

<script>
import moment from 'moment';
import PatientView from '../../../patient/modals/View.vue';
import Allergy from '../Allergy.vue';

export default {
  components: {
    Allergy,
    PatientView,
  },
  props: {
    patient: {
      type: Object,
    },
    appointment: {
      type: Object,
    },
    loading: {
      type: Boolean,
    },
  },
  data() {
    return {
      patientModal: {
        show: false,
      },
      allergyModal: {
        show: false,
      },
    };
  },
  computed: {
    insuranceName() {
      return this.appointment.insuranceName
        ? this.appointment.insuranceName
        : this.patient.insuranceName;
    },
    bgImage() {
      if (!this.patient.gender) {
        return null;
      }

      let backgroundImage = `url('//static.stenci.pro/images/no-image-${
        this.patient.gender}.png')`;

      if (this.image.preview) {
        backgroundImage = `url('${this.image.preview}')`;
      } else if (this.patient.imageUrl) {
        backgroundImage = `url('${this.patient.imageUrl}?w=200&h=200')`;
      }

      return { backgroundImage };
    },
    patientImage() {
      if (!this.patient || !this.patient.gender) {
        return null;
      }

      return this.patient.imageUrl
        ? `${this.patient.imageUrl}?w=200&h=200`
        : `//static.stenci.pro/images/no-image-${this.patient.gender}.png`;
    },
    isMinor() {
      if (this.patient.birthDate) {
        const duration = moment.duration(moment().diff(moment(this.patient.birthDate))).asYears();
        return duration < 18;
      }
      return false;
    },
  },
};
</script>

<style lang="scss">
@import "./src/assets/scss/_variables.scss";

.medical-record-header {
  align-items: center;
  display: flex;
  .tile {
    flex: 1;
    padding: 0 $layout-spacing-lg;
  }
  .avatar {
    height: 2.5rem;
    width: 2.5rem;
  }
  .tile-action {
    align-items: center;
    display: flex;
    .btn {
      margin-left: $layout-spacing;
    }
  }
}
</style>
