<template>
  <div>
    <dx-modal id="modal-patient-notes" title="Avisos do paciente" size="md"
              :value="show" @input="close">
      <div class="loading loading-lg" v-if="loading" />
      <div v-else>
        <div class="columns">
          <div class="column form-group">
            <input type="text" class="form-input text-bold" :value="patientName" readonly>
          </div>
          <div class="column col-auto form-group">
            <button class="btn btn-primary btn-icon btn-icon-left" @click="open">
              <fa-icon :icon="['fal', 'plus']"/>Novo aviso
            </button>
          </div>
        </div>
        <div class="empty" v-if="data.items.length === 0">
          <div class="empty-icon">
            <fa-icon :icon="['fal', 'info-circle']" size="3x"/>
          </div>
          <p class="empty-title h5">Avisos</p>
          <p class="empty-subtitle">Nenhum aviso cadastrado para este paciente.</p>
        </div>
        <table class="table table-striped table-hover" v-else>
          <thead>
          <tr>
            <th>Data</th>
            <th>Solicitante</th>
            <th>Aviso</th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(item, i) in data.items" :key="i">
            <td>{{ item.date | date }}</td>
            <td>{{ item.user.name }}</td>
            <td class="text-ellipsis" style="max-width: 130px">{{ item.notes }}</td>
            <td class="text-right">
              <button class="btn btn-sm btn-action btn-icon btn-secondary mr-1"
                      :disabled="deleting || formLoading"
                      @click="edit(item.id)">
                <fa-icon :icon="['fal', 'pencil']"></fa-icon>
              </button>
              <button class="btn btn-sm btn-action btn-icon btn-error"
                      :class="{loading: deleting && idx === i}"
                      :disabled="deleting || formLoading"
                      @click="remove(item.id, i)">
                <fa-icon :icon="['fal', 'times']"></fa-icon>
              </button>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <template slot="footer">
        <button class="btn btn-gray" @click="close">Fechar</button>
      </template>
    </dx-modal>
    <dx-modal id="modal-patient-notes-form" title="Avisos do paciente"
              v-if="showForm"
              v-model="showForm">
      <div class="loading loading-lg" v-if="formLoading" />
      <div class="columns" v-else>
        <div class="column col-12 form-group"
             :class="{'has-error': $v.form.displayTo.$error}">
          <label class="form-label">Exibir o aviso em qual recurso?</label>
          <div class="week-days" id="week-days">
            <label class="form-checkbox form-inline"
                   v-for="(item, i) in displayTo" :key="i">
              <input type="checkbox" v-model="item.selected">
              <i class="form-icon"/>{{ item.name }}
            </label>
          </div>
        </div>
        <div class="column col-12 form-group"
             :class="{'has-error': $v.form.notes.$error}">
          <label class="form-label">Mensagem</label>
          <textarea rows="3" class="form-input" v-model="form.notes" v-focus />
        </div>
      </div>
      <template slot="footer">
        <button class="btn btn-primary mr-1"
                v-if="!formLoading"
                :class="{loading: saving}"
                :disabled="saving"
                @click="save">Salvar</button>
        <button class="btn btn-gray" @click="showForm = false">Fechar</button>
      </template>
    </dx-modal>
  </div>
</template>

<script>
import formMixin from 'src/mixins/form';
import { mergeFrom } from '@/helpers/object';
import { required } from 'vuelidate/lib/validators';

export default {
  props: {
    show: {
      type: Boolean,
    },
    patientId: {
      type: String,
    },
    patientName: {
      type: String,
    },
    externalData: {
      type: Object,
      default: null,
    },
  },
  mixins: [
    formMixin,
  ],
  data() {
    return {
      loading: false,
      deleting: false,
      formLoading: false,
      saving: false,
      idx: null,
      data: {
        limit: 0,
        offset: 0,
        items: [],
      },
      displayTo: [
        { key: 'appointment', name: 'Agenda', selected: false },
        { key: 'medical_record', name: 'Prontuário', selected: false },
        { key: 'medical_report', name: 'Central de laudos', selected: false },
        { key: 'billing', name: 'Faturamento / Financeiro', selected: false },
      ],
      showForm: false,
      form: this.blankForm(),
    };
  },
  mounted() {
    if (this.externalData) {
      this.data = this.externalData;
    } else {
      this.load();
    }
  },
  validations() {
    const rules = {
      form: {
        displayTo: {},
        notes: { required },
      },
    };

    const displayTo = this.displayTo.some(({ selected }) => selected);

    if (!displayTo) {
      rules.form.displayTo = { required };
    }

    return rules;
  },
  methods: {
    load() {
      this.loading = true;
      this.clearDisplay();

      const params = {
        limit: this.data.limit,
        offset: this.data.offset,
      };

      this.$httpX.get(`/patients/${this.patientId}/alerts`, { params })
        .then(({ data }) => {
          this.data = data;
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false;
        });
    },
    close() {
      this.$emit('close');
    },
    open() {
      this.clearDisplay();
      this.form = this.blankForm();
      this.showForm = true;
    },
    edit(id) {
      this.formLoading = true;
      this.showForm = true;
      this.clearDisplay();
      this.form = this.blankForm();
      this.$httpX.get(`/patients/${this.patientId}/alerts/${id}`)
        .then(({ data }) => {
          this.form = mergeFrom(this.blankForm(), data);
          this.form.displayTo.forEach((display) => {
            const found = this.displayTo.find(({ key }) => key === display);
            if (found) {
              found.selected = true;
            }
          });
        })
        .catch(() => {
          this.$toast.error('Ocorreu um erro. Tente novamente!');
        })
        .finally(() => {
          this.formLoading = false;
        });
    },
    remove(id, i) {
      this.$dialog.show('', {
        html:
          '<div class="text-center">'
          + '<h5 class="text-center">Atenção!</h5>'
          + '<div>Deseja realmente excluir este registro?</div>'
          + '</div>',
        buttons: [
          {
            label: 'Não',
            classes: '',
          }, {
            label: 'Sim',
            classes: 'btn-primary btn-error ml-2',
            click: (close) => {
              this.idx = i;
              close();
              this.deleting = true;
              this.$httpX
                .delete(`/patients/${this.patientId}/alerts/${id}`)
                .then(() => {
                  this.data.items.splice(i, 1);
                })
                .catch(() => {
                })
                .finally(() => {
                  this.deleting = false;
                });
            },
          },
        ],
      });
    },
    async save() {
      this.$v.form.$touch();
      if (this.$v.form.$error) {
        return;
      }

      this.saving = true;

      const data = this.clone(this.form);
      delete data.user;
      data.displayTo = this.displayTo
        .filter(({ selected }) => selected)
        .map(({ key }) => key);

      const request = !this.form.id
        ? this.$httpX.post(`/patients/${this.patientId}/alerts`, data)
        : this.$httpX.put(`/patients/${this.patientId}/alerts/${this.form.id}`, data);

      // eslint-disable-next-line consistent-return
      return request
        .then(({ data: result }) => {
          const found = this.data.items.find(({ id }) => id === result.id);
          if (found) {
            found.notes = result.notes;
          } else {
            this.data.items.push(result);
          }
          this.showForm = false;
          this.$toast.show('Registro salvo');
        })
        .catch(() => {
          this.$toast.error('Ocorreu um erro. Tente novamente!');
        })
        .finally(() => {
          this.saving = false;
        });
    },
    clearDisplay() {
      this.displayTo.forEach((item) => {
        item.selected = false;
      });
    },
    blankForm() {
      return {
        id: '',
        date: '',
        notes: '',
        displayTo: [],
        patient: {
          id: '',
          name: '',
        },
        user: {
          id: '',
          name: '',
        },
      };
    },
  },
};
</script>

<style lang="scss">
@import "./src/assets/scss/_variables.scss";
#modal-patient-notes {}
#modal-patient-notes-form {
  .week-days {
    border: $border-width solid $border-color-dark;
    border-radius: $border-radius;
    display: flex;
    justify-content: space-around;
  }
}
</style>
