<template>
  <div>
    <dx-modal title="Gerenciamento de soluções e medicamentos"
              :value="show" @input="close"
              id="modal-exam" size="lg">

      <div class="loading loading-lg" v-if="loading" />

      <template v-else>
        <div class="group-title">
          <div class="columns form-group">
            <div class="column col-6">
              <div class="input-group" :class="{loading: loadingModels}">
                <select class="form-select" v-model="modelId"
                        :class="{loading: loadingModels}"
                        @change="getModel">
                  <option value="">Meus protocolos de medicamentos</option>
                  <option v-for="(item, i) in models" :value="item.id" :key="i">
                    {{ item.name }}
                  </option>
                </select>
                <button class="btn btn-neutral input-group-btn"
                        @click="openEditModel">
                  Gerenciar
                </button>
              </div>
            </div>
          </div>
        </div>

        <template v-if="form.items.length === 0">
          <div class="empty">
            <div class="empty-icon">
              <fa-icon :icon="['fal', 'info-circle']" size="2x"/>
            </div>
            <p class="empty-title h6">Lista de medicamentos</p>
            <p class="empty-subtitle">
              Nenhum medicamento adicionado. Utilize o campo abaixo para adicionar.
            </p>
          </div>
        </template>

        <template v-else>
          <medicine-item
            ref="medicineItems"
            v-for="item in form.items"
            :data="item"
            :key="item.key"
            @save="setItem"
            @remove="removeMedicine"
          />
        </template>

        <div class="search-filter">
          <div class="divider" />
          <span>Informe o nome do medicamento</span>
          <dx-autocomplete
            id="medicine"
            ref="medicine"
            v-model="medicine"
            :source="findMedicine"
            label="name"
            track-by="medicine-id"
            @select="setMedicine"
            :debounce="800"
            placeholder="Pesquisar medicamento">
            <button slot="action"
                    class="btn btn-neutral btn-action input-group-btn btn-icon">
              <fa-icon :icon="['fal', 'search']"/>
            </button>
            <template v-slot="{ item }">
              <span>
                <fa-icon class="text-primary" :icon="['fal', 'file-medical-alt']"></fa-icon>
                {{ item.name }} <small v-if="item.caption">{{ item.caption }}</small>
              </span>
            </template>
          </dx-autocomplete>
        </div>
      </template>
      <template slot="footer">
        <button class="btn btn-primary mr-1"
                :disabled="saving || loading" @click="save"
                :class="{loading: saving}">Salvar
        </button>
        <button class="btn" @click="close">Sair</button>
      </template>
    </dx-modal>

    <modal-edit
      v-if="editModels.show"
      :show="editModels.show"
      @close="closeEditModel"
    />
  </div>
</template>

<script>
import { randomHash } from '@/helpers';
import { mapState } from 'vuex';
import moment from 'moment';
import ModalEdit from '../../../../template/components/request/modals/Main.vue';
import { mergeFrom } from '../../../../../helpers/object';
import MedicineItem from './MedicineItem.vue';

export default {
  components: {
    MedicineItem,
    ModalEdit,
  },
  props: {
    show: {
      type: Boolean,
    },
    medicalRecordId: {
      type: String,
    },
    bundleId: {
      type: String,
    },
  },
  data() {
    return {
      models: [],
      modelId: '',
      medicine: '',
      loading: false,
      saving: false,
      loadingModels: false,
      editModels: {
        show: false,
      },
      form: this.blankForm(),
    };
  },
  computed: {
    ...mapState({
      user: state => state.auth.user,
    }),
  },
  async mounted() {
    this.loadModels();
    if (this.bundleId) {
      this.load();
    }
  },
  methods: {
    load() {
      this.loading = true;
      this.$http.get(`/medical-records/${this.medicalRecordId}/prescriptions/${this.bundleId}`)
        .then(({ data }) => {
          this.form = mergeFrom(this.blankForm(), data);
          this.form.items = this.form.items.map((item) => {
            item.key = randomHash();
            return item;
          });
        })
        .catch(this.$toast.error)
        .finally(() => {
          this.loading = false;
        });
    },
    loadModels() {
      const params = {
        limit: 0,
        professionalId: this.user.id,
      };
      this.$http.get('/recipe-templates', { params })
        .then(({ data }) => {
          this.models = data.items;
        })
        .catch(this.$toast.error);
    },
    getModel() {
      if (!this.modelId) {
        return;
      }

      this.loadingModels = true;
      this.$http.get(`/recipe-templates/${this.modelId}`)
        .then(({ data }) => {
          data.drugs.forEach((drug) => {
            this.form.items.push({
              id: '',
              key: randomHash(),
              item: {
                id: drug.id,
                name: drug.name,
              },
              quantity: drug.quantity,
              unit: drug.unit,
              accessType: drug.access,
              frequency: '',
              useDays: '',
              discretion: '',
            });
          });
        })
        .catch(this.$toast.error)
        .then(() => {
          this.modelId = '';
          this.loadingModels = false;
        });
    },
    openEditModel() {
      this.editModels.show = true;
    },
    closeEditModel() {
      this.loadModels();
      this.editModels.show = false;
    },
    setItem(item) {
      const found = this.form.items.find(({ key }) => key === item.key);
      if (found) {
        found.id = item.id;
        found.item = {
          id: item.item.id,
          name: item.item.name,
        };
        found.quantity = item.quantity;
        found.unit = item.unit;
        found.accessType = item.accessType;
        found.startDate = item.startDate;
        found.endDate = item.endDate;
        found.frequency = item.frequency;
        found.useDays = item.useDays;
        found.discretion = item.discretion;
        found.justification = item.justification;
      }
    },
    validations() {
      if (!Array.isArray(this.$refs.medicineItems) || this.$refs.medicineItems.length === 0) {
        this.$toast.show('Infome ao menos um medicamento!', { type: 'error' });
        return false;
      }

      if (this.$refs.medicineItems.some(item => !item.validate())) {
        this.$toast.show('Informe os campos obrigatórios!', { type: 'error' });
        return false;
      }
      return true;
    },
    save() {
      if (!this.validations()) {
        return null;
      }
      const data = {
        items: this.form.items.map((item) => {
          item = {
            id: item.id,
            type: 'medicine',
            item: {
              id: item.item.id,
              name: item.item.name,
            },
            quantity: item.quantity,
            unit: item.unit,
            accessType: item.accessType,
            frequency: item.frequency,
            useDays: item.useDays,
            discretion: item.discretion,
            justification: item.justification,
            startDate: item.startDate,
            endDate: item.useDays && item.useDays !== 'continuous'
              ? moment()
                .add(item.useDays, 'days')
                .format('YYYY-MM-DD HH:mm')
              : null,
          };
          return item;
        }),
      };

      const request = this.bundleId
        ? this.$http
          .put(`/medical-records/${this.medicalRecordId}/prescriptions/${this.bundleId}`, data)
        : this.$http
          .post(`/medical-records/${this.medicalRecordId}/prescriptions`, data);

      return request
        .then(({ data: result }) => {
          this.$emit('save', result);
        })
        .catch(this.$toast.error);
    },
    close() {
      this.$emit('close');
    },
    removeMedicine(key) {
      const found = this.form.items.find(item => item.key === key);
      const idx = this.form.items.indexOf(found);
      this.form.items.splice(idx, 1);
    },
    findMedicine(search) {
      return this.$http.get(`/medicines?search=${search}`)
        .then(({ data }) => {
          if (data.items && data.items.length === 0) {
            return [{ id: null, name: search }];
          }
          return data.items;
        });
    },
    setMedicine(data) {
      this.medicine = null;
      const found = this.form.items.find(item => item.id === data.id);
      if (found) {
        const result = Number(found.quantity);
        found.quantity = result + 1;
      } else {
        this.form.items.push({
          id: '',
          key: randomHash(),
          item: {
            id: data.id,
            name: `${data.name} ${data.display}`,
          },
          unit: data.unit,
          accessType: data.access,
          quantity: 1,
          frequency: '',
          useDays: '',
          discretion: '',
        });
      }
    },
    setName(item) {
      return item.name;
    },
    onMedicineName(text) {
      this.item.name = text;
    },
    blankForm() {
      return {
        bundleId: '',
        date: '',
        items: [],
      };
    },
  },
};
</script>

<style lang="scss">
  @import "../../../../../assets/scss/variables";
  #modal-exam {
    .group-title {
      background-color: $gray-color-light;
      margin-bottom: $layout-spacing-sm;
      padding: $layout-spacing;
    }
    .empty {
      padding: $layout-spacing-lg;
      .empty-icon {
        margin-bottom: $layout-spacing-sm;
      }
      .empty-subtitle {
        margin: 0;
      }
    }
    .table {
      background-color: $gray-color-ultra-light;
      tr th {
        background-color: $gray-color-light;
      }
    }
    .search-filter {
      margin-top: 1.4rem;
    }
  }
</style>
