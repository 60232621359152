<template>
  <div class="item-page">
    <div class="columns item-body">
      <div class="column col-6 col-sm-12 form-group"
           :class="{'has-error': $v.item.item.name.$error}">
        <dx-autocomplete
          id="medicine"
          v-model="medicine"
          :source="findMedicine"
          :custom-label="setName"
          :readonly="!!item.item.name"
          @change="onMedicineName"
          @blur="$v.item.item.name.$touch()"
          @select="setMedicine"
          :debounce="800"
          placeholder="Pesquisar medicamento">
          <button slot="action" class="btn btn-gray btn-action input-group-btn btn-icon"
                  @click="remove">
            <fa-icon :icon="['fal', 'times']"/>
          </button>
          <template v-slot="{ item }">
            <strong class="text-primary">
              <fa-icon :icon="['fal', 'capsules']"></fa-icon>
              {{ item.name }}
            </strong>
            <small v-if="item.caption">{{ item.caption }}</small>
          </template>
        </dx-autocomplete>
      </div>
      <div class="column col-6 col-sm-12 form-group"
           :class="{'has-error': $v.item.quantity.$error
             || $v.item.unit.$error
             || $v.item.accessType.$error}">
        <div class="input-group">
          <dx-input-number class="text-center" v-model="item.quantity"
                           :precision="2" placeholder="Qtd."
                           @change="$v.item.quantity.$touch()"
                           @blur="setDefaultQty"
                           :disabled="disableQuantity"/>
          <select class="form-select" v-model="item.unit"
                  @blur="$v.item.unit.$touch()"
                  @change="changeUnit">
            <option v-for="(text, value, i) in units" :key="i"
                    :value="value">{{ text }}</option>
          </select>
          <select class="form-select" v-model="item.accessType"
                  :class="{'has-error': $v.item.accessType.$error}"
                  @blur="$v.item.accessType.$touch()">
            <option v-for="(text, value, i) in accesses" :key="i"
                    :value="value">{{ text }}</option>
          </select>
        </div>
      </div>
      <div class="column col-3 col-sm-12 form-group"
           :class="{'has-error': $v.item.discretion.$error}">
        <label class="form-label">Critério</label>
        <select class="form-select" v-model="item.discretion"
                @blur="$v.item.discretion.$touch()">
          <option value="">[Selecione]</option>
          <option value="if_necessary">Se necessário</option>
          <option value="urgent">Urgente</option>
          <option value="doctor_discretion">Critério do médico</option>
        </select>
      </div>
      <div class="column col-3 col-sm-12 form-group"
           :class="{'has-error': $v.item.frequency.$error}">
        <label class="form-label">Frequência</label>
        <select class="form-select" v-model="item.frequency"
                @blur="$v.item.frequency.$touch()">
          <option value="">[Selecione]</option>
          <option v-for="(day, i) in 12" :value="day" :key="i">
            {{ day }}x ao dia
          </option>
        </select>
      </div>
      <div class="column col-2 col-sm-12 form-group"
           :class="{'has-error': $v.item.useDays.$error}">
        <label class="form-label">Qtde. Dias</label>
        <select class="form-select" v-model="item.useDays"
                @blur="$v.item.useDays.$touch()">
          <option value="">[Selecione]</option>
          <option value="continuous">Contínuo</option>
          <option v-for="(day, i) in 30" :value="day" :key="i">
            {{ day }} dia<span v-if="day > 1">s</span>
          </option>
        </select>
      </div>
      <div class="column col-4 col-sm-12 form-group"
           :class="{'has-error': $v.item.date.$error || $v.item.hour.$error}">
        <label class="form-label">Iniciar em</label>
        <div class="input-group">
          <dx-input-date class="text-center" v-model="item.date"
                         @change="$v.item.date.$touch()"
                         @blur="setDefaultDate"
          />
          <input type="text" class="form-input text-center"
                 v-model="item.hour"
                 v-mask="'00:00'" placeholder="00:00"
                 @change="$v.item.hour.$touch()"
                 @blur="setDefaultHour"
          >
        </div>
      </div>
      <div class="column col-12 form-group">
        <textarea rows="2" class="form-input"
                  v-model="item.justification"
                  placeholder="Observação / Justificativa"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { accesses } from 'src/data/drug-accesses';
import { units } from 'src/data/drug-units';
import moment from 'moment';
import {
  date,
  minDate,
  hour,
} from 'src/data/validators';
import { required } from 'vuelidate/src/validators';
import { mergeFrom, clone } from '../../../../../helpers/object';

export default {
  props: {
    data: {},
  },
  data() {
    return {
      units,
      accesses,
      medicine: null,
      disableQuantity: false,
      debounceTimeout: null,
      item: this.blankItem(),
    };
  },
  mounted() {
    this.setData();
  },
  watch: {
    item: {
      handler() {
        clearTimeout(this.debounceTimeout);
        this.debounceTimeout = setTimeout(this.save, 500);
      },
      deep: true,
    },
  },
  validations() {
    return {
      item: {
        item: {
          name: { required },
        },
        quantity: { required },
        unit: { required },
        accessType: { required },
        discretion: { required },
        frequency: { required },
        useDays: { required },
        date: {
          date,
          minDate: minDate(),
          required,
        },
        hour: { required, hour },
      },
    };
  },
  methods: {
    validate() {
      this.$v.item.$touch();
      return !this.$v.item.$error;
    },
    setData() {
      this.item = mergeFrom(this.blankItem(), this.data);
      if (this.item.startDate) {
        this.item.date = moment(this.item.startDate).format('YYYY-MM-DD');
        this.item.hour = moment(this.item.startDate).format('HH:mm');
      }
      this.medicine = {
        id: this.item.item.id,
        name: this.item.item.name,
      };
    },
    changeUnit() {
      this.disableQuantity = false;
      if (this.item.unit === 'continuous') {
        this.item.quantity = 1;
        this.disableQuantity = true;
      }
    },
    findMedicine(search) {
      return this.$http.get(`/medicines?search=${search}`)
        .then(({ data }) => {
          if (data.items.length === 0) {
            return [{ id: null, name: search }];
          }
          return data.items;
        });
    },
    setMedicine(data) {
      this.item.item.id = data.id;
      this.item.item.name = `${data.name} ${data.caption || ''}`;
      this.item.quantity = 1;
      this.item.unit = data.unit || 'packing';
      this.item.accessType = data.access || 'oral';
      this.medicine = {
        id: this.item.item.id,
        name: this.item.item.name,
      };
    },
    setName(item) {
      return item.name;
    },
    onMedicineName(text) {
      this.item.item.name = text;
    },
    remove() {
      this.$emit('remove', this.item.key);
    },
    save() {
      const data = clone(this.item);
      delete data.date;
      delete data.hour;
      data.startDate = `${this.item.date} ${this.item.hour}`;
      this.$emit('save', data);
    },
    roundedHourMinutes() {
      const now = moment();
      now.minutes(Math.ceil(now.minutes() / 10) * 10);
      return now.format('HH:mm');
    },
    setDefaultQty() {
      if (!this.item.quantity) {
        this.item.quantity = 1;
      }
    },
    setDefaultDate() {
      if (!this.item.date) {
        this.item.date = this.blankItem().date;
      }
    },
    setDefaultHour() {
      if (!this.item.hour) {
        this.item.hour = this.blankItem().hour;
      }
    },
    blankItem() {
      return {
        id: '',
        key: '',
        item: {
          id: '',
          name: '',
        },
        quantity: 1,
        unit: '',
        accessType: '',
        startDate: '',
        endDate: '',
        frequency: '',
        date: moment().format('YYYY-MM-DD'),
        hour: this.roundedHourMinutes(),
        useDays: 1,
        discretion: '',
        justification: '',
      };
    },
  },
};
</script>

<style lang="scss">
@import "../../../../../assets/scss/variables";

.item-page {
  background-color: $gray-color-ultra-light;
  border: $border-width solid $border-color;
  border-radius: $border-radius;
  margin-bottom: $layout-spacing-xl;
  padding: $layout-spacing;
}
</style>
