<template>
  <div class="card medical-record-detail">
    <div class="loading loading-lg mt-2" v-if="loading" />
    <template v-else>
      <div class="card-header">
        <div class="text-bold mb-2">
          Atendimento do dia {{ data.startDate | date('DD[ de ]MMMM[ de ]YYYY') }}
        </div>
      </div>
      <div class="card-body">
        <ophthalmology-detail
          v-if="data.anamnesis.templateRenderer && data.id"
          :data="data"
        />
        <default-detail
          v-else
          :data="data"
        />
      </div>
    </template>
    <div class="card-footer text-right">
      <button class="btn btn-sm" @click="close">Voltar para lista</button>
    </div>
  </div>
</template>

<script>
import DefaultDetail from './detail/DefaultDetail.vue';
import OphthalmologyDetail from './detail/OphthalmologyDetail.vue';

export default {
  props: {
    id: {
      type: String,
    },
  },
  components: {
    DefaultDetail,
    OphthalmologyDetail,
  },
  data() {
    return {
      loading: false,
      data: {
        id: '',
        attachments: false,
        patientId: '',
        anamnesis: {
          templateId: '',
          templateRenderer: null,
          inputs: [],
        },
        anthropometry: {
          abdominalCircumference: '',
          cephalicPerimeter: '',
          height: '',
          weight: '',
        },
        vitalSigns: {
          bloodPressure: '',
          heartRate: '',
          respiratoryFrequency: '',
          saturation: '',
          temperature: '',
        },
        documents: [],
        prescriptions: [],
      },
    };
  },
  mounted() {
    this.load(this.id);
  },
  methods: {
    load(id) {
      this.loading = true;
      return this.$httpX.get(`/medical-records/${id}`)
        .then(({ data }) => {
          this.data = data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    close() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss">
  @import "./src/assets/scss/_variables.scss";
  .medical-record-detail {
  }
</style>
