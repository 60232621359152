<template>
  <div class="card-hospitalization-page">
    <div class="card-hospitalization-header">
      <div class="columns">
        <div class="column col-3 col-md-12 col-sm-12">
          <div class="text-bold mt-1">Guia de internação</div>
        </div>
        <div class="column hide-md hide-sm" />
        <div class="column col-auto col-md-12 col-sm-12">
          <div :class="{loading: loadingModel}">
            <select class="form-select" v-model="modelId"
                    @change="getModel" :disabled="loadingModel">
              <option value="">Meus protocolos de internação</option>
              <option value="mostRequested">Mais solicitados</option>
              <option v-for="(item, i) in models" :value="item.id" :key="i">
                {{ item.name }}
              </option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <div class="card-hospitalization-body">
      <div class="columns">
        <div class="column col-2 col-sm-12 form-group"
             :class="{'has-error': $v.form.date.$error}">
          <label class="form-label">Data solicitação</label>
          <div class="input-group">
            <dx-input-date class="text-center"
                           @focus="setDate"
                           v-model="form.date"
                           @blur="$v.form.date.$touch()" />
            <button class="btn btn-neutral input-group-btn btn-icon"
                    @click="form.date = ''" tabindex="-1">
              <fa-icon :icon="['fal', 'times']"/>
            </button>
          </div>
          <template v-if="$v.form.date.$error">
            <div class="form-input-hint"
                 v-if="!$v.form.date.date">Data inválida</div>
            <div class="form-input-hint"
                 v-else-if="!$v.form.date.minDate">A data não pode ser inferior a hoje</div>
          </template>
        </div>
        <div class="column col-2 col-sm-12 form-group"
             :class="{'has-error': $v.form.admissionDate.$error}">
          <label class="form-label">Data Admissão</label>
          <dx-input-date class="text-center"
                         v-model="form.admissionDate"
                         @blur="$v.form.admissionDate.$touch()" />
          <template v-if="$v.form.admissionDate.$error">
            <div class="form-input-hint"
                 v-if="!$v.form.admissionDate.date">Data inválida</div>
            <div class="form-input-hint"
                 v-else-if="!$v.form.admissionDate.minDate">
              A data não pode ser inferior a hoje
            </div>
          </template>
        </div>
        <div class="column col-5 form-group">
          <label for="insurance" class="form-label">Convênio/ Plano</label>
          <select id="insurance" name="insurance" class="form-select"
                  v-model="form.insuranceId">
            <option value="">Selecione</option>
            <option v-for="item in insurances" :key="item.id"
                    :value="item.id">{{ item.name }}</option>
          </select>
        </div>
      </div>
      <div class="h6 mb-2">Dados do Hospital / Local Solicitado</div>
      <div class="columns form-group">
        <div class="column col-3 form-group">
          <label class="form-label">Código na operadora / CNPJ</label>
          <input type="text" class="form-input" autocomplete="nope" v-model="form.local.code">
        </div>
        <div class="column col-6 form-group">
          <label class="form-label">Nome do Hospital / Local solicitado</label>
        <input type="text" class="form-input" autocomplete="nope" v-model="form.local.name">
        </div>
        <div class="column col-3 form-group" :class="{'has-error': $v.form.suggestedDate.$error}">
          <label class="form-label">Data sugerida para internação</label>
          <dx-input-date class="text-center" autocomplete="nope" v-model="form.suggestedDate"
                         @blur="$v.form.suggestedDate.$touch()"/>
          <template v-if="$v.form.suggestedDate.$error">
            <div class="form-input-hint"
                 v-if="!$v.form.suggestedDate.date">Data inválida</div>
            <div class="form-input-hint"
                 v-else-if="!$v.form.suggestedDate.minDate">
              A data não pode ser inferior a hoje
            </div>
          </template>
        </div>
      </div>
      <div class="h6 mb-2">Dados da Internação</div>
      <div class="columns form-group">
        <div class="column col-3 col-sm-12 form-group">
          <label for="character-attendance" class="form-label">
            Caráter da Internação
          </label>
          <select id="character-attendance" name="character-attendance"
                  class="form-select" v-model="form.character">
            <option value="elective">Eletivo</option>
            <option value="emergency">Urgência/Emergência</option>
          </select>
        </div>
        <div class="column col-3 col-sm-12 form-group">
          <label for="hospitalization-type" class="form-label">
            Tipo de Internação
          </label>
          <select id="hospitalization-type" name="hospitalization-type"
                  class="form-select" v-model="form.hospitalization.type">
            <option value="clinical">1 - Clínica</option>
            <option value="surgical">2 - Cirúrgica</option>
            <option value="obstetric">3 - Obstétrica</option>
            <option value="pediatric">4 - Pediátrica</option>
            <option value="psychiatric">5 - Psiquiátrica</option>
          </select>
        </div>
        <div class="column col-4 col-sm-12 form-group">
          <label for="hospitalization-regime" class="form-label">
            Regime de Internação
          </label>
          <select id="hospitalization-regime" name="hospitalization-regime"
                  class="form-select" v-model="form.hospitalization.regime">
            <option value="hospital">1 - Hospitalar</option>
            <option value="day_hospital">2 - Hospital-dia</option>
            <option value="home_care">3 - Domiciliar</option>
          </select>
        </div>
        <div class="column col-2 col-sm-12 form-group">
          <label for="hospitalization-quantity" class="form-label">
            Qtde. Diarias
          </label>
          <input type="number" id="hospitalization-quantity" min="1"
                 class="form-input text-center"
                 v-model="form.hospitalization.quantity"
                 autocomplete="nope">
        </div>
        <div class="column col-12 form-group">
          <label class="form-label">Indicação Clínica</label>
          <input type="text"
                 class="form-input" v-model="form.clinicalIndication"
                 autocomplete="nope">
        </div>
        <div class="column col-12 form-group">
          <label class="form-label">Observação</label>
          <input type="text"
                 class="form-input" v-model="form.justification"
                 autocomplete="nope">
        </div>
      </div>
      <div class="h6 mb-2">Hipóteses Diagnósticas</div>
      <div class="columns form-group">
        <div class="column col-3 col-sm-12 form-group">
          <label for="disease-type" class="form-label">
            Tipo Doença
          </label>
          <select id="disease-type" name="disease-type"
                  class="form-select" v-model="form.diseaseType">
            <option value="acute">Aguda</option>
            <option value="chronic">Crônica</option>
          </select>
        </div>
        <div class="column col-4 col-sm-12 form-group">
          <label for="accident-indication" class="form-label">
            Indicação de Acidente
          </label>
          <select id="accident-indication" name="character-attendance"
                  class="form-select" v-model="form.accidentIndication">
            <option value="not_accident">Não acidente</option>
            <option value="work">Trabalho</option>
            <option value="transit">Trânsito</option>
            <option value="others">Outros</option>
          </select>
        </div>
        <div class="column col-12 form-group">
          <label>CID</label>
          <dx-autocomplete
            id="cid"
            v-model="cid"
            :source="findCid"
            @select="setCid"
            :debounce="800"
            placeholder="Pesquisar CID">
            <button slot="action"
                    class="btn btn-neutral btn-action input-group-btn btn-icon">
              <fa-icon :icon="['fal', 'search']"/>
            </button>
            <template v-slot="{ item }">
              <fa-icon class="text-primary" :icon="['fal', 'heartbeat']" />
              {{ item.code }} - {{ item.name }}
            </template>
          </dx-autocomplete>
          <div class="chip bg-secondary mt-2" v-for="(cid, c) in form.cids" :key="cid.code">
            {{ cid.code }} - {{ cid.name.substring(0, 40) }}{{ cid.name.length > 40 ? '...' : '' }}
            <fa-icon class="c-hand ml-2" :icon="['fal', 'times']"
                     @click="removeCid(c)"/>
          </div>
        </div>
      </div>
      <div class="h6 mb-2">Procedimentos Solicitados</div>
      <div class="mb-2">
        <table class="table" v-if="form.expenses.length > 0">
          <thead>
          <tr>
            <th style="width:50px">#</th>
            <th style="width:100px">Código</th>
            <th>Descrição</th>
            <th class="text-center" style="width:20px">Quantidade</th>
            <th style="width:40px"></th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(item, i) in form.expenses" :key="i">
            <td>{{ i + 1 }}</td>
            <td>
              <input type="text" class="form-input input-sm text-center"
                     v-mask="'00.00.00.00'"
                     v-model="item.code">
            </td>
            <td>
              <input type="text" class="form-input input-sm"
                     v-model="item.name">
            </td>
            <td class="text-center">
              <input type="text" class="form-input input-sm text-center"
                     v-mask-number maxlength="2"
                     v-model="item.quantity">
            </td>
            <td class="text-right">
              <button class="btn btn-sm btn-action btn-icon btn-error"
                      @click="removeExpense(i)">
                <fa-icon :icon="['fal', 'times']"></fa-icon>
              </button>
            </td>
          </tr>
          </tbody>
        </table>
        <div class="columns find-expense">
          <div class="column">
            <dx-autocomplete
              id="expense"
              ref="expense"
              v-model="expense"
              :source="findExpense"
              label="expense"
              track-by="expense-id"
              @select="setExpense"
              :debounce="800"
              placeholder="Pesquisar exame por código ou descrição">
              <button slot="action"
                      class="btn btn-neutral btn-action input-group-btn btn-icon">
                <fa-icon :icon="['fal', 'search']"/>
              </button>
              <template v-slot="{ item }">
              <span>
                <fa-icon class="text-primary" :icon="['fal', 'file-medical-alt']"></fa-icon>
                {{ item.name }} ({{ item.code | tuss }})
              </span>
                <span>{{ item.display }}</span>
              </template>
            </dx-autocomplete>
          </div>
          <div class="column col-auto">
            <button
              @click="addExpense"
              class="btn btn-icon btn-icon-left btn-gray">
              <fa-icon :icon="['fal', 'plus-circle']" />Adicionar manualmente
            </button>
          </div>
        </div>
      </div>
      <div class="divider" />
      <div class="h6 mb-2">OPM Solicitados</div>
      <div class="mb-2">
        <table class="table" v-if="form.opmes.length > 0">
          <thead>
          <tr>
            <th style="width:50px">#</th>
            <th style="width:100px">Código</th>
            <th>Descrição</th>
            <th class="text-center" style="width:20px">Quantidade</th>
            <th style="width:40px"></th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(item, i) in form.opmes" :key="i">
            <td>{{ i + 1 }}</td>
            <td>
              <input type="text" class="form-input input-sm text-center"
                     v-mask="'00.00.00.00'"
                     v-model="item.code">
            </td>
            <td>
              <input type="text" class="form-input input-sm"
                     v-model="item.name">
            </td>
            <td class="text-center">
              <input type="text" class="form-input input-sm text-center"
                     v-mask-number maxlength="2"
                     v-model="item.quantity">
            </td>
            <td class="text-right">
              <button class="btn btn-sm btn-action btn-icon btn-error"
                      @click="removeOpme(i)">
                <fa-icon :icon="['fal', 'times']"></fa-icon>
              </button>
            </td>
          </tr>
          </tbody>
        </table>
        <div class="columns find-expense">
          <div class="column">
            <dx-autocomplete
              id="opme"
              ref="opme"
              v-model="opme"
              :source="findOpme"
              label="opme"
              track-by="opme-id"
              @select="setOpme"
              :debounce="800"
              placeholder="Pesquisar por código ou descrição">
              <button slot="action"
                      class="btn btn-neutral btn-action input-group-btn btn-icon">
                <fa-icon :icon="['fal', 'search']"/>
              </button>
              <template v-slot="{ item }">
                <div>
                  <fa-icon class="text-primary" :icon="['fal', 'file-medical-alt']"></fa-icon>
                  {{ item.technicalName }} ({{ item.code | tuss }})
                </div>
                <div>{{ item.model }}</div>
              </template>
            </dx-autocomplete>
          </div>
          <div class="column col-auto">
            <button
              class="btn btn-icon btn-icon-left btn-gray" @click="addOpme">
              <fa-icon :icon="['fal', 'plus-circle']" />Adicionar manualmente
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="card-hospitalization-footer">
      <button class="btn btn-gray btn-icon-left"
              :class="{loading: printing}"
              :disabled="!haveExpenses || printing"
              @click="print">
        <fa-icon :icon="['fal', 'print']"/>
        Imprimir
      </button>
      <button class="btn btn-icon-left btn-error ml-2"
              @click="remove">
        <fa-icon :icon="['fal', 'trash']"/>
        Excluir
      </button>
      <div class="popover ml-2">
        <div class="popover-container">
          <div class="card">
            <div class="card-header">
              <h6>Protocolo de internação</h6>
            </div>
            <div class="card-body">
              Você pode salvar diversos protocolos de internação
              para ser utilizado em outros pacientes
            </div>
          </div>
        </div>
<!--        <button class="btn btn-icon-left btn-neutral"-->
<!--                :class="{loading: savingModel}"-->
<!--                :disabled="!haveExpenses || savingModel"-->
<!--                @click="saveModel">-->
<!--          <fa-icon :icon="['fal', 'book-medical']"/>-->
<!--          <span v-if="modelId">Atualizar meu protocolo</span>-->
<!--          <span v-else>Adicionar em meus modelos de protocolos</span>-->
<!--        </button>-->
      </div>
      <div class="popover ml-2">
        <div class="popover-container">
          <div class="card">
            <div class="card-header">
              <h6>Protocolo de internação</h6>
            </div>
            <div class="card-body">
              Você pode salvar diversos protocolos de internação
              para ser utilizado em outros pacientes
            </div>
          </div>
        </div>
      </div>
    </div>
    <dx-modal title="Exames mais solicitados"
              :value="modalMostRequested.show" size="lg"
              @input="modalMostRequested.show = false" id="modal-most-requested">
      <template v-if="modalMostRequested.items.length === 0">
        <div class="empty mt-2">
          <div class="empty-icon">
            <fa-icon :icon="['fal', 'info-circle']" size="3x"/>
          </div>
          <p class="empty-title h5">Nenhum exame solicitado</p>
          <p class="empty-subtitle">
            Ainda não foi realizado nenhuma solicitação de exames.
          </p>
        </div>
      </template>
      <table class="table table-striped table-hover" v-else>
        <thead>
        <tr>
          <th></th>
          <th>Código</th>
          <th>Descrição</th>
          <th class="text-center">Quantidade</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(item, i) in modalMostRequested.items" :key="i">
          <td class="text-center" width="60px">
            <div class="form-group">
              <label class="form-checkbox">
                <input type="checkbox" v-model="item.selected">
                <i class="form-icon"></i>
              </label>
            </div>
          </td>
          <td>{{ item.code | tuss }}</td>
          <td>{{ item.name }}</td>
          <td class="text-center">{{ item.quantity }}</td>
        </tr>
        </tbody>
      </table>
      <template slot="footer">
        <button class="btn btn-gray mr-1"
                :class="{loading: modalMostRequested.saving}"
                :disabled="modalMostRequested.saving
                  || modalMostRequested.items.length === 0"
                @click="addMostRequested">Adicionar</button>
        <button class="btn" @click="modalMostRequested.show = false">Sair</button>
      </template>
    </dx-modal>
  </div>
</template>

<script>
import { randomHash } from '@/helpers';
import moment from 'moment';
import { mapState } from 'vuex';
import { date, minDate } from '../../../../../data/validators';
import { clone } from '../../../../../helpers/object';

export default {
  props: {
    medicalRecordId: {
      type: String,
    },
    lastRequests: {
      type: Array,
    },
    data: {
      id: {
        type: String,
      },
      date: {
        type: Date,
      },
      admissionDate: {
        type: Date,
      },
      patient: {
        name: {
          type: String,
        },
        record: {
          type: String,
        },
      },
      insuranceId: {
        type: String,
      },
      requester: {
        identity: {
          type: String,
        },
        name: {
          type: String,
        },
        cnes: {
          type: String,
        },
      },
      character: {
        type: String,
      },
      hospitalization: {
        type: {
          type: String,
        },
        regime: {
          type: String,
        },
        quantity: {
          type: String,
        },
      },
      local: {
        code: {
          type: String,
        },
        name: {
          type: String,
        },
      },
      suggestedDate: {
        type: Date,
      },
      clinicalIndication: {
        type: String,
      },
      justification: {
        type: String,
      },
      diseaseType: {
        type: String,
      },
      accidentIndication: {
        type: String,
      },
      cids: {
        type: Array,
      },
      expenses: {
        type: Array,
      },
      opmes: {
        type: Array,
      },
    },
  },
  data() {
    return {
      insurances: [],
      models: [],
      modelId: '',
      debounceTimeout: null,
      expense: '',
      opme: '',
      cid: null,
      loadingModel: false,
      savingModel: false,
      printing: false,
      modalMostRequested: {
        show: false,
        saving: false,
        items: [],
      },
      form: {
        id: '',
        type: '',
        date: moment().format('YYYY-MM-DD'),
        admissionDate: '',
        patient: {
          name: '',
          record: '',
        },
        local: {
          code: '',
          name: '',
        },
        insuranceId: '',
        requester: {
          identity: '',
          name: '',
          cnes: '',
        },
        character: '',
        hospitalization: {
          type: '',
          regime: '',
          quantity: '',
        },
        suggestedDate: '',
        clinicalIndication: '',
        justification: '',
        diseaseType: '',
        accidentIndication: '',
        cids: [],
        expenses: [],
        opmes: [],
      },
    };
  },
  validations() {
    return {
      form: {
        date: { date },
        admissionDate: { date },
        suggestedDate: { date, minDate: minDate(moment().format('YYYY-MM-DD')) },
      },
    };
  },
  async mounted() {
    await this.loadInsurances();
    this.form = this.data;
    if (this.form.date === null) {
      this.form.date = '';
    }
    this.loadModels();
    this.loadMostRequested();
    this.setWatchers();
  },
  computed: {
    haveExpenses() {
      return this.form.expenses.length > 0;
    },
    ...mapState({
      user: state => state.auth.user,
    }),
  },
  methods: {
    setDate() {
      if (!this.form.date) {
        this.form.date = moment().format('YYYY-MM-DD');
      }
    },
    setWatchers() {
      this.$watch('form', {
        handler() {
          clearTimeout(this.debounceTimeout);
          this.debounceTimeout = setTimeout(this.save, 1000);
        },
        deep: true,
      });
    },
    loadInsurances() {
      const params = {
        limit: 200,
      };
      return this.$httpX.get('/insurances', { params })
        .then(({ data }) => {
          this.insurances = data.items;
        })
        .catch(this.$toast.error);
    },
    loadModels() {
      const params = {
        active: true,
        type: 'hospitalization',
        limit: 0,
      };
      this.$httpX.get('/request-templates', { params })
        .then(({ data }) => {
          this.models = data.items;
        })
        .catch(this.$toast.error);
    },
    findCid(search) {
      return this.$httpX.get(`/services/cids?search=${search}`)
        .then(({ data }) => {
          if (data.items.length === 0) {
            return [];
          }
          return data.items;
        });
    },
    setCid(data) {
      this.cid = null;
      this.form.cids.push({
        code: data.code,
        name: data.name,
      });
    },
    removeCid(idx) {
      this.form.cids.splice(idx, 1);
    },
    getModel() {
      if (!this.modelId) {
        return;
      }

      if (this.modelId === 'mostRequested') {
        this.modalMostRequested.items.forEach((item) => {
          item.selected = false;
        });
        this.modalMostRequested.show = true;
      } else {
        this.loadingModels = true;
        this.$httpX.get(`/request-templates/${this.modelId}`)
          .then(({ data }) => {
            if (data && data.expenses.length > 0) {
              data.expenses.forEach((expense) => {
                const found = this.form.expenses.find(item => item.id === expense.id);
                if (found) {
                  const result = Number(found.quantity);
                  found.quantity = result + Number(expense.quantity);
                } else {
                  this.form.expenses.push({
                    key: randomHash(),
                    id: expense.id,
                    code: expense.code,
                    name: expense.name,
                    quantity: expense.quantity,
                  });
                }
              });
            }
            if (data && data.opmes.length > 0) {
              data.opmes.forEach((expense) => {
                const found = this.form.opmes.find(item => item.id === expense.id);
                if (found) {
                  const result = Number(found.quantity);
                  found.quantity = result + Number(expense.quantity);
                } else {
                  this.form.opmes.push(expense);
                }
              });
            }
          })
          .catch(this.$toast.error)
          .then(() => {
            this.modelId = '';
            this.loadingModels = false;
          });
      }
    },
    loadMostRequested() {
      const params = {
        type: 'hospitalization',
      };

      return this.$httpX.get('/professional-most-requested', { params })
        .then(({ data }) => {
          this.modalMostRequested.items = data.items.map((item) => {
            item.selected = false;
            return item;
          });
        }).catch(this.$toast.error);
    },
    addMostRequested() {
      const selectedItems = this.modalMostRequested.items
        .filter(({ selected }) => selected);
      if (selectedItems) {
        selectedItems.forEach((expense) => {
          const found = this.form.expenses.find(item => item.id === expense.id);
          if (found) {
            const result = Number(found.quantity);
            found.quantity = result + 1;
          } else {
            this.form.expenses.push({
              key: randomHash(),
              id: expense.id,
              code: expense.code,
              name: expense.name,
              quantity: expense.quantity,
            });
          }
        });
      }
      this.modalMostRequested.show = false;
      this.modelId = '';
    },
    saveModel() {
      if (this.form.expenses.length === 0) {
        return;
      }
      this.savingModel = true;
      const names = this.form.expenses
        .map(expense => expense.name.split(' ')[0]);

      const data = {
        id: this.modelId,
        type: this.form.type,
        name: names.join(' + '),
        professionals: [
          { id: this.user.id, name: this.user.name },
        ],
        active: true,
        expenses: this.form.expenses,
        opmes: this.form.opmes,
      };

      const request = !data.id
        ? this.$httpX.post('/request-templates', data)
        : this.$httpX.put(`/request-templates/${data.id}`, data);

      // eslint-disable-next-line consistent-return
      return request
        .then(({ data: result }) => {
          const found = this.models.find(model => model.id === result.id);
          if (!found) {
            this.models.push({
              id: result.id,
              name: result.name,
              expenses: result.expenses.length,
              opmes: result.opmes.length,
            });
          } else {
            found.name = result.name;
            found.expenses = result.expenses.length;
            found.opmes = result.opmes.length;
          }
          this.modelId = result.id;
          this.$toast.show('Salvo com sucesso!');
        })
        .catch(this.$toast.error)
        .then(() => {
          this.savingModel = false;
        });
    },
    save() {
      this.$v.form.$touch();
      if (this.$v.form.$error) {
        return false;
      }

      const data = clone(this.form);

      if (!data.date || !moment(data.date, 'YYYY-MM-DD', true).isValid()) {
        delete data.date;
      }

      const request = !data.id
        ? this.$httpX
          .post(
            `/medical-records/${this.medicalRecordId}/hospitalization-requests`,
            data,
          )
        : this.$httpX
          .put(
            `/medical-records/${this.medicalRecordId}/hospitalization-requests/${data.id}`,
            data,
          );

      return request
        .then(({ data: result }) => {
          this.form.id = result.id;
        })
        .catch(this.$toast.error);
    },
    remove() {
      this.$dialog.show('', {
        html:
          '<div class="text-center">'
          + '<h5 class="text-center">Atenção!</h5>'
          + '<div>Deseja realmente excluir esta solicitação?</div>'
          + '</div>',
        buttons: [
          {
            label: 'Não',
            classes: '',
          }, {
            label: 'Sim',
            classes: 'btn-primary btn-error ml-2',
            click: (close) => {
              this.$emit('remove');
              close();
            },
          },
        ],
      });
    },
    print() {
      this.printing = true;
      return this.$fileX
        .print(`/medical-records/${
          this.medicalRecordId}/hospitalization-requests/${this.form.id}/print`)
        .catch(this.$toast.error)
        .then(() => {
          this.printing = false;
        });
    },
    removeExpense(idx) {
      this.form.expenses.splice(idx, 1);
    },
    findExpense(search) {
      const params = {
        types: 'procedure',
        search,
        limit: 100,
      };

      return this.$httpX.get('/tuss', { params })
        .then(({ data }) => data.items);
    },
    setExpense(data) {
      this.expense = null;
      const found = this.form.expenses.find(item => item.id === data.id);
      if (found) {
        const result = Number(found.quantity);
        found.quantity = result + 1;
      } else {
        this.form.expenses.push({
          key: randomHash(),
          id: data.id,
          code: data.code,
          name: data.name,
          quantity: 1,
        });
      }
    },
    addExpense() {
      this.expense = null;
      this.form.expenses.push({
        key: randomHash(),
        id: null,
        code: '00000000',
        name: 'Informe o exame',
        quantity: 1,
      });
    },
    removeOpme(idx) {
      this.form.opmes.splice(idx, 1);
    },
    findOpme(search) {
      const params = {
        types: 'material',
        search,
        limit: 100,
      };
      return this.$httpX.get('/tuss', { params })
        .then(({ data }) => data.items);
    },
    setOpme(data) {
      this.opme = null;
      const found = this.form.opmes.find(item => item.id === data.id);
      if (found) {
        const result = Number(found.quantity);
        found.quantity = result + 1;
      } else {
        this.form.opmes.push({
          key: randomHash(),
          id: data.id,
          code: data.code,
          name: `${data.technicalName} ${data.model}`,
          quantity: 1,
        });
      }
    },
    addOpme() {
      this.opme = null;
      this.form.opmes.push({
        key: randomHash(),
        id: null,
        code: '00000000',
        name: 'Informe o procedimento',
        quantity: 1,
      });
    },
    addLastRequests(item) {
      this.form.expenses.push(item);
    },
  },
};
</script>

<style lang="scss">
  @import "../../../../../assets/scss/variables";

  $bg-color: lighten($gray-color, 24%);

  .card-hospitalization-page {
    background-color: $bg-color;
    border: $border-width solid $border-color;
    border-radius: $border-radius;
    margin-bottom: $layout-spacing-lg;
    .card-hospitalization-header {
      background-color: lighten($gray-color, 15%);
      padding: $layout-spacing;
    }
    .card-hospitalization-body {
      padding: $layout-spacing;
      .table {
        margin-bottom: $layout-spacing-lg;
        tbody tr {
          background-color: $gray-color-light;
        }
        td {
          border-bottom-color: $bg-color;
        }
      }
    }
    .card-hospitalization-footer {
      padding: $layout-spacing-lg;
      text-align: center;
      button {
        min-width: 7rem;
      }
    }
  }
</style>
