<template>
  <div class="medical-record-default-item">
    <div v-if="item.anthropometry.height">
      <strong>Altura:</strong> {{ item.anthropometry.height }} cm
    </div>
    <div v-if="item.anthropometry.weight">
      <strong>Peso:</strong> {{ item.anthropometry.weight }} kg
    </div>
    <br>
    <div class="mb-2"
         v-for="(item, n) in item.anamnesis.inputs" :key="n">
      <span v-html="item.value"/>
    </div>

    <template v-if="item.documents > 0
      || item.recipes > 0
      || item.prescriptions > 0
      || item.examRequests > 0
      || item.hospitalizationRequests > 0
      || item.attachments > 0">

      <div class="divider" />
      <div class="text-bold mb-2">Documentos emitidos</div>
      <div class="mb-2" v-if="item.prescriptions > 0">
        <strong class="hover-link" :class="{loading: loadingItem === 'prescription'}"
                @click="openPrescription">
          ({{ item.prescriptions }})
          Prescriç<span v-if="item.prescriptions === 1">ão</span><span v-else>ões</span>
        </strong>
        <prescription-detail
          :id="item.id"
          :items="detailData.prescriptions"
          v-if="show.prescription"
        />
      </div>
      <div class="mb-2" v-if="item.documents > 0">
        <strong class="hover-link" :class="{loading: loadingItem === 'document'}"
                @click="openDocument">
          ({{ item.documents }}) Impresso<span v-if="item.documents > 1">s</span>
        </strong>
        <document-detail
          :id="item.id"
          :items="detailData.documents"
          v-if="show.document"
        />
      </div>
      <div class="mb-2" v-if="item.recipes > 0">
        <strong class="hover-link" :class="{loading: loadingItem === 'recipe'}"
                @click="openRecipe">
          ({{ item.recipes }}) Receita<span v-if="item.recipes > 1">s</span>
        </strong>
        <recipe-detail
          :id="item.id"
          :items="detailData.recipes"
          v-if="show.recipe"
        />
      </div>
      <div class="mb-2" v-if="item.examRequests > 0">
        <strong class="hover-link" :class="{loading: loadingItem === 'request'}"
                @click="openRequest">({{ item.examRequests }})
          <span v-if="item.examRequests === 1">Solicitação de exames</span>
          <span v-else>Solicitações de exames</span>
        </strong>
        <request-detail
          :id="item.id"
          :items="detailData.examRequests"
          v-if="show.request"
        />
      </div>
      <div class="mb-2" v-if="item.hospitalizationRequests > 0">
        <strong class="hover-link" :class="{loading: loadingItem === 'hospitalization'}"
                @click="openHospitalization">
          ({{ item.hospitalizationRequests }})
          <span v-if="item.hospitalizationRequests === 1">Solicitação de internação</span>
          <span v-else>Solicitações de internação</span>
        </strong>
        <hospitalization-detail
          :id="item.id"
          :items="detailData.hospitalizationRequests"
          v-if="show.hospitalization"
        />
      </div>
      <div class="mb-2" v-if="item.attachments > 0">
        <strong class="hover-link" :class="{loading: loadingItem === 'attachment'}"
                @click="openAttachment">
          ({{ item.attachments }}) Anexo<span v-if="item.attachments > 1">s</span>
        </strong>
        <attachment-detail
          :id="item.id"
          :patientId="item.patient.id"
          v-if="show.attachment"
        />
      </div>
    </template>
  </div>
</template>

<script>
import AttachmentDetail from '../attachment/Detail.vue';
import DocumentDetail from '../document/Detail.vue';
import HospitalizationDetail from '../request/HospitalizationDetail.vue';
import PrescriptionDetail from '../prescription/Detail.vue';
import RecipeDetail from '../receipt/Detail.vue';
import RequestDetail from '../request/Detail.vue';

export default {
  props: {
    item: {
      type: Object,
    },
  },
  components: {
    AttachmentDetail,
    DocumentDetail,
    HospitalizationDetail,
    PrescriptionDetail,
    RecipeDetail,
    RequestDetail,
  },
  data() {
    return {
      loadingItem: null,
      show: {
        document: false,
        recipe: false,
        request: false,
        hospitalization: false,
        attachment: false,
        prescription: false,
      },
      detailData: {
        id: '',
        attachments: false,
        patientId: '',
        anamnesis: {
          templateId: '',
          templateRenderer: null,
          inputs: [],
        },
        anthropometry: {
          abdominalCircumference: '',
          cephalicPerimeter: '',
          height: '',
          weight: '',
        },
        vitalSigns: {
          bloodPressure: '',
          heartRate: '',
          respiratoryFrequency: '',
          saturation: '',
          temperature: '',
        },
        examRequests: [],
        hospitalizationRequests: [],
        recipes: [],
        documents: [],
        prescriptions: [],
      },
    };
  },
  methods: {
    async openPrescription() {
      this.loadingItem = 'prescription';
      this.show.prescription = !this.show.prescription;
      if (!this.detailData.id) {
        await this.loadDetail();
      }
      this.loadingItem = null;
    },
    async openDocument() {
      this.loadingItem = 'document';
      this.show.document = !this.show.document;
      if (!this.detailData.id) {
        await this.loadDetail();
      }
      this.loadingItem = null;
    },
    async openRecipe() {
      this.loadingItem = 'recipe';
      this.show.recipe = !this.show.recipe;
      if (!this.detailData.id) {
        await this.loadDetail();
      }
      this.loadingItem = null;
    },
    async openRequest() {
      this.loadingItem = 'request';
      this.show.request = !this.show.request;
      if (!this.detailData.id) {
        await this.loadDetail();
      }
      this.loadingItem = null;
    },
    async openHospitalization() {
      this.loadingItem = 'hospitalization';
      this.show.hospitalization = !this.show.hospitalization;
      if (!this.detailData.id) {
        await this.loadDetail();
      }
      this.loadingItem = null;
    },
    async openAttachment() {
      this.loadingItem = 'attachment';
      this.show.attachment = !this.show.attachment;
      if (!this.detailData.id) {
        await this.loadDetail();
      }
      this.loadingItem = null;
    },
    loadDetail() {
      return this.$httpX.get(`/medical-records/${this.item.id}`)
        .then(({ data }) => {
          this.detailData = data;
        });
    },
  },
};
</script>

<style lang="scss">
@import "./src/assets/scss/_variables.scss";

.medical-record-default-item {
  .card-detail {
    background-color: lighten($gray-color-light, 5%);
    border: $border-color solid $border-width;
    border-radius: $border-radius;
    margin: $layout-spacing 0;
    padding: $layout-spacing;
    .card-detail-title {
      font-weight: bold;
      margin-bottom: $layout-spacing;
    }
    .card-drug {
      background-color: $gray-color-light;
      border: $border-color solid $border-width;
      border-radius: $border-radius;
      margin-bottom: $layout-spacing;
      padding: $layout-spacing;
    }
  }
}
</style>
