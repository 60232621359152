<template>
  <div class="ophthalmology-page">
    <div class="columns">
      <div class="column col-12 form-group"
           :class="{'has-error': $v.input_60ede9a29f3f7f7c8c95987c.$error}">
        <label class="form-label text-bold">Queixa principal</label>
        <textarea v-model="input_60ede9a29f3f7f7c8c95987c"
                  @blur="$v.input_60ede9a29f3f7f7c8c95987c.$touch()"
                  class="form-input" rows="2"/>
        <template v-if="$v.input_60ede9a29f3f7f7c8c95987c.$error">
          <div class="form-input-hint"
               v-if="!$v.input_60ede9a29f3f7f7c8c95987c.required">
            Campo obrigatório
          </div>
        </template>
      </div>
      <div class="column col-12 form-group">
        <label class="form-label text-bold">História pregressa</label>
        <textarea v-model="input_60ede9a29f3f7f7c8c95987d"
                  class="form-input" rows="2"/>
      </div>
      <div class="column col-12 form-group">
        <div class="group-items auto-ref">
          <div class="columns">
            <div class="column col-4 col-md-12 col-sm-12 form-group">
              <label class="form-label text-bold">Auto Refração</label>
              <div class="items mb-1">
                <span class="side">D</span>
                <div>
                  <input type="number" class="form-input text-center"
                         :class="input_60ede9a29f3f7f7c8c95987e
                         && input_60ede9a29f3f7f7c8c95987e.toString().substr(0, 1) === '-'
                         ? 'negative-value' : ''"
                         step="0.25" min="-35" max="30"
                         v-model="input_60ede9a29f3f7f7c8c95987e"
                         placeholder="Esf.">
                </div>
                <div>
                  <input type="number" class="form-input text-center"
                         :class="input_60ede9a29f3f7f7c8c959880
                         && input_60ede9a29f3f7f7c8c959880.toString().substr(0, 1) === '-'
                         ? 'negative-value' : ''"
                         step="0.25" min="-30" max="0"
                         v-model="input_60ede9a29f3f7f7c8c959880"
                         @blur="$v.input_60ede9a29f3f7f7c8c959882.$touch()"
                         placeholder="Cil.">
                </div>
                <div :class="{'has-error': $v.input_60ede9a29f3f7f7c8c959882.$error}">
                  <input type="number" class="form-input text-center"
                         step="1" min="0" max="180"
                         v-model="input_60ede9a29f3f7f7c8c959882"
                         placeholder="Eixo">
                </div>
              </div>
              <div class="items">
                <span class="side">E</span>
                <div>
                  <input type="number" class="form-input text-center mr-1"
                         :class="input_60ede9a29f3f7f7c8c95987f
                         && input_60ede9a29f3f7f7c8c95987f.toString().substr(0, 1) === '-'
                         ? 'negative-value' : ''"
                         step="0.25" min="-35" max="30"
                         v-model="input_60ede9a29f3f7f7c8c95987f"
                         placeholder="Esf.">
                </div>
                <div>
                  <input type="number" class="form-input text-center mr-1"
                         :class="input_60ede9a29f3f7f7c8c959881
                         && input_60ede9a29f3f7f7c8c959881.toString().substr(0, 1) === '-'
                         ? 'negative-value' : ''"
                         step="0.25" min="-30" max="0"
                         @blur="$v.input_60ede9a29f3f7f7c8c959883.$touch()"
                         v-model="input_60ede9a29f3f7f7c8c959881"
                         placeholder="Cil.">
                </div>
                <div :class="{'has-error': $v.input_60ede9a29f3f7f7c8c959883.$error}">
                  <input type="number" class="form-input text-center"
                         step="1" min="0" max="180"
                         v-model="input_60ede9a29f3f7f7c8c959883"
                         placeholder="Eixo">
                </div>
              </div>
            </div>
            <div class="column col-4 col-md-12 col-sm-12 form-group">
              <label class="form-label text-bold">Ceratometria</label>
              <div class="items mb-1">
                <span class="side">D</span>
                <div>
                  <input type="number" class="form-input text-center mr-1"
                         step="0.25" min="20" max="80"
                         v-model="input_60ede9a29f3f7f7c8c959884"
                         placeholder="K1">
                </div>
                <div>
                  <input type="number" class="form-input text-center mr-1"
                         step="0.25" min="20" max="80"
                         @blur="$v.input_60ede9a29f3f7f7c8c959888.$touch()"
                         v-model="input_60ede9a29f3f7f7c8c959886"
                         placeholder="K2">
                </div>
                <div :class="{'has-error': $v.input_60ede9a29f3f7f7c8c959888.$error}">
                  <input type="number" class="form-input text-center"
                         step="1" min="0" max="180"
                         v-model="input_60ede9a29f3f7f7c8c959888"
                         placeholder="Eixo">
                </div>
              </div>
              <div class="items">
                <span class="side">E</span>
                <div>
                  <input type="number" class="form-input text-center mr-1"
                         step="0.25" min="20" max="80"
                         v-model="input_60ede9a29f3f7f7c8c959885"
                         placeholder="K1">
                </div>
                <div>
                  <input type="number" class="form-input text-center mr-1"
                         step="0.25" min="20" max="80"
                         @blur="$v.input_60ede9a29f3f7f7c8c959889.$touch()"
                         v-model="input_60ede9a29f3f7f7c8c959887"
                         placeholder="K2">
                </div>
                <div :class="{'has-error': $v.input_60ede9a29f3f7f7c8c959889.$error}">
                  <input type="number" class="form-input text-center"
                         step="1" min="0" max="180"
                         v-model="input_60ede9a29f3f7f7c8c959889"
                         placeholder="Eixo">
                </div>
              </div>
            </div>
            <div class="column col-4 col-md-12 col-sm-12 form-group">
              <label class="form-label text-bold">Lensometria</label>
              <div class="items mb-1">
                <span class="side">D</span>
                <div>
                  <input type="number" class="form-input text-center mr-1"
                         :class="input_60ede9a29f3f7f7c8c95988a
                         && input_60ede9a29f3f7f7c8c95988a.toString().substr(0, 1) === '-'
                         ? 'negative-value' : ''"
                         step="0.25" min="-35" max="30"
                         v-model="input_60ede9a29f3f7f7c8c95988a"
                         placeholder="Esf.">
                </div>
                <div>
                  <input type="number" class="form-input text-center mr-1"
                         :class="input_60ede9a29f3f7f7c8c95988c
                         && input_60ede9a29f3f7f7c8c95988c.toString().substr(0, 1) === '-'
                         ? 'negative-value' : ''"
                         step="0.25" min="-30" max="0"
                         @blur="$v.input_60ede9a29f3f7f7c8c95988e.$touch()"
                         v-model="input_60ede9a29f3f7f7c8c95988c"
                         placeholder="Cil.">
                </div>
                <div :class="{'has-error': $v.input_60ede9a29f3f7f7c8c95988e.$error}">
                  <input type="number" class="form-input text-center"
                         step="1" min="0" max="180"
                         v-model="input_60ede9a29f3f7f7c8c95988e"
                         placeholder="Eixo">
                </div>
                <div>
                  <input type="number" class="form-input text-center"
                         step="0.25" min="0.50" max="6" placeholder="Adição"
                         v-model="input_60ede9a29f3f7f7c8d95988a">
                </div>
              </div>
              <div class="items">
                <span class="side">E</span>
                <div>
                  <input type="number" class="form-input text-center mr-1"
                         :class="input_60ede9a29f3f7f7c8c95988b
                         && input_60ede9a29f3f7f7c8c95988b.toString().substr(0, 1) === '-'
                         ? 'negative-value' : ''"
                         step="0.25" min="-35" max="30"
                         v-model="input_60ede9a29f3f7f7c8c95988b"
                         placeholder="Esf.">
                </div>
                <div>
                  <input type="number" class="form-input text-center mr-1"
                         :class="input_60ede9a29f3f7f7c8c95988d
                         && input_60ede9a29f3f7f7c8c95988d.toString().substr(0, 1) === '-'
                         ? 'negative-value' : ''"
                         step="0.25" min="-30" max="0"
                         @blur="$v.input_60ede9a29f3f7f7c8c95988f.$touch()"
                         v-model="input_60ede9a29f3f7f7c8c95988d"
                         placeholder="Cil.">
                </div>
                <div :class="{'has-error': $v.input_60ede9a29f3f7f7c8c95988f.$error}">
                  <input type="number" class="form-input text-center"
                         step="1" min="0" max="180"
                         v-model="input_60ede9a29f3f7f7c8c95988f"
                         placeholder="Eixo">
                </div>
                <div>
                  <select class="form-select text-center"
                          v-model="input_60ede9a29f3f7f7d8c95988b">
                    <option value="null">Qtde.</option>
                    <option v-for="(item, i) in qty" :value="item"
                            :key="i">{{ item }}</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <button class="btn btn-sm btn-gray-outline mb-1"
                  @click="openOphthalmologyModal('autoRefraction')">Exibir Histórico</button>
        </div>
      </div>
      <div class="column col-6 col-md-12 col-sm-12 form-group">
        <div class="group-items auto-pio">
          <div class="columns">
            <div class="column col-6 form-group">
              <label class="form-label text-bold">Auto PIO</label>
              <div class="items mb-1">
                <span class="side">D</span>
                <input type="number" class="form-input text-center mr-1"
                       step="1" min="0" max="80"
                       @change="setAutoPioHour"
                       v-model="input_60ede9a29f3f7f7c8c959890">
                <span class="side">E</span>
                <input type="number" class="form-input text-center"
                       step="1" min="0" max="80"
                       @change="setAutoPioHour"
                       v-model="input_60ede9a29f3f7f7c8c959891">
              </div>
              <div class="items">
                <span class="side"></span>
                <input type="text" class="form-input text-center"
                       disabled placeholder="00:00"
                       v-model="input_60ede9a29f3f7f7c8c959893">
              </div>
            </div>
            <div class="column col-6 form-group">
              <label class="form-label text-bold">PIO</label>
              <div class="items mb-1">
                <span class="side">D</span>
                <input type="number" class="form-input text-center mr-1"
                       step="1" min="0" max="80"
                       @change="setPioHour"
                       v-model="input_60ede9a29f3f7f7c8c959894">
                <span class="side">E</span>
                <input type="number" class="form-input text-center"
                       step="1" min="0" max="80"
                       @change="setPioHour"
                       v-model="input_60ede9a29f3f7f7c8c959895">
              </div>
              <div class="items">
                <span class="side"></span>
                <input type="text" class="form-input text-center"
                       disabled placeholder="00:00"
                       v-model="input_60ede9a29f3f7f7c8c959896">
<!--                <input type="text" class="form-input text-center"-->
<!--                       disabled placeholder="00:00"-->
<!--                       v-model="input_60ede9a29f3f7f7c8c959897">-->
              </div>
            </div>
          </div>
          <button class="btn btn-sm btn-gray-outline mb-1"
                  @click="openOphthalmologyModal('autoPio')">Exibir Histórico</button>
        </div>
      </div>
      <div class="column col-6 col-md-12 col-sm-12 form-group">
        <div class="group-items avsc">
          <div class="columns">
            <div class="column col-6 form-group">
              <label class="form-label text-bold">AVsc</label>
              <div class="items mb-1">
                <span class="side">D</span>
                <select class="form-select"
                        v-model="input_60ede9a29f3f7f7c8c959898">
                  <option v-for="(item, i) in avs" :value="item" :key="i">{{ item }}</option>
                </select>
              </div>
              <div class="items">
                <span class="side">E</span>
                <select class="form-select"
                        v-model="input_60ede9a29f3f7f7c8c959899">
                  <option v-for="(item, i) in avs" :value="item" :key="i">{{ item }}</option>
                </select>
              </div>
            </div>
            <div class="column col-6 form-group">
              <label class="form-label text-bold">AVcc</label>
              <div class="items mb-1">
                <span class="side">D</span>
                <select class="form-select"
                        v-model="input_60ede9a29f3f7f7c8c95989a">
                  <option v-for="(item, i) in avs" :value="item" :key="i">{{ item }}</option>
                </select>
              </div>
              <div class="items">
                <span class="side">E</span>
                <select class="form-select"
                        v-model="input_60ede9a29f3f7f7c8c95989b">
                  <option v-for="(item, i) in avs" :value="item" :key="i">{{ item }}</option>
                </select>
              </div>
            </div>
          </div>
          <button class="btn btn-sm btn-gray-outline mb-1"
                  @click="openOphthalmologyModal('avsc')">Exibir Histórico</button>
        </div>
      </div>
      <div class="column col-12 form-group">
        <div class="group-items refraction">
          <div class="columns">
            <div class="column col-6 col-md-12 col-sm-12 form-group">
              <label class="form-label text-bold">Refração Subjetiva</label>
              <div class="items mb-1">
                <span class="side">D</span>
                <div>
                  <input type="number" class="form-input text-center mr-1"
                         :class="input_60ede9a29f3f7f7c8c95989c
                         && input_60ede9a29f3f7f7c8c95989c.toString().substr(0, 1) === '-'
                         ? 'negative-value' : ''"
                         step="0.25" min="-35" max="30"
                         v-model="input_60ede9a29f3f7f7c8c95989c"
                         placeholder="Esf.">
                </div>
                <div>
                  <input type="number" class="form-input text-center mr-1"
                         :class="input_60ede9a29f3f7f7c8c95989e
                         && input_60ede9a29f3f7f7c8c95989e.toString().substr(0, 1) === '-'
                         ? 'negative-value' : ''"
                         step="0.25" min="-30" max="0"
                         @blur="$v.input_60ede9a29f3f7f7c8c9598a0.$touch()"
                         v-model="input_60ede9a29f3f7f7c8c95989e"
                         placeholder="Cil.">
                </div>
                <div :class="{'has-error': $v.input_60ede9a29f3f7f7c8c9598a0.$error}">
                  <input type="number" class="form-input text-center"
                         step="1" min="0" max="180"
                         v-model="input_60ede9a29f3f7f7c8c9598a0"
                         placeholder="Eixo">
                </div>
                <div>
                  <select class="form-select text-center"
                          v-model="input_60ede9a29f3f7f7c8c9598a8">
                    <option value="null">AVcc</option>
                    <option v-for="(item, i) in avs" :value="item" :key="i">{{ item }}</option>
                  </select>
                </div>
                <div>
                  <input type="number" class="form-input text-center"
                         step="0.25" min="0.50" max="6" placeholder="Adição"
                         v-model="input_60ede9a29f3f7f7c8c9598aa">
                </div>
              </div>
              <div class="items mb-1">
                <span class="side">E</span>
                <div>
                  <input type="number" class="form-input text-center mr-1"
                         :class="input_60ede9a29f3f7f7c8c95989d
                         && input_60ede9a29f3f7f7c8c95989d.toString().substr(0, 1) === '-'
                         ? 'negative-value' : ''"
                         step="0.25" min="-35" max="30"
                         v-model="input_60ede9a29f3f7f7c8c95989d"
                         placeholder="Esf.">
                </div>
                <div>
                  <input type="number" class="form-input text-center mr-1"
                         :class="input_60ede9a29f3f7f7c8c95989f
                         && input_60ede9a29f3f7f7c8c95989f.toString().substr(0, 1) === '-'
                         ? 'negative-value' : ''"
                         step="0.25" min="-30" max="0"
                         @blur="$v.input_60ede9a29f3f7f7c8c9598a1.$touch()"
                         v-model="input_60ede9a29f3f7f7c8c95989f"
                         placeholder="Cil.">
                </div>
                <div :class="{'has-error': $v.input_60ede9a29f3f7f7c8c9598a1.$error}">
                  <input type="number" class="form-input text-center"
                         step="1" min="0" max="180"
                         v-model="input_60ede9a29f3f7f7c8c9598a1"
                         placeholder="Eixo">
                </div>
                <div>
                  <select class="form-select text-center"
                          v-model="input_60ede9a29f3f7f7c8c9598a9">
                    <option value="null">AVcc</option>
                    <option v-for="(item, i) in avs" :value="item" :key="i">{{ item }}</option>
                  </select>
                </div>
                <div>
                  <select class="form-select text-center"
                          v-model="input_60ede9a29f3f7f7c8a9598aa">
                    <option value="null">Qtde.</option>
                    <option v-for="(item, i) in qty" :value="item" :key="i">{{ item }}</option>
                  </select>
                </div>
              </div>
              <div class="refraction-actions">
                <dx-dropdown
                  class="btn btn-gray btn-print btn-sm btn-icon btn-icon-right mr-1"
                  :items="printItems"
                  label="text"
                  @select="setPrint"
                  direction="left">Imprimir Receita
                  <fa-icon :icon="['fal', 'chevron-down']" />
                </dx-dropdown>
                <button class="btn btn-sm btn-gray-outline"
                        @click="openOphthalmologyModal('refraction')">
                  Exibir Histórico
                </button>
              </div>
            </div>
            <div class="column col-6 col-md-12 col-sm-12 form-group">
              <label class="form-label text-bold">Refração Objetiva</label>
              <div class="items mb-1">
                <span class="side">D</span>
                <div>
                  <input type="number" class="form-input text-center mr-1"
                         :class="input_60ede9a29f3f7f7c8c9598a2
                         && input_60ede9a29f3f7f7c8c9598a2.toString().substr(0, 1) === '-'
                         ? 'negative-value' : ''"
                         step="0.25" min="-35" max="30"
                         v-model="input_60ede9a29f3f7f7c8c9598a2"
                         placeholder="Esf.">
                </div>
                <div>
                  <input type="number" class="form-input text-center mr-1"
                         :class="input_60ede9a29f3f7f7c8c9598a4
                         && input_60ede9a29f3f7f7c8c9598a4.toString().substr(0, 1) === '-'
                         ? 'negative-value' : ''"
                         step="0.25" min="-30" max="0"
                         @blur="$v.input_60ede9a29f3f7f7c8c9598a6.$touch()"
                         v-model="input_60ede9a29f3f7f7c8c9598a4"
                         placeholder="Cil.">
                </div>
                <div :class="{'has-error': $v.input_60ede9a29f3f7f7c8c9598a6.$error}">
                  <input type="number" class="form-input text-center"
                         step="1" min="0" max="180"
                         v-model="input_60ede9a29f3f7f7c8c9598a6"
                         placeholder="Eixo">
                </div>
                <div>
                  <select class="form-select text-center"
                          v-model="input_60ede9a29f3f7f7c8d9598a8">
                    <option value="null">AVcc</option>
                    <option v-for="(item, i) in avs" :value="item" :key="i">{{ item }}</option>
                  </select>
                </div>
                <div>
                  <input type="number" class="form-input text-center"
                         step="0.25" min="0.50" max="6" placeholder="Adição"
                         v-model="input_60ede9a29f3f7f7c8c9598ab">
                </div>
              </div>
              <div class="items">
                <span class="side">E</span>
                <div>
                  <input type="number" class="form-input text-center mr-1"
                         :class="input_60ede9a29f3f7f7c8c9598a3
                         && input_60ede9a29f3f7f7c8c9598a3.toString().substr(0, 1) === '-'
                         ? 'negative-value' : ''"
                         step="0.25" min="-35" max="30"
                         v-model="input_60ede9a29f3f7f7c8c9598a3"
                         placeholder="Esf.">
                </div>
                <div>
                  <input type="number" class="form-input text-center mr-1"
                         :class="input_60ede9a29f3f7f7c8c9598a5
                         && input_60ede9a29f3f7f7c8c9598a5.toString().substr(0, 1) === '-'
                         ? 'negative-value' : ''"
                         step="0.25" min="-30" max="0"
                         @blur="$v.input_60ede9a29f3f7f7c8c9598a7.$touch()"
                         v-model="input_60ede9a29f3f7f7c8c9598a5"
                         placeholder="Cil.">
                </div>
                <div :class="{'has-error': $v.input_60ede9a29f3f7f7c8c9598a7.$error}">
                  <input type="number" class="form-input text-center"
                         step="1" min="0" max="180"
                         v-model="input_60ede9a29f3f7f7c8c9598a7"
                         placeholder="Eixo">
                </div>
                <div>
                  <select class="form-select text-center"
                          v-model="input_60ede9a29f3f7f7c8d9598a9">
                    <option value="null">AVcc</option>
                    <option v-for="(item, i) in avs" :value="item" :key="i">{{ item }}</option>
                  </select>
                </div>
                <div>
                  <select class="form-select text-center"
                          v-model="input_60ede9a29f3f7f7c8d9598ab">
                    <option value="null">Qtde.</option>
                    <option v-for="(item, i) in qty" :value="item" :key="i">{{ item }}</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="column col-6 form-group">
        <label class="form-label text-bold">Biomicroscopia</label>
        <div class="input-group">
          <span class="input-group-addon">OD</span>
          <input type="text" class="form-input"
                 v-model="input_60ede9a29f3f7f7c8c9598ac">
        </div>
        <div class="input-group mt-2">
          <span class="input-group-addon">OE</span>
          <input type="text" class="form-input"
                 v-model="input_60ede9a29f3f7f7c8c9598ad">
        </div>
      </div>
      <div class="column col-6 form-group">
        <label class="form-label text-bold">Fundo de Olho</label>
        <div class="input-group">
          <span class="input-group-addon">OD</span>
          <input type="text" class="form-input"
                 v-model="input_60ede9a29f3f7f7c8c9598ae">
        </div>
        <div class="input-group mt-2">
          <span class="input-group-addon">OE</span>
          <input type="text" class="form-input"
                 v-model="input_60ede9a29f3f7f7c8c9598af">
        </div>
      </div>
      <div class="column col-12 form-group">
        <label class="form-label text-bold">Exames complementares</label>
        <input type="text" class="form-input"
               v-model="input_60ede9a29f3f7f7c8c9598b0">
      </div>
      <div class="column col-12 form-group">
        <label class="form-label text-bold">Diagnóstico</label>
        <input type="text" class="form-input"
               v-model="input_60ede9a29f3f7f7c8c9598b1">
      </div>
      <div class="column col-12 form-group">
        <label class="form-label text-bold">Conduta</label>
        <input type="text" class="form-input"
               v-model="input_60ede9a29f3f7f7c8c9598b2">
      </div>
    </div>
    <dx-modal
      v-model="ophthalmologyModal.show"
      title="Histórico"
      id="ophthalmology-modal">
      <template v-if="ophthalmologyModal.origin === 'autoRefraction'">
        <st-tabs ref="auto-tabs">
          <st-tab id="tab-auto-refraction" name="Auto Refração">
            <table class="table table-striped table-hover">
              <thead>
              <tr>
                <th style="width: 100px;">Data</th>
                <th>Olho</th>
                <th class="text-center">Esférico</th>
                <th class="text-center">Cilíndrico</th>
                <th class="text-center">Eixo</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(item, i) in ophthalmology.autoRefractionItems" :key="i">
                <td>{{ item.date | date }}</td>
                <td>
                  <span>Direito</span><br>
                  <span>Esquerdo</span>
                </td>
                <td class="text-center">
                  <span>{{ item.spD | number }}</span><br>
                  <span>{{ item.spE | number }}</span>
                </td>
                <td class="text-center">
                  <span>{{ item.cyD | number }}</span><br>
                  <span>{{ item.cyE | number }}</span>
                </td>
                <td class="text-center">
                  <span>{{ item.axD || '-' }}</span><br>
                  <span>{{ item.axE || '-'  }}</span>
                </td>
              </tr>
              </tbody>
            </table>
          </st-tab>
          <st-tab id="tab-keratometry" name="Ceratometria">
            <table class="table table-striped table-hover">
              <thead>
              <tr>
                <th style="width: 100px;">Data</th>
                <th>Olho</th>
                <th class="text-center">K1</th>
                <th class="text-center">K2</th>
                <th class="text-center">Eixo</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(item, i) in ophthalmology.keratometryItems" :key="i">
                <td>{{ item.date | date }}</td>
                <td>
                  <span>Direito</span><br>
                  <span>Esquerdo</span>
                </td>
                <td class="text-center">
                  <span>{{ item.k1D | number }}</span><br>
                  <span>{{ item.k1E | number }}</span>
                </td>
                <td class="text-center">
                  <span>{{ item.k2D | number }}</span><br>
                  <span>{{ item.k2E | number }}</span>
                </td>
                <td class="text-center">
                  <span>{{ item.axD || '-' }}</span><br>
                  <span>{{ item.axE || '-'  }}</span>
                </td>
              </tr>
              </tbody>
            </table>
          </st-tab>
          <st-tab id="tab-lensometry" name="Lensometria">
            <table class="table table-striped table-hover">
              <thead>
              <tr>
                <th style="width: 100px;">Data</th>
                <th>Olho</th>
                <th class="text-center">Esférico</th>
                <th class="text-center">Cilíndrico</th>
                <th class="text-center">Eixo</th>
                <th class="text-center">Adição</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(item, i) in ophthalmology.lensometryItems" :key="i">
                <td>{{ item.date | date }}</td>
                <td>
                  <span>Direito</span><br>
                  <span>Esquerdo</span>
                </td>
                <td class="text-center">
                  <span>{{ item.spD | number }}</span><br>
                  <span>{{ item.spE | number }}</span>
                </td>
                <td class="text-center">
                  <span>{{ item.cyD | number }}</span><br>
                  <span>{{ item.cyE | number }}</span>
                </td>
                <td class="text-center">
                  <span>{{ item.axD || '-' }}</span><br>
                  <span>{{ item.axE || '-'  }}</span>
                </td>
                <td class="text-center">
                  <span>{{ item.add | number }}</span><br>
                  <span>{{ item.qtd || '-'  }}</span>
                </td>
              </tr>
              </tbody>
            </table>
          </st-tab>
        </st-tabs>
      </template>
      <template v-if="ophthalmologyModal.origin === 'autoPio'">
        <h6 class="text-primary">PIO e Auto PIO</h6>
        <table class="table table-striped table-hover">
          <thead>
          <tr>
            <th>Data</th>
            <th>Olho</th>
            <th class="text-center">Auto PIO</th>
            <th class="text-center">PIO</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(item, i) in ophthalmology.pioItems" :key="i">
            <td>{{ item.date | date }}</td>
            <td>
              <span>Direito</span><br>
              <span>Esquerdo</span>
            </td>
            <td class="text-center">
              <span>{{ item.apD || '-' }}</span><br>
              <span>{{ item.apE || '-' }}</span>
            </td>
            <td class="text-center">
              <span>{{ item.piD || '-' }}</span><br>
              <span>{{ item.piE || '-' }}</span>
            </td>
          </tr>
          </tbody>
        </table>
      </template>
      <template v-if="ophthalmologyModal.origin === 'avsc'">
        <h6 class="text-primary">AVsc e AVcc</h6>
        <table class="table table-striped table-hover">
          <thead>
          <tr>
            <th>Data</th>
            <th>Olho</th>
            <th class="text-center">AVsc</th>
            <th class="text-center">AVcc</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(item, i) in ophthalmology.avItems" :key="i">
            <td>{{ item.date | date }}</td>
            <td>
              <span>Direito</span><br>
              <span>Esquerdo</span>
            </td>
            <td class="text-center">
              <span>{{ item.scD || '-' }}</span><br>
              <span>{{ item.scE || '-' }}</span>
            </td>
            <td class="text-center">
              <span>{{ item.ccD || '-' }}</span><br>
              <span>{{ item.ccE || '-' }}</span>
            </td>
          </tr>
          </tbody>
        </table>
      </template>
      <template v-if="ophthalmologyModal.origin === 'refraction'">
        <st-tabs ref="auto-tabs">
          <st-tab id="tab-subjective" name="Refração Subjetiva">
            <table class="table table-striped table-hover">
              <thead>
              <tr>
                <th style="width: 100px;">Data</th>
                <th>Olho</th>
                <th class="text-center">Esférico</th>
                <th class="text-center">Cilíndrico</th>
                <th class="text-center">Eixo</th>
                <th class="text-center">AVcc</th>
                <th class="text-center">Adição</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(item, i) in ophthalmology.subjectiveItems" :key="i">
                <td>{{ item.date | date }}</td>
                <td>
                  <span>Direito</span><br>
                  <span>Esquerdo</span>
                </td>
                <td class="text-center">
                  <span>{{ item.spD | number }}</span><br>
                  <span>{{ item.spE | number }}</span>
                </td>
                <td class="text-center">
                  <span>{{ item.cyD | number }}</span><br>
                  <span>{{ item.cyE | number }}</span>
                </td>
                <td class="text-center">
                  <span>{{ item.axD || '-' }}</span><br>
                  <span>{{ item.axE || '-'  }}</span>
                </td>
                <td class="text-center">
                  <span>{{ item.avD || '-' }}</span><br>
                  <span>{{ item.avE || '-'  }}</span>
                </td>
                <td class="text-center">
                  <span>{{ item.add | number }}</span><br>
                  <span>{{ item.adj || '-'  }}</span>
                </td>
              </tr>
              </tbody>
            </table>
          </st-tab>
          <st-tab id="tab-objective" name="Refração Objetiva">
            <table class="table table-striped table-hover">
              <thead>
              <tr>
                <th style="width: 100px;">Data</th>
                <th>Olho</th>
                <th class="text-center">Esférico</th>
                <th class="text-center">Cilíndrico</th>
                <th class="text-center">Eixo</th>
                <th class="text-center">AVcc</th>
                <th class="text-center">Adição</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(item, i) in ophthalmology.objectiveItems" :key="i">
                <td>{{ item.date | date }}</td>
                <td>
                  <span>Direito</span><br>
                  <span>Esquerdo</span>
                </td>
                <td class="text-center">
                  <span>{{ item.spD | number }}</span><br>
                  <span>{{ item.spE | number }}</span>
                </td>
                <td class="text-center">
                  <span>{{ item.cyD | number }}</span><br>
                  <span>{{ item.cyE | number }}</span>
                </td>
                <td class="text-center">
                  <span>{{ item.axD || '-' }}</span><br>
                  <span>{{ item.axE || '-'  }}</span>
                </td>
                <td class="text-center">
                  <span>{{ item.avD || '-' }}</span><br>
                  <span>{{ item.avE || '-'  }}</span>
                </td>
                <td class="text-center">
                  <span>{{ item.add | number }}</span><br>
                  <span>{{ item.adj || '-'  }}</span>
                </td>
              </tr>
              </tbody>
            </table>
          </st-tab>
        </st-tabs>
      </template>
      <template slot="footer">
        <button class="btn" @click="ophthalmologyModal.show = false">Sair</button>
      </template>
    </dx-modal>
  </div>
</template>

<script>
import formMixin from 'src/mixins/form';
import { required } from 'vuelidate/lib/validators';
import moment from 'moment';

const inputIds = [
  '60ede9a29f3f7f7c8c95987c', // Queixa principal
  '60ede9a29f3f7f7c8c95987d', // História pregressa
  '60ede9a29f3f7f7c8c95987e', // Auto Refração - Esférico Direito
  '60ede9a29f3f7f7c8c95987f', // Auto Refração - Esférico Esquerdo
  '60ede9a29f3f7f7c8c959880', // Auto Refração - Cilíndrico Direito
  '60ede9a29f3f7f7c8c959881', // Auto Refração - Cilíndrico Esquerdo
  '60ede9a29f3f7f7c8c959882', // Auto Refração - Eixo Direito
  '60ede9a29f3f7f7c8c959883', // Auto Refração - Eixo Esquerdo
  '60ede9a29f3f7f7c8c959884', // Ceratometria - K1 Direito
  '60ede9a29f3f7f7c8c959885', // Ceratometria - K1 Esquerdo
  '60ede9a29f3f7f7c8c959886', // Ceratometria - K2 Direito
  '60ede9a29f3f7f7c8c959887', // Ceratometria - K2 Esquerdo
  '60ede9a29f3f7f7c8c959888', // Ceratometria - Eixo Direito
  '60ede9a29f3f7f7c8c959889', // Ceratometria - Eixo Esquerdo
  '60ede9a29f3f7f7c8c95988a', // Lensometria - Esférico Direito
  '60ede9a29f3f7f7c8c95988b', // Lensometria - Esférico Esquerdo
  '60ede9a29f3f7f7c8c95988c', // Lensometria - Cilíndrico Direito
  '60ede9a29f3f7f7c8c95988d', // Lensometria - Cilíndrico Esquerdo
  '60ede9a29f3f7f7c8c95988e', // Lensometria - Eixo Direito
  '60ede9a29f3f7f7c8c95988f', // Lensometria - Eixo Esquerdo
  '60ede9a29f3f7f7c8d95988a', // Lensometria - Adição
  '60ede9a29f3f7f7d8c95988b', // Lensometria - Quantidade
  '60ede9a29f3f7f7c8c959890', // Auto PIO (Pressão Intraocular) - Valor Direito
  '60ede9a29f3f7f7c8c959891', // Auto PIO (Pressão Intraocular) - Valor Esquerdo
  '60ede9a29f3f7f7c8c959892', // Auto PIO (Pressão Intraocular) - Horário Direito
  '60ede9a29f3f7f7c8c959893', // Auto PIO (Pressão Intraocular) - Horário Esquerdo
  '60ede9a29f3f7f7c8c959894', // PIO (Pressão Intraocular) - Valor Direito
  '60ede9a29f3f7f7c8c959895', // PIO (Pressão Intraocular) - Valor Esquerdo
  '60ede9a29f3f7f7c8c959896', // PIO (Pressão Intraocular) - Horário Direito
  '60ede9a29f3f7f7c8c959897', // PIO (Pressão Intraocular) - Horário Esquerdo
  '60ede9a29f3f7f7c8c959898', // AVsc (Acuidade Visual Sem Correção) - Direito
  '60ede9a29f3f7f7c8c959899', // AVsc (Acuidade Visual Sem Correção) - Esquerdo
  '60ede9a29f3f7f7c8c95989a', // AVcc (Acuidade Visual Com Correção) - Direito
  '60ede9a29f3f7f7c8c95989b', // AVcc (Acuidade Visual Com Correção) - Esquerdo
  '60ede9a29f3f7f7c8c95989c', // Refração Subjetiva - Esférico Direito
  '60ede9a29f3f7f7c8c95989d', // Refração Subjetiva - Esférico Esquerdo
  '60ede9a29f3f7f7c8c95989e', // Refração Subjetiva - Cilíndrico Direito
  '60ede9a29f3f7f7c8c95989f', // Refração Subjetiva - Cilíndrico Esquerdo
  '60ede9a29f3f7f7c8c9598a0', // Refração Subjetiva - Eixo Direito
  '60ede9a29f3f7f7c8c9598a1', // Refração Subjetiva - Eixo Esquerdo
  '60ede9a29f3f7f7c8c9598a8', // Refração Subjetiva - AVcc (Acuidade Visual Com Correção) - Direito
  '60ede9a29f3f7f7c8c9598a9', // Refração Subjetiva - AVcc (Acuidade Visual Com Correção) - Esquerdo
  '60ede9a29f3f7f7c8c9598aa', // Refração Subjetiva - Adição
  '60ede9a29f3f7f7c8a9598aa', // Refração Subjetiva - Quantidade
  '60ede9a29f3f7f7c8c9598a2', // Refração Objetiva - Esférico Direito
  '60ede9a29f3f7f7c8c9598a3', // Refração Objetiva - Esférico Esquerdo
  '60ede9a29f3f7f7c8c9598a4', // Refração Objetiva - Cilíndrico Direito
  '60ede9a29f3f7f7c8c9598a5', // Refração Objetiva - Cilíndrico Esquerdo
  '60ede9a29f3f7f7c8c9598a6', // Refração Objetiva - Eixo Direito
  '60ede9a29f3f7f7c8c9598a7', // Refração Objetiva - Eixo Esquerdo
  '60ede9a29f3f7f7c8c9598ab', // Refração Objetiva - Adição
  '60ede9a29f3f7f7c8d9598ab', // Refração Objetiva - Quantidade
  '60ede9a29f3f7f7c8d9598a8', // Refração Objetiva - AVcc (Acuidade Visual Com Correção) - Direito
  '60ede9a29f3f7f7c8d9598a9', // Refração Objetiva - AVcc (Acuidade Visual Com Correção) - Esquerdo
  '60ede9a29f3f7f7c8c9598ac', // Biomicroscopia - Direito
  '60ede9a29f3f7f7c8c9598ad', // Biomicroscopia - Esquerdo
  '60ede9a29f3f7f7c8c9598ae', // Fundo de Olho (Mapeamento de retina) - Direito
  '60ede9a29f3f7f7c8c9598af', // Fundo de Olho (Mapeamento de retina) - Esquerdo
  '60ede9a29f3f7f7c8c9598b0', // Exames complementares
  '60ede9a29f3f7f7c8c9598b1', // Diagnóstico
  '60ede9a29f3f7f7c8c9598b2', // Conduta
];

function mapPropsModels(ids = []) {
  return ids.reduce((obj, id) => {
    obj[`input_${id}`] = {
      get() {
        return this.form.inputs.find(item => item.id === id).model.value;
      },
      set(value) {
        this.$emit('change', { id, value });
      },
    };
    return obj;
  }, {});
}

export default {
  mixins: [formMixin],
  props: {
    ophthalmology: {
      type: Object,
    },
    data: {
      type: Object,
    },
    id: {
      type: String,
    },
  },
  data() {
    return {
      form: this.data,
      printItems: [
        { value: 'subjective', text: 'Refração Subjetiva' },
        { value: 'objective', text: 'Refração Objetiva' },
      ],
      avs: [
        '20/15',
        '20/20',
        '20/25',
        '20/30',
        '20/40',
        '20/50',
        '20/60',
        '20/70',
        '20/80',
        '20/100',
        '20/200',
        '20/400',
        '20/600',
        '20/800',
        'CD a 1m',
        'CD a 2m',
        'CD a 3m',
        'Vultos',
        'PL',
        'PPL',
        'SPL',
        'SM',
      ],
      qty: [
        'J1',
        'J2',
        'J3',
        'J4',
        'J5',
        'J6',
        '>J6',
      ],
      ophthalmologyModal: {
        origin: '',
        show: false,
      },
    };
  },
  computed: {
    ...mapPropsModels(inputIds),
  },
  validations() {
    const rules = {
      input_60ede9a29f3f7f7c8c95987c: { required },
      input_60ede9a29f3f7f7c8c959882: {},
      input_60ede9a29f3f7f7c8c959883: {},
      input_60ede9a29f3f7f7c8c959888: {},
      input_60ede9a29f3f7f7c8c959889: {},
      input_60ede9a29f3f7f7c8c95988e: {},
      input_60ede9a29f3f7f7c8c95988f: {},
      input_60ede9a29f3f7f7c8c9598a0: {},
      input_60ede9a29f3f7f7c8c9598a1: {},
      input_60ede9a29f3f7f7c8c9598a6: {},
      input_60ede9a29f3f7f7c8c9598a7: {},
    };

    if (this.input_60ede9a29f3f7f7c8c959880 && !this.input_60ede9a29f3f7f7c8c959882) {
      rules.input_60ede9a29f3f7f7c8c959882 = { required };
    }

    if (this.input_60ede9a29f3f7f7c8c959881 && !this.input_60ede9a29f3f7f7c8c959883) {
      rules.input_60ede9a29f3f7f7c8c959883 = { required };
    }

    if (this.input_60ede9a29f3f7f7c8c959886 && !this.input_60ede9a29f3f7f7c8c959888) {
      rules.input_60ede9a29f3f7f7c8c959888 = { required };
    }

    if (this.input_60ede9a29f3f7f7c8c959887 && !this.input_60ede9a29f3f7f7c8c959889) {
      rules.input_60ede9a29f3f7f7c8c959889 = { required };
    }

    if (this.input_60ede9a29f3f7f7c8c95988c && !this.input_60ede9a29f3f7f7c8c95988e) {
      rules.input_60ede9a29f3f7f7c8c95988e = { required };
    }

    if (this.input_60ede9a29f3f7f7c8c95988d && !this.input_60ede9a29f3f7f7c8c95988f) {
      rules.input_60ede9a29f3f7f7c8c95988f = { required };
    }

    if (this.input_60ede9a29f3f7f7c8c95989e && !this.input_60ede9a29f3f7f7c8c9598a0) {
      rules.input_60ede9a29f3f7f7c8c9598a0 = { required };
    }

    if (this.input_60ede9a29f3f7f7c8c95989f && !this.input_60ede9a29f3f7f7c8c9598a1) {
      rules.input_60ede9a29f3f7f7c8c9598a1 = { required };
    }

    if (this.input_60ede9a29f3f7f7c8c9598a4 && !this.input_60ede9a29f3f7f7c8c9598a6) {
      rules.input_60ede9a29f3f7f7c8c9598a6 = { required };
    }

    if (this.input_60ede9a29f3f7f7c8c9598a5 && !this.input_60ede9a29f3f7f7c8c9598a7) {
      rules.input_60ede9a29f3f7f7c8c9598a7 = { required };
    }

    return rules;
  },
  mounted() {
    this.touchItems();
  },
  methods: {
    setAutoPioHour() {
      if (!this.input_60ede9a29f3f7f7c8c959891 && !this.input_60ede9a29f3f7f7c8c959890) {
        this.input_60ede9a29f3f7f7c8c959893 = '';
        this.input_60ede9a29f3f7f7c8c959892 = '';
        return;
      }
      this.input_60ede9a29f3f7f7c8c959892 = moment().format('HH:mm');
      this.input_60ede9a29f3f7f7c8c959893 = moment().format('HH:mm');
    },
    setPioHour() {
      if (!this.input_60ede9a29f3f7f7c8c959894 && !this.input_60ede9a29f3f7f7c8c959895) {
        this.input_60ede9a29f3f7f7c8c959896 = '';
        this.input_60ede9a29f3f7f7c8c959897 = '';
        return;
      }
      this.input_60ede9a29f3f7f7c8c959896 = moment().format('HH:mm');
      this.input_60ede9a29f3f7f7c8c959897 = moment().format('HH:mm');
    },
    touchItems() {
      this.$v.input_60ede9a29f3f7f7c8c959882.$touch();
      this.$v.input_60ede9a29f3f7f7c8c959883.$touch();
      this.$v.input_60ede9a29f3f7f7c8c959888.$touch();
      this.$v.input_60ede9a29f3f7f7c8c959889.$touch();
      this.$v.input_60ede9a29f3f7f7c8c95988e.$touch();
      this.$v.input_60ede9a29f3f7f7c8c95988f.$touch();
      this.$v.input_60ede9a29f3f7f7c8c9598a0.$touch();
      this.$v.input_60ede9a29f3f7f7c8c9598a1.$touch();
      this.$v.input_60ede9a29f3f7f7c8c9598a6.$touch();
      this.$v.input_60ede9a29f3f7f7c8c9598a7.$touch();
    },
    setPrint({ value }) {
      const params = {
        type: value,
      };
      return this.$file
        .print(`/medical-records/${this.id}/print-ophthalmology-recipes`, params)
        .catch(this.$toast.error)
        .finally(() => {
        });
    },
    openOphthalmologyModal(origin) {
      this.ophthalmologyModal.origin = origin;
      this.ophthalmologyModal.show = true;
    },
  },
};
</script>

<style lang="scss">
  @import "./src/assets/scss/_variables.scss";
  .ophthalmology-page {
    .form-input {
      opacity: 100;
    }
    .negative-value {
      color: red;
    }
    .group-items {
      border-radius: $border-radius;
      margin-top: $layout-spacing-sm;
      padding: $layout-spacing-sm $layout-spacing;
      .form-label {
        margin-left: $font-size;
      }
      .items {
        align-items: center;
        display: flex;
        .side {
          color: $gray-color;
          min-width: $font-size;
        }
        &>div {
          flex: 1;
          margin-right: $layout-spacing-sm;
          &:last-child {
            margin-right: 0;
          }
        }
      }
      &.auto-ref {
        border: $unit-h solid $gray-color;
      }
      &.auto-pio {
        border: $unit-h solid #efcc92;
      }
      &.avsc {
        border: $unit-h solid #9292c2;
      }
      &.refraction {
        border: $unit-h solid #a8cba8;
      }
    }
    .refraction-actions {
      margin-top: $layout-spacing;
      .btn-print {
        &.dropdown > a {
          color: $light-color;
          &:focus {
            box-shadow: none;
          }
        }
        .menu {
          color: #0C7C5D;
          a {
            color: $gray-color-dark;
            &:hover {
              color: $light-color;
            }
          }
        }
      }
    }
  }
</style>
