<template>
  <div>
    <div class="card-detail" v-for="(document, d) in items"
         :key="`${document.id}${d}`">
      <div class="card-detail-data">
        <div v-html="document.data" />
        <st-button-report
          class="mt-2"
          type="pdf"
          button-class="btn-info"
          :route="printLink(document.id)"
        >
          <template v-slot:default="{ icon, option }">
            <fa-icon :icon="icon"></fa-icon>
            <span v-if="option">{{ option.text }}</span>
          </template>
        </st-button-report>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
    },
    items: {
      type: Array,
    },
  },
  data() {
    return {
      loadingPrint: false,
    };
  },
  methods: {
    printLink(id) {
      return `/medical-records/${this.id}/documents/${id}/print`;
    },
  },
};
</script>

<style lang="scss">
  @import "./src/assets/scss/_variables.scss";
</style>
