<template>
  <div class="medical-record-default-detail">
    <div class="card-detail">
      <div class="card-detail-title">Antropometria</div>
      <div class="card-detail-data">
        <div class="columns">
          <div class="column col-3 col-md-6 col-sm-12">
            Altura
            <div>{{ data.anthropometry.height ? `${data.anthropometry.height} cm` : '-' }}</div>
          </div>
          <div class="column col-3 col-md-6 col-sm-12">
            Peso
            <div>{{ data.anthropometry.weight ? `${data.anthropometry.weight} kg` : '-' }}</div>
          </div>
          <div class="column col-3 col-md-6 col-sm-12">
            Perímetro cefálico
            <div>{{ data.anthropometry.cephalicPerimeter
              ? `${data.anthropometry.cephalicPerimeter} cm` : '-' }}</div>
          </div>
          <div class="column col-3 col-md-6 col-sm-12">
            C. abdominal
            <div>{{ data.anthropometry.abdominalCircumference
              ? `${data.anthropometry.abdominalCircumference} cm` : '-' }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="card-detail">
      <div class="card-detail-title">Sinais vitais</div>
      <div class="card-detail-data">
        <div class="columns">
          <div class="column col-3 col-md-6 col-sm-12">
            Pressão arterial
            <div>{{ data.vitalSigns.bloodPressure
              ? `${data.vitalSigns.bloodPressure} mm/Hg` : '-' }}</div>
          </div>
          <div class="column col-3 col-md-6 col-sm-12">
            Frequência cardíaca
            <div>{{ data.vitalSigns.heartRate
              ? `${data.vitalSigns.heartRate} bpm` : '-' }}</div>
          </div>
          <div class="column col-3 col-md-6 col-sm-12">
            Frequência respiratória
            <div>{{ data.vitalSigns.respiratoryFrequency
              ? `${data.vitalSigns.respiratoryFrequency} mpm` : '-' }}</div>
          </div>
          <div class="column col-3 col-md-6 col-sm-12">
            Temperatura
            <div>{{ data.vitalSigns.temperature
              ? `${data.vitalSigns.temperature} ºC` : '-' }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="card-detail">
      <div class="card-detail-title">Anamnese</div>
      <div class="card-detail-data">
        <div v-for="(input, i) in data.anamnesis.inputs" :key="i">
          <div v-html="input.model.value"/>
        </div>
        <div class="mt-2" v-if="data.cids && data.cids.length > 0">
          <small>CID-10</small>
          <div v-for="(cid, c) in data.cids" :key="c">
            {{ cid.code }} - {{ cid.name }}
          </div>
        </div>
        <st-button-report
          class="mt-2"
          type="pdf"
          button-class="btn-info"
          :route="printAnamnesisLink()"
        >
          <template v-slot:default="{ icon, option }">
            <fa-icon :icon="icon"></fa-icon>
            <span v-if="option">{{ option.text }}</span>
          </template>
        </st-button-report>
      </div>
    </div>
    <div class="card-detail" v-if="data.attachments">
      <div class="card-detail-title">Anexos</div>
      <div class="card-detail-data">
        <st-entity-file-manager
          :entity-id="data.patientId"
          :origin-id="data.id"
          origin-type="medical_record"
          :readonly="true"
          :key="data.id" />
      </div>
    </div>
    <div class="card-detail" v-for="(recipe, p) in data.recipes"
         :key="`${recipe.id}${p}`">
      <div class="card-detail-title">Prescrito - {{ recipe.date | date }}</div>
      <div class="card-detail-data">
        <div class="card-drug" v-for="(drug, g) in recipe.drugs"
             :key="`${drug.id}${g}`">
          {{ drug.name }}
          <small>
            - {{ drug.quantity }} {{ unit.getName(drug.unit) }} {{ access.getName(drug.access) }}
          </small>
          <br>
          <small>{{ drug.dosage }}</small>
        </div>

        <div
          v-if="recipe.notes"
          v-html="recipe.notes"
          class="card-drug recipe-detail-notes"
        ></div>

        <st-button-report
          type="pdf"
          button-class="btn-info"
          :route="printRecipeLink(recipe.id)"
        >
          <template v-slot:default="{ icon, option }">
            <fa-icon :icon="icon"></fa-icon>
            <span v-if="option">{{ option.text }}</span>
          </template>
        </st-button-report>
      </div>
    </div>
    <div class="card-detail" v-if="filteredMedicines.length > 0">
      <div class="card-detail-title">Prescrição de medicamentos e soluções</div>
      <div class="card-detail-data">
        <div v-for="(item, i) in filteredMedicines" :key="i">
          <table class="table" v-if="item.items.length > 0">
            <tbody>
            <tr class="c-hand" v-for="(medicine, i) in item.items" :key="i">
              <td style="width: 40px">{{ i + 1 }}</td>
              <td>{{ medicine.name }}</td>
              <td style="width: 150px">
                {{ medicine.quantity }} x {{ unit.getName(medicine.unit).toLowerCase() }}
              </td>
              <td style="width: 150px">{{ access.getName(medicine.accessType) }}</td>
              <td style="width: 150px">
                {{ discretion.getName(medicine.discretion) }}
              </td>
              <td style="width: 120px">
                <span v-if="medicine.useDays === 'continuous'">
                  uso contínuo
                </span>
                <span v-else-if="medicine.useDays">
                  por {{ medicine.useDays }} dia(s)
                </span>
              </td>
              <td style="width: 100px">
                {{ medicine.frequency }} x ao dia
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="card-detail" v-if="filteredExams.length > 0">
      <div class="card-detail-title">Exames clínicos</div>
      <div class="card-detail-data">
        <div v-for="(item, i) in filteredExams" :key="i">
          <table class="table" v-if="item.items.length > 0">
            <tbody>
            <tr class="c-hand" v-for="(exam, i) in item.items" :key="i">
              <td style="width: 40px">{{ i + 1 }}</td>
              <td style="width: 120px">{{ exam.code | tuss }}</td>
              <td>{{ exam.name }}</td>
              <td style="width: 50px">x{{ exam.quantity }}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="card-detail" v-if="filteredLaboratories.length > 0">
      <div class="card-detail-title">Exames laboratoriais</div>
      <div class="card-detail-data">
        <div v-for="(item, i) in filteredLaboratories" :key="i">
          <table class="table" v-if="item.items.length > 0">
            <tbody>
            <tr class="c-hand" v-for="(exam, i) in item.items" :key="i">
              <td style="width: 40px">{{ i + 1 }}</td>
              <td style="width: 120px">{{ exam.code | tuss }}</td>
              <td>{{ exam.name }}</td>
              <td style="width: 50px">x{{ exam.quantity }}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="card-detail" v-if="filteredRecommendations.length > 0">
      <div class="card-detail-title">Recomendações</div>
      <div class="card-detail-data">
        <div class="prescription-content c-hand"
             v-for="(item, i) in filteredRecommendations" :key="i">
          <table class="table">
            <tbody>
            <tr>
              <td style="width: 40px">{{ i + 1 }}</td>
              <td style="width: 120px">{{ item.date | date }}</td>
              <td>{{ item.notes }}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="card-detail" v-for="(request, r) in data.examRequests"
         :key="`${request.id}${r}`">
      <div class="card-detail-title">
        Solicitação de exame(s)<span v-if="request.date"> {{ request.date | date }}</span>
      </div>
      <div class="card-detail-data">
        <div class="card-drug" v-for="(expense, e) in request.expenses"
             :key="`${expense.id}${e}`">
          {{ expense.code | tuss }} - {{ expense.name }} ( x{{ expense.quantity }} )
        </div>
        <button class="btn btn-info btn-icon btn-action"
                :class="{loading: loadingRequestPrint}"
                :disabled="loadingRequestPrint"
                @click="printRequest(request.id)">
          <fa-icon :icon="['fal', 'print']"></fa-icon>
        </button>
        <button class="btn btn-gray btn-icon btn-icon-left ml-2"
                :class="{loading: loadingRequestPrint}"
                :disabled="loadingRequestPrint"
                @click="printRequest(request.id, true)">
          <fa-icon :icon="['fal', 'print']"></fa-icon> SADT
        </button>
      </div>
    </div>
    <div class="card-detail" v-for="(hospitalization, r) in data.hospitalizationRequests"
         :key="`${hospitalization.id}${r}`">
      <div class="card-detail-title">
        Solicitação de internação - {{ hospitalization.date | date }}
      </div>
      <div class="card-detail-data">
        <div class="card-drug" v-for="(expense, e) in hospitalization.expenses"
             :key="`${expense.id}${e}`">
          {{ expense.code | tuss }} - {{ expense.name }} ( x{{ expense.quantity }} )
        </div>
        <button class="btn btn-info btn-icon btn-action"
                :class="{loading: loadingHospitalizationPrint}"
                :disabled="loadingHospitalizationPrint"
                @click="printHospitalizationRequest(hospitalization.id)">
          <fa-icon :icon="['fal', 'print']"></fa-icon>
        </button>
      </div>
    </div>
    <div class="card-detail" v-for="(document, d) in data.documents"
         :key="`${document.id}${d}`">
      <div class="card-detail-title">Impressos(s)</div>
      <div class="card-detail-data">
        <div v-html="document.data" />
        <st-button-report
          class="mt-2"
          type="pdf"
          button-class="btn-info"
          :route="printDocumentLink(document.id)"
        >
          <template v-slot:default="{ icon, option }">
            <fa-icon :icon="icon"></fa-icon>
            <span v-if="option">{{ option.text }}</span>
          </template>
        </st-button-report>
      </div>
    </div>
  </div>
</template>

<script>
import * as unit from 'src/data/drug-units';
import * as access from 'src/data/drug-accesses';
import * as discretion from 'src/data/discretion-types';

export default {
  props: {
    data: {
      type: Object,
    },
  },
  data() {
    return {
      access,
      discretion,
      unit,
      loadingAnamnesisPrint: false,
      loadingDocumentPrint: false,
      loadingPrescriptionPrint: false,
      loadingRecipePrint: false,
      loadingRequestPrint: false,
      loadingHospitalizationPrint: false,
    };
  },
  computed: {
    filteredMedicines() {
      const medicines = this.data.prescriptions.filter(({ type }) => type === 'medicine');

      return medicines.reduce((results, medicine) => {
        const found = results.find(({ bundleId }) => bundleId === medicine.bundleId);
        const item = {
          id: medicine.item.id,
          name: medicine.item.name,
          quantity: medicine.quantity,
          unit: medicine.unit,
          justification: medicine.justification,
          accessType: medicine.accessType,
          startDate: medicine.startDate,
          endDate: medicine.endDate,
          useDays: medicine.useDays,
          discretion: medicine.discretion,
          frequency: medicine.frequency,
        };

        if (!found) {
          const data = {
            bundleId: medicine.bundleId,
            type: medicine.type,
            date: medicine.date,
            items: [item],
          };
          results.push(data);
        } else {
          found.items.push(item);
        }

        return results;
      }, []);
    },
    filteredExams() {
      const exams = this.data.prescriptions.filter(({ type }) => type === 'exam');

      return exams.reduce((results, exam) => {
        const found = results.find(({ bundleId }) => bundleId === exam.bundleId);

        const item = {
          id: exam.item.id,
          code: exam.item.code,
          name: exam.item.name,
          quantity: exam.quantity,
        };

        if (!found) {
          const data = {
            bundleId: exam.bundleId,
            type: exam.type,
            date: exam.date,
            justification: exam.justification,
            indication: exam.indication,
            items: [item],
          };
          results.push(data);
        } else {
          found.items.push(item);
        }

        return results;
      }, []);
    },
    filteredLaboratories() {
      const exams = this.data.prescriptions.filter(({ type }) => type === 'laboratory');

      return exams.reduce((results, exam) => {
        const found = results.find(({ bundleId }) => bundleId === exam.bundleId);

        const item = {
          id: exam.item.id,
          code: exam.item.code,
          name: exam.item.name,
          quantity: exam.quantity,
        };

        if (!found) {
          const data = {
            bundleId: exam.bundleId,
            type: exam.type,
            date: exam.date,
            justification: exam.justification,
            indication: exam.indication,
            items: [item],
          };
          results.push(data);
        } else {
          found.items.push(item);
        }

        return results;
      }, []);
    },
    filteredRecommendations() {
      return this.data.prescriptions.filter(({ type }) => type === 'recommendation');
    },
  },
  methods: {
    printAnamnesisLink() {
      return `/medical-records/${this.data.id}/print`;
    },
    printDocumentLink(id) {
      return `/medical-records/${this.data.id}/documents/${id}/print`;
    },
    printRecipeLink(id) {
      return `/medical-records/${this.data.id}/recipes/${id}/print`;
    },
    printRequest(id, spsadt = false) {
      this.loadingRequestPrint = true;
      return this.$file
        .print(`/medical-records/${this.data.id}/exam-requests/${id}/print`, { spsadt })
        .catch(this.$toast.error)
        .finally(() => {
          this.loadingRequestPrint = false;
        });
    },
    printHospitalizationRequest(id) {
      this.loadingAnamnesisPrint = true;
      return this.$file
        .print(`/medical-records/${this.data.id}/hospitalization-requests/${id}/print`)
        .catch(this.$toast.error)
        .finally(() => {
          this.loadingAnamnesisPrint = false;
        });
    },
  },
};
</script>

<style lang="scss">
  @import "./src/assets/scss/_variables.scss";
  .medical-record-default-detail {
    .card-detail {
      background-color: lighten($gray-color-light, 5%);
      border: $border-color solid $border-width;
      border-radius: $border-radius;
      margin-bottom: $layout-spacing;
      padding: $layout-spacing;
      .card-detail-title {
        font-weight: bold;
        margin-bottom: $layout-spacing;
      }
    }
    .card-drug {
      background-color: $gray-color-light;
      border: $border-color solid $border-width;
      border-radius: $border-radius;
      margin-bottom: $layout-spacing;
      padding: $layout-spacing;
    }
    .recipe-detail-notes {
      white-space: pre-wrap;
    }
  }
</style>
