<template>
  <div class="card-detail-data">
    <st-entity-file-manager
      :entity-id="patientId"
      :origin-id="id"
      origin-type="medical_record"
      :readonly="true"
      :key="id" />
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
    },
    patientId: {
      type: String,
    },
  },
};
</script>

<style lang="scss">
  @import "./src/assets/scss/_variables.scss";
</style>
