<template>
  <div>
    <div class="card-detail" v-for="(hospitalization, r) in items"
         :key="`${hospitalization.id}${r}`">
      <div class="card-detail-title">{{ hospitalization.date | date }}</div>
      <div class="card-detail-data">
        <div class="card-drug" v-for="(expense, e) in hospitalization.expenses"
             :key="`${expense.id}${e}`">
          {{ expense.code | tuss }} - {{ expense.name }} ( x{{ expense.quantity }} )
        </div>
        <button class="btn btn-info btn-icon btn-action"
                :class="{loading: loadingPrint}"
                :disabled="loadingPrint"
                @click="print(hospitalization.id)">
          <fa-icon :icon="['fal', 'print']"></fa-icon>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
    },
    items: {
      type: Array,
    },
  },
  data() {
    return {
      loadingPrint: false,
    };
  },
  methods: {
    print(id) {
      this.loadingPrint = true;
      return this.$fileX
        .print(`/medical-records/${this.id}/hospitalization-requests/${id}/print`)
        .catch(this.$toast.error)
        .finally(() => {
          this.loadingPrint = false;
        });
    },
  },
};
</script>

<style lang="scss">
  @import "./src/assets/scss/_variables.scss";
</style>
