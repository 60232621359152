<template>
  <div class="form-group">
    <component
      :is="inputComponent"
      :label="label"
      :value="value.value"
      :options="options"
      :disabled="disabled"
      :readonly="readonly"
      v-bind="params"
      @input="onInput"
    />
    <template v-if="hasObservation">
      <textarea rows="2" class="form-input" :value="value.observation"
                @input="onObservationInput" placeholder="Observações adicionais" />
    </template>
  </div>
</template>

<script>
import FormInputBoolean from 'src/components/form-inputs/Boolean.vue';
import FormInputDate from 'src/components/form-inputs/Date.vue';
import FormInputEditor from 'src/components/form-inputs/Editor.vue';
import FormInputHour from 'src/components/form-inputs/Hour.vue';
import FormInputChoice from 'src/components/form-inputs/Choice.vue';
import FormInputNumber from 'src/components/form-inputs/Number.vue';
import FormInputText from 'src/components/form-inputs/Text.vue';

const TYPES = {
  boolean: 'form-input-boolean',
  choice: 'form-input-choice',
  date: 'form-input-date',
  editor: 'form-input-editor',
  hour: 'form-input-hour',
  number: 'form-input-number',
  text: 'form-input-text',
};

export default {
  props: {
    type: {
      type: String,
      default: 'text',
    },
    label: String,
    value: Object,
    params: Object,
    options: Array,
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    FormInputBoolean,
    FormInputChoice,
    FormInputDate,
    FormInputEditor,
    FormInputHour,
    FormInputNumber,
    FormInputText,
  },
  mounted() {
    this.value.observation = this.value.observation || '';
  },
  methods: {
    onInput(value) {
      this.value.value = value;
      this.$emit('input', this.value);
    },
    onObservationInput($event) {
      this.value.observation = $event.target.value;
      this.$emit('input', this.value);
    },
  },
  computed: {
    inputComponent() {
      return this.type in TYPES ? TYPES[this.type] : 'form-input-text';
    },
    hasObservation() {
      return this.params && this.params.observation;
    },
  },
};
</script>
