<template>
  <div>
    <div class="card-detail" v-if="filteredMedicines.length > 0">
      <div class="card-detail-title">Prescrição de medicamentos e soluções</div>
      <div class="card-detail-data">
        <div v-for="(item, i) in filteredMedicines" :key="i">
          <table class="table" v-if="item.items.length > 0">
            <tbody>
            <tr class="c-hand" v-for="(medicine, i) in item.items" :key="i">
              <td style="width: 40px">{{ i + 1 }}</td>
              <td>{{ medicine.name }}</td>
              <td style="width: 150px">
                {{ medicine.quantity }} x {{ unit.getName(medicine.unit).toLowerCase() }}
              </td>
              <td style="width: 150px">{{ access.getName(medicine.accessType) }}</td>
              <td style="width: 150px">
                {{ discretion.getName(medicine.discretion) }}
              </td>
              <td style="width: 120px">
                <span v-if="medicine.useDays === 'continuous'">
                  uso contínuo
                </span>
                <span v-else-if="medicine.useDays">
                  por {{ medicine.useDays }} dia(s)
                </span>
              </td>
              <td style="width: 100px">
                {{ medicine.frequency }} x ao dia
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="card-detail" v-if="filteredExams.length > 0">
      <div class="card-detail-title">Exames clínicos</div>
      <div class="card-detail-data">
        <div v-for="(item, i) in filteredExams" :key="i">
          <table class="table" v-if="item.items.length > 0">
            <tbody>
            <tr class="c-hand" v-for="(exam, i) in item.items" :key="i">
              <td style="width: 40px">{{ i + 1 }}</td>
              <td style="width: 120px">{{ exam.code | tuss }}</td>
              <td>{{ exam.name }}</td>
              <td style="width: 50px">x{{ exam.quantity }}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="card-detail" v-if="filteredLaboratories.length > 0">
      <div class="card-detail-title">Exames laboratoriais</div>
      <div class="card-detail-data">
        <div v-for="(item, i) in filteredLaboratories" :key="i">
          <table class="table" v-if="item.items.length > 0">
            <tbody>
            <tr class="c-hand" v-for="(exam, i) in item.items" :key="i">
              <td style="width: 40px">{{ i + 1 }}</td>
              <td style="width: 120px">{{ exam.code | tuss }}</td>
              <td>{{ exam.name }}</td>
              <td style="width: 50px">x{{ exam.quantity }}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="card-detail" v-if="filteredRecommendations.length > 0">
      <div class="card-detail-title">Recomendações</div>
      <div class="card-detail-data">
        <div class="prescription-content c-hand"
             v-for="(item, i) in filteredRecommendations" :key="i">
          <table class="table">
            <tbody>
            <tr>
              <td style="width: 40px">{{ i + 1 }}</td>
              <td style="width: 120px">{{ item.date | date }}</td>
              <td>{{ item.notes }}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as unit from 'src/data/drug-units';
import * as access from 'src/data/drug-accesses';
import * as discretion from 'src/data/discretion-types';

export default {
  props: {
    id: {
      type: String,
    },
    items: {
      type: Array,
    },
  },
  data() {
    return {
      access,
      discretion,
      unit,
      loadingPrint: false,
    };
  },
  computed: {
    filteredMedicines() {
      const medicines = this.items.filter(({ type }) => type === 'medicine');

      return medicines.reduce((results, medicine) => {
        const found = results.find(({ bundleId }) => bundleId === medicine.bundleId);
        const item = {
          id: medicine.item.id,
          name: medicine.item.name,
          quantity: medicine.quantity,
          unit: medicine.unit,
          justification: medicine.justification,
          accessType: medicine.accessType,
          startDate: medicine.startDate,
          endDate: medicine.endDate,
          useDays: medicine.useDays,
          discretion: medicine.discretion,
          frequency: medicine.frequency,
        };

        if (!found) {
          const data = {
            bundleId: medicine.bundleId,
            type: medicine.type,
            date: medicine.date,
            items: [item],
          };
          results.push(data);
        } else {
          found.items.push(item);
        }

        return results;
      }, []);
    },
    filteredExams() {
      const exams = this.items.filter(({ type }) => type === 'exam');

      return exams.reduce((results, exam) => {
        const found = results.find(({ bundleId }) => bundleId === exam.bundleId);

        const item = {
          id: exam.item.id,
          code: exam.item.code,
          name: exam.item.name,
          quantity: exam.quantity,
        };

        if (!found) {
          const data = {
            bundleId: exam.bundleId,
            type: exam.type,
            date: exam.date,
            justification: exam.justification,
            indication: exam.indication,
            items: [item],
          };
          results.push(data);
        } else {
          found.items.push(item);
        }

        return results;
      }, []);
    },
    filteredLaboratories() {
      const exams = this.items.filter(({ type }) => type === 'laboratory');

      return exams.reduce((results, exam) => {
        const found = results.find(({ bundleId }) => bundleId === exam.bundleId);

        const item = {
          id: exam.item.id,
          code: exam.item.code,
          name: exam.item.name,
          quantity: exam.quantity,
        };

        if (!found) {
          const data = {
            bundleId: exam.bundleId,
            type: exam.type,
            date: exam.date,
            justification: exam.justification,
            indication: exam.indication,
            items: [item],
          };
          results.push(data);
        } else {
          found.items.push(item);
        }

        return results;
      }, []);
    },
    filteredRecommendations() {
      return this.items.filter(({ type }) => type === 'recommendation');
    },
  },
};
</script>

<style lang="scss">
  @import "./src/assets/scss/_variables.scss";
</style>
