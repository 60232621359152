<template>
  <div>
    <dx-modal id="modal-patient-view"
              size="md" :title="data.name || 'Paciente'"
              :value="show" @input="close">
      <div class="loading loading-lg" v-if="loading" />
      <div class="patient-group" v-else>
        <div class="tile">
          <div class="tile-icon">
            <figure class="avatar avatar-lg mr-2">
              <img :src="image" :alt="data.name">
            </figure>
          </div>
          <div class="tile-content">
            <h6 class="tile-title mb-0">{{ data.name }}</h6>
            <div class="text-small">
              <span :class="`${data.gender}-color`">{{ data.birthDate | dateOld }}</span>
              <span class="text-gray"> - </span>
              <span :class="`${data.gender}-color`">{{ gender }}</span>
            </div>

            <div class="columns mt-1">
              <div class="column icon-text">
                <fa-icon :icon="['fal', 'calendar-star']"></fa-icon>
                <span
                  class="tooltip"
                  data-tooltip="Data de nascimento"
                >{{ data.birthDate | date }}</span>
              </div>
              <div class="column icon-text" v-if="data.identity && data.identity.value">
                <fa-icon :icon="['fal', 'id-card']"></fa-icon>
                <span
                  class="tooltip"
                  data-tooltip="CPF"
                >{{ data.identity.value | cpf }}</span>
              </div>
            </div>

            <div class="mt-2"><small>Contato</small></div>
            <div class="columns">
              <div class="column">{{ phones }}</div>
              <div class="column" v-if="data.email">{{ data.email }}</div>
            </div>

            <template>
              <div class="mt-2"><small>Convênio</small></div>
              <div class="columns">
                <div class="column">
                  <span>{{ data.patient.insurance.name }}</span>
                </div>
                <div class="column">
                  <span
                    v-if="data.patient.insurance.validity"
                    class="tooltip"
                    data-tooltip="Validade"
                  >{{ data.patient.insurance.validity | date }}</span>
                </div>
              </div>
            </template>

            <template v-if="data.patient.motherName || data.patient.fatherName">
              <div class="mt-2"><small>Filiação</small></div>
              <div class="columns">
                <div class="column">
                  <span class="tooltip" data-tooltip="Mãe">{{ data.patient.motherName }}</span>
                </div>
                <div class="column">
                  <span class="tooltip" data-tooltip="Pai">{{ data.patient.fatherName }}</span>
                </div>
              </div>
            </template>

            <div class="mt-2"><small>Endereço</small></div>
            <div>{{ data.address | address('full') }}</div>

            <div class="divider"></div>
            <button class="btn btn-info btn-icon btn-icon-left" @click="openGrowthChart">
              <fa-icon :icon="['fal', 'chart-line']"></fa-icon>
              Curvas de crescimento
            </button>
          </div>
        </div>
      </div>
      <template slot="footer">
        <router-link
          :to="`/patients/${data.id}/edit`"
          class="btn btn-info-outline float-left mr-1"
        >Cadastro completo</router-link>
        <button class="btn btn-info-outline float-left" @click="modalAlertShow = true">
          Adicionar aviso
        </button>
        <button class="btn btn-gray" @click="close">Fechar</button>
      </template>
    </dx-modal>
    <modal-growth
      v-if="growthCurvesShow"
      :patient-id="patientId"
      :show="growthCurvesShow"
      @close="growthCurvesShow = false"
    />
    <modal-alert
      v-if="modalAlertShow"
      :patient-id="data.id"
      :patient-name="data.name"
      :show="modalAlertShow"
      @close="modalAlertShow = false"
    />
  </div>
</template>

<script>
import { mergeFrom } from '@/helpers/object';
import phoneFilter from '@/filters/phone';
import ModalGrowth from './GrowthCurves.vue';
import ModalAlert from './Alert.vue';

export default {
  props: {
    show: {
      type: Boolean,
    },
    patientId: {
      type: String,
    },
  },
  components: {
    ModalGrowth,
    ModalAlert,
  },
  data() {
    return {
      loading: false,
      growthCurvesShow: false,
      modalAlertShow: false,
      data: this.blankForm(),
    };
  },
  mounted() {
    this.load();
  },
  computed: {
    hasData() {
      return !!this.data.name;
    },
    phones() {
      if (!this.hasData) return '';
      return [this.data.cellphone, this.data.phone]
        .filter(v => !!v)
        .map(v => phoneFilter(v))
        .join(' / ');
    },
    gender() {
      if (!this.hasData) return '';
      return this.data.gender === 'male' ? 'Masculino' : 'Feminino';
    },
    image() {
      if (!this.hasData) return null;
      return this.data.imageUrl
        ? `${this.data.imageUrl}?w=200&h=200`
        : `//static.stenci.pro/images/no-image-${this.data.gender}.png`;
    },
  },
  methods: {
    load() {
      this.loading = true;
      return this.$httpX
        .get(`/patients/${this.patientId}`)
        .then(({ data }) => {
          this.data = mergeFrom(this.blankForm(), data);
          if (this.data.addresses.length > 0) {
            [this.data.address] = this.data.addresses;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    close() {
      this.$emit('close');
    },
    openGrowthChart() {
      this.growthCurvesShow = true;
    },
    blankForm() {
      return {
        id: '',
        identityId: '',
        type: '',
        name: '',
        birthDate: '',
        gender: '',
        identity: {
          type: '',
          value: '',
        },
        patient: {
          active: true,
          password: '',
          passwordConfirmation: '',
          profiles: [],
          cns: '',
          insurance: {
            name: '',
            planId: '',
            record: '',
            validity: '',
            plan: { name: '' },
          },
          motherName: '',
          fatherName: '',
        },
        email: '',
        imageUrl: '',
        phone: '',
        cellphone: '',
        address: {
          id: '',
          type: '',
          postalCode: '',
          addressLine1: '',
          addressLine2: '',
          neighborhood: '',
          city: '',
          state: '',
        },
        addresses: [],
        site: '',
        notes: '',
      };
    },
  },
};
</script>

<style lang="scss">
@import "./src/assets/scss/_variables.scss";
#modal-patient-view {
  .patient-group {
    background-color: $gray-color-ultra-light;
    border: $border-width solid $border-color;
    border-radius: $border-radius;
    padding: $layout-spacing-lg;
    .icon-text {
      display: flex;
      align-items: center;
      svg {
        color: $gray-color;
        margin-right: $layout-spacing;
      }
    }
    .divider {
      border-top-color: $gray-color-light;
      margin: $layout-spacing-lg 0;
    }
  }
}
</style>
