<template>
  <dx-modal id="modal-patient-view"
            size="lg" title="Alergia(s) do paciente"
            :value="show" @input="close">
    <div class="loading loading-lg" v-if="loading" />
    <div class="allergy-card" v-else>
      <div class="columns form-group">
        <div class="column col-12" v-if="!hasSubstances">
          <div class="text-bold">Situação</div>
          <label class="form-radio form-inline">
            <input type="radio" name="allergy-type" value="unknown"
                   v-model="params.type" :disabled="hasSubstances">
            <i class="form-icon"/>Desconhece
          </label>
          <label class="form-radio form-inline">
            <input type="radio" name="allergy-type" value="deny"
                   v-model="params.type" :disabled="hasSubstances">
            <i class="form-icon"/>Nega
          </label>
          <label class="form-radio form-inline">
            <input type="radio" name="allergy-type" value="allergic"
                   v-model="params.type" :disabled="hasSubstances">
            <i class="form-icon"/>Alérgico
          </label>
        </div>
      </div>
      <template v-if="params.type === 'allergic'">
        <div class="columns form-group">
          <div class="column col-12">
            <div class="text-bold">Substância</div>
            <label class="form-radio form-inline">
              <input type="radio" name="allergy-substance" value="food"
                     v-model="params.substanceType">
              <i class="form-icon"/>Alimento
            </label>
            <label class="form-radio form-inline">
              <input type="radio" name="allergy-substance" value="medicine"
                     v-model="params.substanceType">
              <i class="form-icon"/>Medicamento
            </label>
            <label class="form-radio form-inline">
              <input type="radio" name="allergy-substance" value="others"
                     v-model="params.substanceType">
              <i class="form-icon"/>Outros
            </label>
          </div>
          <div class="column form-group"
               :class="{'has-error': $v.params.substance.name.$error}">
            <label class="form-label">Descrição</label>
            <dx-autocomplete
              id="allergy-name"
              ref="itemAutocomplete"
              :disabled="!params.substanceType"
              v-model="params.substance"
              :source="findItems"
              :custom-label="setName"
              @select="setItem"
              @blur="onItemName"
              :debounce="800"
              placeholder="Informe o nome...">
              <template v-slot="{ item }">
                <strong class="text-primary">
                  <fa-icon :icon="['fal', 'capsules']"></fa-icon>
                  {{ item.name }}
                </strong>
                <small v-if="item.caption">{{ item.caption }}</small>
              </template>
            </dx-autocomplete>
          </div>
          <div class="column col-4 form-group"
               :class="{'has-error': $v.params.severity.$error}">
            <label for="allergy-severity" class="form-label">Severidade</label>
            <select id="allergy-severity" class="form-select"
                    v-model="params.severity"
                    :disabled="!params.substanceType">
              <option value="">[Selecione]</option>
              <option value="severe">Grave</option>
              <option value="moderate">Moderada</option>
              <option value="light">Leve</option>
              <option value="unknown">Desconhecida</option>
            </select>
          </div>
          <div class="column col-auto form-group mb-2"
               style="display: flex; align-items: flex-end">
            <button class="btn btn-icon btn-icon-left btn-primary"
                    :class="{loading: saving}"
                    :disabled="!params.substanceType || saving"
                    @click="save">
              <fa-icon :icon="['fal', 'save']"/>Salvar
            </button>
          </div>
        </div>
        <template v-if="hasSubstances">
          <div class="divider" />
          <table class="table table-hover">
            <thead>
            <tr>
              <th>Substância</th>
              <th>Descrição</th>
              <th>Severidade</th>
              <th>Adicionado por</th>
              <th class="text-center">Data</th>
              <th></th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(item, i) in substances" :key="i">
              <td>{{ substanceTypes.getName(item.type) }}</td>
              <td>{{ item.substance.name }}</td>
              <td>{{ severityTypes.getName(item.severity) }}</td>
              <td>{{ item.user.name }}</td>
              <td class="text-center">{{ item.date | date }}</td>
              <td class="text-right">
                <button class="btn btn-sm btn-action btn-icon btn-error"
                        :disabled="deleting"
                        @click="remove(item, i)">
                  <fa-icon :icon="['fal', 'times']"></fa-icon>
                </button>
              </td>
            </tr>
            </tbody>
          </table>
        </template>
      </template>
    </div>
    <template slot="footer">
      <button class="btn" @click="close">Sair</button>
    </template>
  </dx-modal>
</template>

<script>
import { mapState } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import moment from 'moment';
import * as severityTypes from '../../../data/severity-types';
import * as substanceTypes from '../../../data/substance-types';

export default {
  props: {
    show: {
      type: Boolean,
    },
    id: {
      type: String,
    },
  },
  data() {
    return {
      severityTypes,
      substanceTypes,
      loading: false,
      saving: false,
      deleting: false,
      params: {
        type: '',
        substanceType: '',
        severity: '',
        substance: null,
      },
      substances: [],
    };
  },
  computed: {
    ...mapState({
      user: state => state.auth.user,
    }),
    hasSubstances() {
      return this.substances && this.substances.length > 0;
    },
  },
  watch: {
    'params.type': function changeType() {
      this.clean(true);
    },
    'params.substanceType': function changeSubstance() {
      this.clean();
    },
  },
  validations() {
    return {
      params: {
        substance: {
          name: { required },
        },
        severity: { required },
      },
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    load() {
      this.loading = true;

      return this.$http
        .get(`/entities/${this.id}/allergies`)
        .then(({ data }) => {
          this.params.type = data.type;
          this.substances = data.substances;
        })
        .catch(this.$toast.error)
        .then(() => {
          this.loading = false;
        });
    },
    save() {
      this.$v.params.$touch();
      if (this.$v.params.$error) {
        return null;
      }

      this.saving = true;

      const data = {
        date: moment().format('YYYY-MM-DD[T]HH:mm'),
        type: this.params.type,
        substanceType: this.params.substanceType,
        severity: this.params.severity,
        substance: this.params.substance,
      };

      return this.$http.post(`/entities/${this.id}/allergies`, data)
        .then(({ data: result }) => {
          this.substances = result.substances;
          this.clean(true);
        })
        .catch(() => {
          this.$toast
            .show('Ocorreu um problema ao salvar. Tente novamente!', { type: 'error' });
        })
        .finally(() => {
          this.saving = false;
        });
    },
    remove(item, i) {
      this.deleting = true;
      this.$http
        .delete(`/entities/${this.id}/allergies/${item.id}`)
        .then(() => {
          this.substances.splice(i, 1);
        })
        .catch(() => {
          this.$toast.show('Não foi possível excluir', { type: 'error' });
        })
        .then(() => {
          this.deleting = false;
        });
    },
    findItems(search) {
      if (this.params.substanceType !== 'medicine') {
        return [];
      }
      return this.$http.get(`/medicines?search=${search}`)
        .then(({ data }) => {
          if (data.items && data.items.length === 0) {
            return [{ id: null, name: search }];
          }
          return data.items;
        });
    },
    onItemName(text) {
      if (!this.params.substance || !this.params.substance.id) {
        this.params.substance = {
          id: '',
          name: text,
        };
      }
    },
    setName(item) {
      return `${item.name} ${item.display ? item.display : ''}`;
    },
    setItem(data) {
      this.params.substance = {
        id: data.id,
        name: `${data.name} ${data.display ? data.display : ''}`,
      };
    },
    clean(substanceType) {
      if (substanceType) {
        this.params.substanceType = '';
      }
      this.params.severity = '';
      this.params.substance = null;
      this.$v.params.$reset();
    },
    close() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss">
  @import "../../../assets/scss/variables";
  .allergy-card {
    background-color: $gray-color-ultra-light;
    border: $border-color solid $border-width;
    border-radius: $border-radius;
    margin: $layout-spacing-sm 0;
    padding: $layout-spacing;
    .table {
      td {
        background-color: $light-color;
      }
    }
    .divider {
      border-top: $gray-color-light solid $border-width;
    }
  }
</style>
