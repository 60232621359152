<template>
  <div class="card-document-page">
    <div class="card-document-header">
      <div class="columns">
        <div class="column col-2 col-md-4 col-sm-12 centered"
             :class="{'has-error': $v.date.$error}">
          <dx-input-date class="text-center"
                         v-model="date"
                         @change="setDate"
                         @blur="$v.date.$touch()"
          />
        </div>
        <div class="column hide-sm" />
        <div class="column col-auto col-md-auto col-sm-12">
          <div class="input-group" :class="{loading: loadingModel}">
            <select class="form-select" v-model="modelId"
                    :disabled="loadingModel" @change="getModel">
              <option value="">Meus modelos de impressos</option>
              <option v-for="(item, i) in models" :value="item.id" :key="i">
                {{ item.name }}
              </option>
            </select>
            <button class="btn btn-neutral input-group-btn"
                    @click="openEditModel">
              Gerenciar
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="card-document-body">
      <st-editor id="document-editor"
                 v-model="document"></st-editor>
    </div>
    <div class="card-document-footer">
      <div class="d-flex centered">
        <st-button-report
          type="pdf"
          button-class="btn-gray"
          :disabled="pending"
          :route="printLink()"
        ></st-button-report>
        <button class="btn btn-icon-left btn-error ml-2"
                :disabled="pending"
                @click="remove">
          <fa-icon :icon="['fal', 'trash']"/>
          Excluir
        </button>
      </div>
    </div>
    <dx-modal title="Salvar modelo" v-model="modal.show" v-if="modal.show"
              id="modal-place" size="sm">
      <div class="columns">
        <div class="column col-12 form-group">
          <label for="name" class="form-label">Nome</label>
          <input type="text" id="name" name="name" ref="name"
                 class="form-input" v-model="modal.form.name"
                 placeholder="Defina um nome do impresso" autocomplete="nope">
        </div>
        <div class="column col-12 form-group">
          <label for="size" class="form-label">Formato para impressão</label>
          <select id="size" name="size" class="form-select"
                  v-model="modal.form.size">
            <option value="A4">A4</option>
            <option value="A5">A5</option>
          </select>
        </div>
      </div>
      <template slot="footer">
        <button class="btn btn-primary mr-1" :class="{loading: modal.saving}"
                :disabled="modal.saving"
                @click="saveModel">Salvar</button>
        <button class="btn" @click="modal.show = false">Sair</button>
      </template>
    </dx-modal>
    <modal-edit
      v-if="editModels.show"
      :show="editModels.show"
      @close="closeEditModel"
    />
  </div>
</template>

<script>
import moment from 'moment';
import { mapState } from 'vuex';
import Phone from 'src/filters/phone';
import Cpf from 'src/filters/cpf';
import DateOld from 'src/filters/date-old';
import ModalEdit from '../../../../template/components/document/modals/Main.vue';
import { date } from '../../../../../data/validators';

export default {
  components: {
    ModalEdit,
  },
  props: {
    patient: {
      name: {
        type: String,
      },
      birthDate: {
        type: Date,
      },
      gender: {
        type: String,
      },
      phone: {
        type: String,
      },
      cellphone: {
        type: String,
      },
      identity: {
        type: {
          type: String,
        },
        value: {
          type: String,
        },
      },
      addresses: {
        type: Array,
      },
    },
    medicalRecordId: {
      type: String,
    },
    data: {
      id: {
        type: String,
      },
      data: {
        type: String,
      },
    },
  },
  data() {
    return {
      models: [],
      modelId: '',
      document: '',
      date: moment().format('YYYY-MM-DD'),
      pending: false,
      printing: false,
      loadingModel: false,
      modal: {
        show: false,
        saving: false,
        form: this.blankModelForm(),
      },
      editModels: {
        show: false,
      },
      debounceTimeout: null,
    };
  },
  watch: {
    document: {
      handler() {
        this.pending = true;
        clearTimeout(this.debounceTimeout);
        this.debounceTimeout = setTimeout(() => {
          this.save();
        }, 1000);
      },
      deep: true,
    },
  },
  mounted() {
    this.document = this.data.data;
    this.loadModels();
  },
  computed: {
    ...mapState({
      user: state => state.auth.user,
    }),
  },
  validations() {
    return {
      date: { date },
    };
  },
  methods: {
    setDate() {
      if (this.date && (this.date.length < 10 || !moment(this.date).isValid())) {
        this.date = '';
      }
    },
    loadModels() {
      const params = {
        limit: 0,
        professionalId: this.user.id,
      };
      this.$httpX.get('/document-templates', { params })
        .then(({ data }) => {
          this.models = data.items;
        })
        .catch(this.$toast.error);
    },
    getModel() {
      if (!this.modelId) {
        return;
      }
      this.loadingModel = true;
      this.$httpX.get(`/document-templates/${this.modelId}`)
        .then(({ data }) => {
          if (data && data.data) {
            const address = this.patient.addresses
            && this.patient.addresses.length > 0
              ? this.patient.addresses[0] : null;

            this.document = data.data
              .replace(/\[date]/g, moment().format('DD/MM/YYYY'))
              .replace(/\[hour]/g, moment().format('HH:mm'))
              .replace(/\[cid]/g, '')
              .replace(/\[patient_name]/g, this.patient.name)
              .replace(/\[patient_gender]/g,
                this.patient.gender === 'male'
                  ? 'Masculino'
                  : 'feminino')
              .replace(/\[patient_birth_date]/g, moment(this.patient.birthDate)
                .format('DD/MM/YYYY'))
              .replace(/\[patient_age]/g,
                DateOld(this.patient.birthDate, true))
              .replace(/\[patient_cpf]/g,
                this.patient.identity && this.patient.identity.value
                  ? Cpf(this.patient.identity.value)
                  : null)
              .replace(/\[patient_phone]/g, Phone(this.patient.cellphone))
              .replace(/\[patient_address]/g, this.formatAddress(address));
            this.modelId = '';
          }
        })
        .catch(this.$toast.error)
        .then(() => {
          this.loadingModel = false;
        });
    },
    openEditModel() {
      this.editModels.show = true;
    },
    closeEditModel() {
      this.loadModels();
      this.editModels.show = false;
    },
    formatAddress(item) {
      if (item) {
        return `${
          item.addressLine1
        } ${
          item.addressLine2
        }, ${
          item.neighborhood
        } - ${
          item.city
        }-${
          item.state
        }`;
      }
      return '';
    },
    saveModel() {
      this.modal.saving = true;
      const data = {
        id: this.modelId,
        professionals: [
          { id: this.user.id, name: this.user.name },
        ],
        active: true,
        data: this.document,
      };

      if (this.modal.form.name) {
        data.name = this.modal.form.name;
      }

      if (this.modal.form.size) {
        data.size = this.modal.form.size;
      }

      const request = !data.id
        ? this.$httpX.post('/document-templates', data)
        : this.$httpX.put(`/document-templates/${data.id}`, data);

      // eslint-disable-next-line consistent-return
      return request
        .then(({ data: result }) => {
          const found = this.models.find(model => model.id === result.id);
          if (!found) {
            this.models.push({
              id: result.id,
              name: result.name,
            });
          } else {
            found.name = result.name;
            found.size = result.size;
          }
          this.modelId = result.id;
          this.$toast.show('Salvo com sucesso!');
          this.modal.show = false;
        })
        .catch(this.$toast.error)
        .then(() => {
          this.modal.saving = false;
        });
    },
    save() {
      const data = {
        id: this.data.id,
        data: this.document,
      };
      const request = !this.data.id
        ? this.$httpX
          .post(`/medical-records/${this.medicalRecordId}/documents`, data)
        : this.$httpX
          .put(`/medical-records/${this.medicalRecordId}/documents/${this.data.id}`, data);

      return request
        .then(({ data: result }) => {
          this.data.id = result.id;
        })
        .catch(() => {
          this.$toast.error('Ocorreu um erro ao salvar este registro. Tente novamente!');
        })
        .finally(() => {
          this.pending = false;
        });
    },
    remove() {
      this.$dialog.show('', {
        html:
          '<div class="text-center">'
          + '<h5 class="text-center">Atenção!</h5>'
          + '<div>Deseja realmente excluir este impresso?</div>'
          + '</div>',
        buttons: [
          {
            label: 'Não',
            classes: '',
          }, {
            label: 'Sim',
            classes: 'btn-primary btn-error ml-2',
            click: (close) => {
              this.$emit('remove');
              close();
            },
          },
        ],
      });
    },
    printLink() {
      let url = `/medical-records/${this.medicalRecordId}/documents/${this.data.id}/print`;

      if (this.date) {
        url += `?date=${this.date}`;
      }

      return url;
    },
    blankModelForm() {
      return {
        name: '',
        size: 'A4',
      };
    },
  },
};
</script>

<style lang="scss">
  @import "../../../../../assets/scss/variables";
  .card-document-page {
    background-color: lighten($gray-color, 24%);
    border: $border-width solid $border-color;
    border-radius: $border-radius;
    margin-bottom: $layout-spacing-lg;
    .card-document-header {
      background-color: lighten($gray-color, 15%);
      padding: $layout-spacing;
    }
    .card-document-body {
      padding-top: $layout-spacing-lg;
    }
    .card-document-footer {
      padding: $layout-spacing-lg;
      text-align: center;
      button {
        min-width: 7rem;
      }
    }
    .document-editor-body {
      background-color: $light-color;
      .ck.ck-content.ck-editor__editable {
        min-height: 40rem;
        padding: 15px 72px;
      }
    }
  }
</style>
