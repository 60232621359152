<template>
  <div class="prescription-page">
    <div class="columns form-group">
      <div class="column col-4">
        <div class="input-group">
          <select class="form-select">
            <option value="">Meus modelos</option>
          </select>
          <button class="btn btn-neutral input-group-btn">
            Gerenciar
          </button>
        </div>
      </div>
    </div>
    <div class="prescription-group">
      <div class="prescription-item text-gray">
        <fa-icon :icon="['fal', 'minus-circle']" class="mr-2"/>Dieta
        <small>(em desenvolvimento)</small>
      </div>
    </div>
    <div class="prescription-group">
      <div class="prescription-item" @click="openMedicine">
        <fa-icon :icon="['fal', 'plus-circle']" class="mr-2"/>Medicamentos e Soluções
      </div>
      <div v-for="(item, i) in filteredMedicines" :key="i">
        <div class="prescription-content" @click="editMedicine(item.bundleId)">
          <table class="table" v-if="item.items.length > 0">
            <tbody>
            <tr class="c-hand" v-for="(medicine, i) in item.items" :key="i">
              <td style="width: 40px">{{ i + 1 }}</td>
              <td>{{ medicine.item.name }}</td>
              <td style="width: 150px">
                {{ medicine.quantity }} {{ unit.getName(medicine.unit).toLowerCase() }}
              </td>
              <td style="width: 150px">{{ access.getName(medicine.accessType) }}</td>
              <td style="width: 150px">
                {{ discretion.getName(medicine.discretion) }}
              </td>
              <td style="width: 120px">
                <span v-if="medicine.useDays === 'continuous'">
                  uso contínuo
                </span>
                <span v-else-if="medicine.useDays">
                  por {{ medicine.useDays }} dia(s)
                </span>
              </td>
              <td style="width: 100px">
                {{ medicine.frequency }} x ao dia
              </td>
              <td style="width: 40px">
                <button class="btn btn-action btn-icon btn-sm btn-gray"
                        :class="{loading: deleting && idx === i}"
                        :disabled="deleting && idx === i"
                        @click.stop="remove(medicine.id, i)">
                  <fa-icon :icon="['fal', 'times']" />
                </button>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="prescription-group">
      <div class="prescription-item" @click="openExam('exam')">
        <fa-icon :icon="['fal', 'plus-circle']" class="mr-2"/>Exames clínicos
      </div>
      <div v-for="(item, i) in filteredExams" :key="i">
        <div class="prescription-content" @click="editExam('exam', item.bundleId)">
          <table class="table" v-if="item.items.length > 0">
            <tbody>
            <tr class="c-hand" v-for="(exam, i) in item.items" :key="i">
              <td style="width: 40px">{{ i + 1 }}</td>
              <td style="width: 120px">{{ exam.item.code | tuss }}</td>
              <td>{{ exam.item.name }}</td>
              <td style="width: 50px">x{{ exam.quantity }}</td>
              <td style="width: 40px">
                <button class="btn btn-action btn-icon btn-sm btn-gray"
                        :class="{loading: deleting && idx === i}"
                        :disabled="deleting && idx === i"
                        @click.stop="remove(exam.id, i)">
                  <fa-icon :icon="['fal', 'times']" />
                </button>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="prescription-group">
      <div class="prescription-item" @click="openExam('laboratory')">
        <fa-icon :icon="['fal', 'plus-circle']" class="mr-2"/>Exames laboratoriais
      </div>
      <div v-for="(item, i) in filteredLaboratories" :key="i">
        <div class="prescription-content" @click="editExam('laboratory', item.bundleId)">
          <table class="table" v-if="item.items.length > 0">
            <tbody>
            <tr class="c-hand" v-for="(exam, i) in item.items" :key="i">
              <td style="width: 40px">{{ i + 1 }}</td>
              <td style="width: 120px">{{ exam.item.code | tuss }}</td>
              <td>{{ exam.item.name }}</td>
              <td style="width: 50px">x{{ exam.quantity }}</td>
              <td style="width: 40px">
                <button class="btn btn-action btn-icon btn-sm btn-gray"
                        :class="{loading: deleting && idx === i}"
                        :disabled="deleting && idx === i"
                  @click.stop="remove(exam.id, i)">
                  <fa-icon :icon="['fal', 'times']" />
                </button>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="prescription-group">
      <div class="prescription-item" @click="openRecommendation">
        <fa-icon :icon="['fal', 'plus-circle']" class="mr-2"/>Recomendações
      </div>
      <div class="prescription-content c-hand"
           v-for="(item, i) in filteredRecommendations" :key="i"
           @click="editRecommendation(item.bundleId)">
        <table class="table">
          <tbody>
          <tr>
            <td style="width: 40px">{{ i + 1 }}</td>
            <td style="width: 120px">{{ item.date | date }}</td>
            <td>{{ item.notes }}</td>
            <td style="width: 40px">
              <button class="btn btn-action btn-icon btn-sm btn-gray"
                      :class="{loading: deleting && idx === i}"
                      :disabled="deleting && idx === i"
                      @click.stop="remove(item.id, i)">
                <fa-icon :icon="['fal', 'times']" />
              </button>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="prescription-group">
      <div class="prescription-item text-gray">
        <fa-icon :icon="['fal', 'minus-circle']" class="mr-2"/>Enfermagem / SAE
        <small>(em desenvolvimento)</small>
      </div>
    </div>
    <modal-medicine
      v-if="medicineModal.show"
      :show="medicineModal.show"
      :medical-record-id="medicalRecordId"
      :bundle-id="medicineModal.bundleId"
      @save="saveMedicine"
      @close="medicineModal.show = false"
    />
    <modal-exam
      v-if="examModal.show"
      :show="examModal.show"
      :medical-record-id="medicalRecordId"
      :bundle-id="examModal.bundleId"
      :type="examModal.type"
      @save="saveExam"
      @close="examModal.show = false"
    />
    <modal-recommendation
      v-if="recommendationModal.show"
      :show="recommendationModal.show"
      :medical-record-id="medicalRecordId"
      :bundle-id="recommendationModal.bundleId"
      @save="saveRecommendation"
      @close="recommendationModal.show = false"
    />
  </div>
</template>

<script>
import * as unit from 'src/data/drug-units';
import * as access from 'src/data/drug-accesses';
import * as discretion from 'src/data/discretion-types';
import ModalExam from './modals/Exam.vue';
import ModalMedicine from './modals/Medicine.vue';
import ModalRecommendation from './modals/Recommendation.vue';

export default {
  props: {
    medicalRecordId: {
      type: String,
    },
    patientId: {
      type: String,
    },
  },
  components: {
    ModalExam,
    ModalMedicine,
    ModalRecommendation,
  },
  data() {
    return {
      access,
      discretion,
      unit,
      items: [],
      idx: null,
      deleting: false,
      medicineModal: {
        type: '',
        show: false,
        bundleId: '',
      },
      examModal: {
        type: '',
        show: false,
        bundleId: '',
      },
      recommendationModal: {
        bundleId: '',
        show: false,
      },
    };
  },
  mounted() {
    this.load();
  },
  computed: {
    filteredMedicines() {
      const medicines = this.items.filter(({ type }) => type === 'medicine');

      return medicines.reduce((results, medicine) => {
        const found = results.find(({ bundleId }) => bundleId === medicine.bundleId);
        const item = {
          id: medicine.id,
          item: {
            id: medicine.item.id,
            name: medicine.item.name,
          },
          quantity: medicine.quantity,
          unit: medicine.unit,
          justification: medicine.justification,
          accessType: medicine.accessType,
          startDate: medicine.startDate,
          endDate: medicine.endDate,
          useDays: medicine.useDays,
          discretion: medicine.discretion,
          frequency: medicine.frequency,
        };

        if (!found) {
          const data = {
            bundleId: medicine.bundleId,
            type: medicine.type,
            date: medicine.date,
            items: [item],
          };
          results.push(data);
        } else {
          found.items.push(item);
        }

        return results;
      }, []);
    },
    filteredExams() {
      const exams = this.items.filter(({ type }) => type === 'exam');

      return exams.reduce((results, exam) => {
        const found = results.find(({ bundleId }) => bundleId === exam.bundleId);

        const item = {
          id: exam.id,
          item: {
            id: exam.item.id,
            code: exam.item.code,
            name: exam.item.name,
          },
          quantity: exam.quantity,
        };

        if (!found) {
          const data = {
            bundleId: exam.bundleId,
            type: exam.type,
            date: exam.date,
            justification: exam.justification,
            indication: exam.indication,
            items: [item],
          };
          results.push(data);
        } else {
          found.items.push(item);
        }

        return results;
      }, []);
    },
    filteredLaboratories() {
      const exams = this.items.filter(({ type }) => type === 'laboratory');

      return exams.reduce((results, exam) => {
        const found = results.find(({ bundleId }) => bundleId === exam.bundleId);

        const item = {
          id: exam.id,
          item: {
            id: exam.item.id,
            code: exam.item.code,
            name: exam.item.name,
          },
          quantity: exam.quantity,
        };

        if (!found) {
          const data = {
            bundleId: exam.bundleId,
            type: exam.type,
            date: exam.date,
            justification: exam.justification,
            indication: exam.indication,
            items: [item],
          };
          results.push(data);
        } else {
          found.items.push(item);
        }

        return results;
      }, []);
    },
    filteredRecommendations() {
      return this.items.filter(({ type }) => type === 'recommendation');
    },
  },
  methods: {
    load() {
      this.$http.get(`/medical-records/${this.medicalRecordId}/prescriptions`)
        .then(({ data }) => {
          this.items = data.items;
        })
        .catch(this.$toast.error);
    },
    remove(id, i) {
      this.idx = i;
      this.deleting = true;
      return this.$http
        .delete(`/medical-records/${this.medicalRecordId}/prescriptions/${id}`)
        .then(() => {
          this.load();
        })
        .catch(() => {
          this.$toast.show('Houve um erro ao excluir. Tente novamente!', { type: 'error' });
        })
        .finally(() => {
          this.deleting = false;
        });
    },
    openMedicine() {
      this.medicineModal.bundleId = '';
      this.medicineModal.show = true;
    },
    editMedicine(bundleId) {
      this.medicineModal.bundleId = bundleId;
      this.medicineModal.show = true;
    },
    saveMedicine() {
      this.medicineModal.show = false;
      this.load();
    },
    openExam(type) {
      this.examModal.type = type;
      this.examModal.bundleId = '';
      this.examModal.show = true;
    },
    editExam(type, bundleId) {
      this.examModal.type = type;
      this.examModal.bundleId = bundleId;
      this.examModal.show = true;
    },
    saveExam() {
      this.examModal.show = false;
      this.load();
    },
    openRecommendation() {
      this.recommendationModal.bundleId = '';
      this.recommendationModal.show = true;
    },
    editRecommendation(bundleId) {
      this.recommendationModal.bundleId = bundleId;
      this.recommendationModal.show = true;
    },
    saveRecommendation() {
      this.recommendationModal.show = false;
      this.load();
    },
  },
};
</script>

<style lang="scss">
  @import "../../../../assets/scss/variables";
  .prescription-page {
    .prescription-group {
      margin-bottom: $layout-spacing-sm;
      .prescription-item {
        background-color: $gray-color-light;
        margin-bottom: $layout-spacing-sm;
        padding: $layout-spacing;
        font-weight: bold;
        &:hover {
          cursor: pointer;
          text-decoration: underline;
        }
      }
      .prescription-content {
        background-color: #ebeff8;
        border: $border-width solid $border-color;
        border-radius: $border-radius;
        margin: $layout-spacing-sm 0 $layout-spacing-sm 1.2rem;
        table {
          tr {
            td {
              border-bottom: none;
              height: 1rem;
            }
          }
          &:hover {
            background-color: darken(#ebeff8, 3%);
          }
        }
      }
    }
  }
</style>
