<template>
  <div class="medical-record-item">
    <div class="card">
      <div class="card-header">
        <strong>
          {{ item.startDate | date('DD[ de ]MMMM[ de ]YYYY[ às ]HH:mm') }}
        </strong><br>
        <small>{{ item.professional.name }}</small>
      </div>
      <div class="card-body">
        <ophthalmology-item
          v-if="item.anamnesis.templateRenderer"
          :item="item"
        />
        <default-item
          v-else
          :item="item"
        />
      </div>
      <div class="card-footer">
        <div class="image tooltip tooltip-right"
             data-tooltip="Atendimento com anexo(s)">
        </div>
        <div class="text-right">
          <button class="btn btn-sm mr-1"
                  :class="{loading: printing}"
                  :disabled="printing"
                  @click="printAnamnesis">Imprimir
          </button>
          <button class="btn btn-sm"
                  @click="openDetail">Detalhar</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DefaultItem from './item/DefaultItem.vue';
import OphthalmologyItem from './item/OphthalmologyItem.vue';

export default {
  props: {
    item: {
      type: Object,
    },
  },
  components: {
    DefaultItem,
    OphthalmologyItem,
  },
  data() {
    return {
      printing: false,
    };
  },
  methods: {
    printAnamnesis() {
      this.printing = true;
      return this.$file
        .print(`/medical-records/${this.item.id}/print`)
        .catch(this.$toast.error)
        .then(() => {
          this.printing = false;
        });
    },
    openDetail() {
      this.$emit('openDetail', this.item.id);
    },
  },
};
</script>

<style lang="scss">
@import "./src/assets/scss/_variables.scss";

  .medical-record-item {
    border: $border-width solid $border-color;
    margin-bottom: $layout-spacing-lg;
    .negative-value {
      color: red;
    }
    .positive-value {
      color: green;
    }
    .card-footer {
      display: flex;
      justify-content: space-between;
      .image {
        color: $primary-color;
        font-size: .8rem;
      }
    }
  }
</style>
