<template>
  <div>
    <dx-modal title="Recomendações"
              :value="show" @input="close"
              id="modal-recommendation" size="md">

      <div class="loading loading-lg" v-if="loading" />

      <template v-else>
        <div class="columns">
          <div class="column col-12 form-group">
            <div class="input-group" :class="{loading: loadingModels}">
              <select class="form-select" v-model="modelId"
                      :class="{loading: loadingModels}"
                      @change="getModel">
                <option value="">Minhas recomendações</option>
                <option v-for="(item, i) in models" :value="item.id" :key="i">
                  {{ item.name }}
                </option>
              </select>
<!--              <button class="btn btn-neutral input-group-btn"-->
<!--                      @click="openEditModel">-->
<!--                Gerenciar-->
<!--              </button>-->
            </div>
          </div>
          <div class="column col-12 form-group"
               :class="{'has-error': $v.form.notes.$error}">
            <label class="form-label">Recomendação</label>
            <textarea class="form-input" rows="5"
                      v-model="form.notes"
                      @blur="$v.form.notes.$touch()"
            />
          </div>
        </div>
      </template>

      <template slot="footer">
        <button class="btn btn-primary mr-1"
                :disabled="saving || loading" @click="save"
                :class="{loading: saving}">Salvar
        </button>
        <button class="btn" @click="close">Sair</button>
      </template>
    </dx-modal>

    <modal-edit
      v-if="editModels.show"
      :show="editModels.show"
      @close="closeEditModel"
    />
  </div>
</template>

<script>
import { randomHash } from '@/helpers';
import { mapState } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import ModalEdit from '../../../../template/components/request/modals/Main.vue';
import { mergeFrom } from '../../../../../helpers/object';

export default {
  components: {
    ModalEdit,
  },
  props: {
    show: {
      type: Boolean,
    },
    medicalRecordId: {
      type: String,
    },
    bundleId: {
      type: String,
    },
  },
  data() {
    return {
      models: [],
      modelId: '',
      loading: false,
      saving: false,
      loadingModels: false,
      editModels: {
        show: false,
      },
      form: this.blankForm(),
    };
  },
  computed: {
    ...mapState({
      user: state => state.auth.user,
    }),
  },
  async mounted() {
    if (this.bundleId) {
      this.load();
    }
  },
  validations() {
    return {
      form: {
        notes: { required },
      },
    };
  },
  methods: {
    load() {
      this.loading = true;
      this.$http.get(`/medical-records/${this.medicalRecordId}/prescriptions/${this.bundleId}`)
        .then(({ data }) => {
          this.form = mergeFrom(this.blankForm(), data);
        })
        .catch(this.$toast.error)
        .finally(() => {
          this.loading = false;
        });
    },
    loadModels() {
      const params = {
        limit: 0,
        type: this.type,
        professionalId: this.user.id,
      };
      this.$http.get('/recommendation-templates', { params })
        .then(({ data }) => {
          this.models = data.items;
        })
        .catch(this.$toast.error);
    },
    getModel() {
      if (!this.modelId) {
        return;
      }

      this.loadingModels = true;
      this.$httpX.get(`/request-templates/${this.modelId}`)
        .then(({ data }) => {
          if (data && data.expenses.length > 0) {
            data.expenses.forEach((expense) => {
              const found = this.form.items.find(item => item.id === expense.id);
              if (found) {
                const result = Number(found.quantity);
                found.quantity = result + Number(expense.quantity);
              } else {
                this.form.items.push({
                  key: randomHash(),
                  id: expense.id,
                  code: expense.code,
                  name: expense.name,
                  quantity: expense.quantity,
                });
              }
            });
          }
        })
        .catch(this.$toast.error)
        .finally(() => {
          this.modelId = '';
          this.loadingModels = false;
        });
    },
    openEditModel() {
      this.editModels.show = true;
    },
    closeEditModel() {
      this.loadModels();
      this.editModels.show = false;
    },
    save() {
      this.$v.form.$touch();
      if (this.$v.form.$error) {
        return false;
      }

      this.saving = true;

      const data = {
        items: [{
          type: 'recommendation',
          notes: this.form.notes,
        }],
      };

      const request = this.bundleId
        ? this.$http
          .put(`/medical-records/${this.medicalRecordId}/prescriptions/${this.bundleId}`, data)
        : this.$http
          .post(`/medical-records/${this.medicalRecordId}/prescriptions`, data);

      return request
        .then(({ data: result }) => {
          this.$emit('save', result);
        })
        .catch(this.$toast.error)
        .finally(() => {
          this.saving = false;
        });
    },
    close() {
      this.$emit('close');
    },
    blankForm() {
      return {
        id: '',
        bundleId: '',
        date: '',
        notes: '',
      };
    },
  },
};
</script>

<style lang="scss">
</style>
